export default {
  FETCH_BLOG_POST_EMAIL_REQUEST: 'FETCH_BLOG_POST_EMAIL_REQUEST',
  FETCH_BLOG_POST_EMAIL_SUCCESS: 'FETCH_BLOG_POST_EMAIL_SUCCESS',
  FETCH_BLOG_POST_EMAIL_FAILURE: 'FETCH_BLOG_POST_EMAIL_FAILURE',
  UPDATE_BLOG_POST_EMAIL: 'UPDATE_BLOG_POST_EMAIL',
  UPDATE_BLOG_POST_EMAIL_SECTION_DATA: 'UPDATE_BLOG_POST_EMAIL_SECTION_DATA',
  UPDATE_BLOG_POST_EMAIL_SECTION_THEME: 'UPDATE_BLOG_POST_EMAIL_SECTION_THEME',
  SAVE_BLOG_POST_EMAIL_REQUEST: 'SAVE_BLOG_POST_EMAIL_REQUEST',
  SAVE_BLOG_POST_EMAIL_SUCCESS: 'SAVE_BLOG_POST_EMAIL_SUCCESS',
  SAVE_BLOG_POST_EMAIL_FAILURE: 'SAVE_BLOG_POST_EMAIL_FAILURE',
  SEND_BLOG_POST_TEST_EMAIL_REQUEST: 'SEND_BLOG_POST_TEST_EMAIL_REQUEST',
  SEND_BLOG_POST_TEST_EMAIL_SUCCESS: 'SEND_BLOG_POST_TEST_EMAIL_SUCCESS',
  SEND_BLOG_POST_TEST_EMAIL_FAILURE: 'SEND_BLOG_POST_TEST_EMAIL_FAILURE',
  SEND_BLOG_POST_EMAIL_REQUEST: 'SEND_BLOG_POST_EMAIL_REQUEST',
  SEND_BLOG_POST_EMAIL_SUCCESS: 'SEND_BLOG_POST_EMAIL_SUCCESS',
  SEND_BLOG_POST_EMAIL_FAILURE: 'SEND_BLOG_POST_EMAIL_FAILURE',
  RESET_BLOG_POST_EMAIL: 'RESET_BLOG_POST_EMAIL',
  UPDATE_BLOG_POST_EMAIL_HTML_SECTION: 'UPDATE_BLOG_POST_EMAIL_HTML_SECTION',
  ADD_BLOG_POST_EMAIL_HTML_SECTION: 'ADD_BLOG_POST_EMAIL_HTML_SECTION',
  REMOVE_BLOG_POST_EMAIL_HTML_SECTION: 'REMOVE_BLOG_POST_EMAIL_HTML_SECTION',
  RESET_BLOG_POST_EMAIL_HTML_SECTION: 'RESET_BLOG_POST_EMAIL_HTML_SECTION',
  UPDATE_BLOG_POST_EMAIL_JSON_SECTION: 'UPDATE_BLOG_POST_EMAIL_JSON_SECTION',
  ADD_BLOG_POST_EMAIL_JSON_SECTION: 'ADD_BLOG_POST_EMAIL_JSON_SECTION',
  REMOVE_BLOG_POST_EMAIL_JSON_SECTION: 'REMOVE_BLOG_POST_EMAIL_JSON_SECTION',
  SET_BLOG_POST_EMAIL_SECTION_ID: 'SET_BLOG_POST_EMAIL_SECTION_ID',
  RESET_BLOG_POST_EMAIL_JSON_SECTION: 'RESET_BLOG_POST_EMAIL_JSON_SECTION',
  SET_BLOG_POST_EMAIL_DRAWER_VISIBILITY: 'SET_BLOG_POST_EMAIL_DRAWER_VISIBILITY',
  SET_BLOG_POST_EMAIL_MODAL_VISIBILITY: 'SET_BLOG_POST_EMAIL_MODAL_VISIBILITY',
  UPDATE_BLOG_POST_EMAIL_CHECK_LIST: 'UPDATE_BLOG_POST_EMAIL_CHECK_LIST',
  DELETE_BLOG_POST_EMAIL_SECTION: 'DELETE_BLOG_POST_EMAIL_SECTION'
}
