export default {
  SET_HOME_PAGE_REVISION: 'SET_HOME_PAGE_REVISION',
  SAVE_HOME_PAGE_REVISION_REQUEST: 'SAVE_HOME_PAGE_REVISION_REQUEST',
  SAVE_HOME_PAGE_REVISION_SUCCESS: 'SAVE_HOME_PAGE_REVISION_SUCCESS',
  SAVE_HOME_PAGE_REVISION_FAILURE: 'SAVE_HOME_PAGE_REVISION_FAILURE',
  UPDATE_HOME_PAGE_REVISION_SECTION_THEME: 'UPDATE_HOME_PAGE_REVISION_SECTION_THEME',
  UPDATE_HOME_PAGE_REVISION_SECTION_DATA: 'UPDATE_HOME_PAGE_REVISION_SECTION_DATA',
  ADD_HOME_PAGE_REVISION_SECTION_DATA: 'ADD_HOME_PAGE_REVISION_SECTION_DATA',
  DELETE_HOME_PAGE_REVISION_SECTION_DATA: 'DELETE_HOME_PAGE_REVISION_SECTION_DATA',
  UPDATE_HOME_PAGE_REVISION_SECTION_META: 'UPDATE_HOME_PAGE_REVISION_SECTION_META',
  ADD_NEW_HOME_PAGE_REVISION_SECTION: 'ADD_NEW_HOME_PAGE_REVISION_SECTION',
  DELETE_HOME_PAGE_REVISION_SECTION: 'DELETE_HOME_PAGE_REVISION_SECTION',
  SELECT_HOME_PAGE_REVISION_TEMPLATE: 'SELECT_HOME_PAGE_REVISION_TEMPLATE',
  UPDATE_HOME_PAGE_REVISION_TEMPLATE_META: 'UPDATE_HOME_PAGE_REVISION_TEMPLATE_META',
  UPLOAD_HOME_PAGE_REVISION_IMAGE_REQUEST: 'UPLOAD_HOME_PAGE_REVISION_IMAGE_REQUEST',
  UPLOAD_HOME_PAGE_REVISION_IMAGE_SUCCESS: 'UPLOAD_HOME_PAGE_REVISION_IMAGE_SUCCESS',
  UPLOAD_HOME_PAGE_REVISION_IMAGE_FAILURE: 'UPLOAD_HOME_PAGE_REVISION_IMAGE_FAILURE',
  SET_HOME_PAGE_REVISION_CURRENT_SECTION_ID: 'SET_HOME_PAGE_REVISION_CURRENT_SECTION_ID',
  SHOW_HOME_PAGE_TEMPLATES_DRAWER: 'SHOW_HOME_PAGE_TEMPLATES_DRAWER',
  SHOW_HOME_PAGE_ADD_NEW_SECTIONS_DRAWER: 'SHOW_HOME_PAGE_ADD_NEW_SECTIONS_DRAWER',
  MOVE_HOME_PAGE_REVISION_SECTION: 'MOVE_HOME_PAGE_REVISION_SECTION',
  REORDER_HOME_PAGE_ITEMS: 'REORDER_HOME_PAGE_ITEMS',
  CHANGE_HOME_PAGE_SECTION_KEY: 'CHANGE_HOME_PAGE_SECTION_KEY',
  UPDATE_HOME_PAGE_REVISION: 'UPDATE_HOME_PAGE_REVISION'
}
