export default {
  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
  FETCH_NOTIFICATIONS_PROJECTS_REQUEST: 'FETCH_NOTIFICATIONS_PROJECTS_REQUEST',
  FETCH_NOTIFICATIONS_PROJECTS_SUCCESS: 'FETCH_NOTIFICATIONS_PROJECTS_SUCCESS',
  FETCH_NOTIFICATIONS_PROJECTS_FAILURE: 'FETCH_NOTIFICATIONS_PROJECTS_FAILURE',
  UPDATE_NOTIFICATIONS_QUERY: 'UPDATE_NOTIFICATIONS_QUERY',
  UPDATE_NOTIFICATIONS_FILTERS: 'UPDATE_NOTIFICATIONS_FILTERS',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS'
}
