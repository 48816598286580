import produce from 'immer'
import constants from 'constants/libraries'

const initialState = {
  intercom: {}
}

export default produce((draft, action) => {
  
  switch (action.type) {
    case constants.FETCH_INTERCOM_DETAILS:
      draft.intercom = action.intercom
      break
  }
}, initialState)
