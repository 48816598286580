export default {
  FETCH_FORUM_REQUEST: 'FETCH_FORUM_REQUEST',
  FETCH_FORUM_SUCCESS: 'FETCH_FORUM_SUCCESS',
  FETCH_FORUM_FAILURE: 'FETCH_FORUM_FAILURE',
  UPDATE_FORUM: 'UPDATE_FORUM',
  SAVE_FORUM_REQUEST: 'SAVE_FORUM_REQUEST',
  SAVE_FORUM_SUCCESS: 'SAVE_FORUM_SUCCESS',
  SAVE_FORUM_FAILURE: 'SAVE_FORUM_FAILURE',
  RESET_FORUM: 'RESET_FORUM',
  FETCH_FORUM_PERMALINK_REQUEST: 'FETCH_FORUM_PERMALINK_REQUEST',
  FETCH_FORUM_PERMALINK_SUCCESS: 'FETCH_FORUM_PERMALINK_SUCCESS',
  FETCH_FORUM_PERMALINK_FAILURE: 'FETCH_FORUM_PERMALINK_FAILURE'
}
