import produce from 'immer'
import constants from 'constants/videos'

const initialState = {
  active: '',
  videos: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_VIDEOS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_VIDEOS_SUCCESS:
      draft.videos = action.videos
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_VIDEOS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.CREATE_VIDEO_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_VIDEO_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.videos[action.videos.id] = action.videos
      break
    case constants.CREATE_VIDEO_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_VIDEO_REQUEST:
      draft.videos[action.id].deleting = true
      break
    case constants.DELETE_VIDEO_SUCCESS:
      delete draft.videos[action.id]
      break
    case constants.DELETE_VIDEO_FAILURE:
      draft.videos[action.id].deleting = false
      draft.videos[action.id].deleteError = action.errorMessage
      break
    case constants.REORDER_VIDEOS:
      (() => {
        for (let key in action.updates) {
          draft.videos[key].sequence = action.updates[key]
        }
      })()
      break
    case constants.SET_ACTIVE_VIDEO:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_VIDEO:
      draft.active = ''
      break
    case constants.SAVE_VIDEO_REQUEST:
      draft.videos[action.id].saving = true
      break
    case constants.SAVE_VIDEO_SUCCESS:
      draft.videos[action.id].saving = false
      draft.videos[action.id].saveError = ''
      draft.videos[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.videos[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_VIDEO_FAILURE:
      draft.videos[action.id].saving = false
      draft.videos[action.id].saveError = action.errorMessage
      draft.videos[action.id].errors = action.errors
      break
    case constants.RESET_VIDEO_ERRORS:
      draft.videos[action.id].saveError = ''
      draft.videos[action.id].errors = {}
      break
    case constants.CLONE_VIDEOS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_VIDEOS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.videos.forEach((video) => {
        draft.videos[video.id] = video
      })
      break
    case constants.CLONE_VIDEOS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.RESET_VIDEOS:
      return initialState
  }
}, initialState)
