import produce from 'immer'
import constants from '../constants/project-users'

const initialState = {
  projectUsers: {
  },
  fetching: false,
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_SUBSCRIBERS_DETAIL_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_PROJECT_SUBSCRIBERS_DETAIL_SUCCESS:
      draft.projectUsers.subscribersCount = action.meta.all

      draft.projectUsers.subscribersData = action.users
      draft.fetching = false

      break

    case constants.FETCH_PROJECT_SUBSCRIBERS_DETAIL_FAILURE:
      draft.fetching = false
      draft.errors = action.errorMessage

      break

    case constants.FETCH_PROJECT_PARTICIPANTS_DETAIL_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_PROJECT_PARTICIPANTS_DETAIL_SUCCESS:
      draft.projectUsers.participantsCount = action.meta.all

      draft.projectUsers.participantsData = action.users
      draft.fetching = false

      break

    case constants.FETCH_PROJECT_PARTICIPANTS_DETAIL_FAILURE:
      draft.fetching = false
      draft.errors = action.errorMessage

      break
  }
}, initialState)
