import produce from 'immer'
import constants from 'constants/qanda-email-template'

const initialState = {
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.SAVE_QANDA_EMAIL_TEMPLATE_REQUEST(action.identifier):
      draft.saving = true
      break
    case constants.SAVE_QANDA_EMAIL_TEMPLATE_SUCCESS(action.identifier):
      draft.saving = false
      delete draft.errors[action.identifier]
      draft.saveError = Object.keys(draft.errors).length > 0 ? draft.saveError : ''
      break
    case constants.SAVE_QANDA_EMAIL_TEMPLATE_FAILURE(action.identifier):
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors[action.identifier] = action.errors
      break
  }
}, initialState)
