export default {
  FETCH_QUICK_POLL_REQUEST: 'FETCH_QUICK_POLL_REQUEST',
  FETCH_QUICK_POLL_SUCCESS: 'FETCH_QUICK_POLL_SUCCESS',
  FETCH_QUICK_POLL_FAILURE: 'FETCH_QUICK_POLL_FAILURE',
  UPDATE_QUICK_POLL: 'UPDATE_QUICK_POLL',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SAVE_QUICK_POLL_REQUEST: 'SAVE_QUICK_POLL_REQUEST',
  SAVE_QUICK_POLL_SUCCESS: 'SAVE_QUICK_POLL_SUCCESS',
  SAVE_QUICK_POLL_FAILURE: 'SAVE_QUICK_POLL_FAILURE',
  RESET_QUICK_POLL: 'RESET_QUICK_POLL',
  FETCH_QUICK_POLL_PERMALINK_REQUEST: 'FETCH_QUICK_POLL_PERMALINK_REQUEST',
  FETCH_QUICK_POLL_PERMALINK_SUCCESS: 'FETCH_QUICK_POLL_PERMALINK_SUCCESS',
  FETCH_QUICK_POLL_PERMALINK_FAILURE: 'FETCH_QUICK_POLL_PERMALINK_FAILURE'
}
