export default {
  FETCH_SCHEDULE_REQUEST: 'FETCH_PROJECT_SCHEDULE_REQUEST',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_PROJECT_SCHEDULE_SUCCESS',
  FETCH_SCHEDULE_FAILURE: 'FETCH_PROJECT_SCHEDULE_FAILURE',
  SAVE_SCHEDULE_REQUEST: 'SAVE_PROJECT_SCHEDULE_REQUEST',
  SAVE_SCHEDULE_SUCCESS: 'SAVE_PROJECT_SCHEDULE_SUCCESS',
  SAVE_SCHEDULE_FAILURE: 'SAVE_PROJECT_SCHEDULE_FAILURE',
  DESTROY_SCHEDULE_REQUEST: 'DESTROY_PROJECT_SCHEDULE_REQUEST',
  DESTROY_SCHEDULE_SUCCESS: 'DESTROY_PROJECT_SCHEDULE_SUCCESS',
  DESTROY_SCHEDULE_FAILURE: 'DESTROY_PROJECT_SCHEDULE_FAILURE',
  RESET_ERRORS: 'RESET_PROJECT_SCHEDULE_ERRORS',
  RESET_SCHEDULE: 'RESET_PROJECT_SCHEDULE'
}
