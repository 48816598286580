import produce from 'immer'
import constants from 'constants/map-layers'
import omit from 'lodash/omit'

const initialState = {
  layers: {},
  links: {},
  activeLayerKey: '', // will store the ID of the Active Editing Layer...
  activeStylingLayerId: '', // defines the layerId on which we are working for styling it.
  fetching: false,
  doneFetching: false,
  query: {
    page: 1,
    per_page: 10
  },
  loadMoreQuery: {
    page: 1,
    per_page: 999
  },
  fetchError: '',
  errors: {},
  isCustomLayerActive: false,
  totalLayers: 0,
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_LAYERS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_LAYERS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.layers = action.layers
      draft.links = action.links
      draft.totalLayers = action.meta?.count
      break
    case constants.FETCH_LAYERS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_ACTIVE_LAYER_KEY:
      draft.activeLayerKey = action.id
      draft.activeStylingLayerId = action.id
      break
    case constants.SET_ACTIVE_LAYER_STYLE_KEY:
      draft.activeStylingLayerId = action.id
      break
    case constants.REPLACE_LAYER_STATE:
      draft.layers[action.id] = action.layer
      break
    case constants.UPDATE_LAYER:
      draft.layers[action.id][action.key] = action.value
      break
    case constants.UPDATE_MAP_LAYER_METADATA:
      draft.layers[action.id]['metadata'][action.key] = action.value
      break
    case constants.RESET_LAYERS:
      return initialState
    case constants.RESET_LAYER:
      draft.layers[action.id].name = ''
      draft.layers[action.id].url = ''
      draft.layers[action.id].metadata.wmsLayerName = ''
      draft.layers[action.id].saving = false
      draft.layers[action.id].saveError = ''
      draft.layers[action.id].errors = {}
      break
    case constants.RESET_ACTIVE_LAYER_KEY:
      draft.activeLayerKey = ''
      draft.activeStylingLayerId = ''
      break
    case constants.RESET_ACTIVE_LAYER_STYLE_KEY:
      draft.activeStylingLayerId = ''
      break
    case constants.HIDE_LAYER_REQUEST:
    case constants.UNHIDE_LAYER_REQUEST:
    case constants.DESTROY_LAYER_REQUEST:
      draft.layers[action.id].transitioning = true
      break
    case constants.HIDE_LAYER_SUCCESS:
      draft.layers[action.id].transitioning = false
      draft.layers[action.id].transitionError = ''
      draft.layers[action.id].hidden = true
      break
    case constants.UNHIDE_LAYER_SUCCESS:
      draft.layers[action.id].transitioning = false
      draft.layers[action.id].transitionError = ''
      draft.layers[action.id].hidden = false
      break
    case constants.DESTROY_LAYER_SUCCESS:
      delete draft.layers[action.id]
      break
    case constants.HIDE_LAYER_FAILURE:
    case constants.UNHIDE_LAYER_FAILURE:
    case constants.DESTROY_LAYER_FAILURE:
      draft.layers[action.id].transitioning = false
      draft.layers[action.id].transitionError = action.errorMessage
      break
    case constants.SAVE_LAYER_REQUEST:
      draft.layers[action.id].saving = true
      break
    case constants.SAVE_LAYER_SUCCESS:
      draft.layers = omit(draft.layers, [action.omitId])
      if (action.isLayerStyleChange) {
        draft.activeStylingLayerId = action.layer.id
      } else {
        draft.activeLayerKey = action.layer.id
      }
      draft.layers[action.layer.id] = action.layer
      draft.layers[action.layer.id].saving = false
      draft.layers[action.layer.id].saveError = ''
      draft.layers[action.layer.id].errors = {}
      break
    case constants.SAVE_LAYER_FAILURE:
      draft.layers[action.id].saving = false
      draft.layers[action.id].saveError = action.errorMessage
      draft.layers[action.id].errors = action.errors
      break
    case constants.REORDER_LAYERS:
      for (const key in action.updates) {
        draft.layers[key].sequence = action.updates[key]
      }
      break
    case constants.RESET_ERRORS:
      draft.errors = {}
      break
    case constants.RESET_LAYER_ERRORS:
      draft.layers[action.id].errors = {}
      break
    case constants.ADD_MAP_LAYER:
      draft.layers[action.layer.id] = action.layer
      draft.activeLayerKey = action.layer.id
      break
    case constants.DESTROY_DRAFT_LAYER:
      draft.layers = omit(draft.layers, [action.id])
      break
    case constants.UPDATE_MAP_LAYERS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.CUSTOM_LAYER_STATUS:
      draft.isCustomLayerActive = action.status
      break
  }
}, initialState)
