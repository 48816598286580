import produce from 'immer'
import constants from 'constants/brainstormer'

const initialState = {
  brainstormer: {},
  activeTab: 'details',
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BRAINSTORMER_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_BRAINSTORMER_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.brainstormer = action.brainstormer
      draft.sendAcknowledgement = action.brainstormer.sendAcknowledgement
      draft.sendAcknowledgementToAdmins = action.brainstormer.sendAcknowledgementToAdmins
      break
    case constants.FETCH_BRAINSTORMER_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_BRAINSTORMER:
      draft.brainstormer[action.key] = action.value
      draft[action.key] = action.value
      break
    case constants.SAVE_BRAINSTORMER_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_BRAINSTORMER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_BRAINSTORMER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.FETCH_BRAINSTORMER_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_BRAINSTORMER_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.brainstormer.permalink = action.permalink
      draft.brainstormer.holdPostRequest = false
      break
    case constants.FETCH_BRAINSTORMER_PERMALINK_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      draft.brainstormer.permalink = ''
      break
    case constants.SET_ACTIVE_TAB:
      draft.activeTab = action.activeTab
      break
    case constants.RESET_BRAINSTORMER:
      return initialState
  }
}, initialState)
