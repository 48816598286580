import produce from 'immer'
import constants from 'constants/project-finders'

const initialState = {
  projectFinders: [],
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  meta: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_FINDERS_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_PROJECT_FINDERS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.projectFinders = action.projectFinders
      draft.meta = action.meta
      break

    case constants.FETCH_PROJECT_FINDERS_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_PROJECT_FINDERS:
      draft.projectFinders = action.projectFinders
      break

    case constants.RESET_PROJECT_FINDER:
      return initialState
  }
}, initialState)
