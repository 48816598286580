import produce from 'immer'
import constants from '../constants/email-templates'

const initialState = {
  emailTemplates: {},
  activation_email: {},
  complete_registration_email: {},
  changed_screen_name_email: {},
  removed_by_moderator_email: {},
  topic_moderation_email: {},
  on_edit_reply: {},
  on_reply: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_EMAIL_TEMPLATES_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_EMAIL_TEMPLATES_SUCCESS:
      action.emailTemplates.forEach(template => {
        draft.emailTemplates[template.identifier] = template
        draft[template.identifier] = template.bodyTemplate
      })
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''

      break

    case constants.FETCH_EMAIL_TEMPLATES_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true

      break

    case constants.UPDATE_EMAIL_TEMPLATE:
      draft.emailTemplates[action.key][action.property] = action.value
      draft[action.key] = action.value

      break

    case constants.SAVE_EMAIL_TEMPLATE_REQUEST:
      draft.emailTemplates[action.identifier].saving = true

      break

    case constants.SAVE_EMAIL_TEMPLATE_SUCCESS:
      draft.emailTemplates[action.identifier].saving = false
      draft.emailTemplates[action.identifier].saveError = ''

      break

    case constants.SAVE_EMAIL_TEMPLATE_FAILURE:
      draft.emailTemplates[action.identifier].saveError = action.errorMessage
      draft.emailTemplates[action.identifier].saving = false

      break

    case constants.SAVE_EMAIL_TEMPLATES_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_EMAIL_TEMPLATES_SUCCESS:
      draft.saving = false
      draft.saveError = ''

      break

    case constants.SAVE_EMAIL_TEMPLATES_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage

      break
  }
}, initialState)
