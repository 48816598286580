export default {
  FETCH_SUBSCRIPTION_REQUEST: 'FETCH_SUBSCRIPTION_REQUEST',
  FETCH_SUBSCRIPTION_SUCCESS: 'FETCH_SUBSCRIPTION_SUCCESS',
  FETCH_SUBSCRIPTION_FAILURE: 'FETCH_SUBSCRIPTION_FAILURE',
  FETCH_SUBSCRIBE_SUBSCRIPTION_REQUEST: 'FETCH_SUBSCRIBE_SUBSCRIPTION_REQUEST',
  FETCH_SUBSCRIBE_SUBSCRIPTION_SUCCESS: 'FETCH_SUBSCRIBE_SUBSCRIPTION_SUCCESS',
  FETCH_SUBSCRIBE_SUBSCRIPTION_FAILURE: 'FETCH_SUBSCRIBE_SUBSCRIPTION_FAILURE',
  FETCH_UNSUBSCRIBE_SUBSCRIPTION_REQUEST: 'FETCH_UNSUBSCRIBE_SUBSCRIPTION_REQUEST',
  FETCH_UNSUBSCRIBE_SUBSCRIPTION_SUCCESS: 'FETCH_UNSUBSCRIBE_SUBSCRIPTION_SUCCESS',
  FETCH_UNSUBSCRIBE_SUBSCRIPTION_FAILURE: 'FETCH_UNSUBSCRIBE_SUBSCRIPTION_FAILURE',
  FETCH_DIGEST_REQUEST: 'FETCH_DIGEST_REQUEST',
  FETCH_DIGEST_SUCCESS: 'FETCH_DIGEST_SUCCESS',
  FETCH_DIGEST_FAILURE: 'FETCH_DIGEST_FAILURE',
  FETCH_SUBSCRIBE_DIGEST_REQUEST: 'FETCH_SUBSCRIBE_DIGEST_REQUEST',
  FETCH_SUBSCRIBE_DIGEST_SUCCESS: 'FETCH_SUBSCRIBE_DIGEST_SUCCESS',
  FETCH_SUBSCRIBE_DIGEST_FAILURE: 'FETCH_SUBSCRIBE_DIGEST_FAILURE',
  FETCH_UNSUBSCRIBE_DIGEST_REQUEST: 'FETCH_UNSUBSCRIBE_DIGEST_REQUEST',
  FETCH_UNSUBSCRIBE_DIGEST_SUCCESS: 'FETCH_UNSUBSCRIBE_DIGEST_SUCCESS',
  FETCH_UNSUBSCRIBE_DIGEST_FAILURE: 'FETCH_UNSUBSCRIBE_DIGEST_FAILURE',
  SET_SUBSCRIPTION_FILTER: 'SET_SUBSCRIPTION_FILTER'
}
