import produce from 'immer'
import constants from '../constants/user-feature'

const initialState = {
  features: {},
  adapter: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  errors: []
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_USER_ENABLE_FEATURE_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_USER_ENABLE_FEATURE_SUCCESS:
      action.features.forEach(feature => {
        draft.features[feature.id] = feature
      })
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_USER_ENABLE_FEATURE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.USER_ENABLE_FEATURE_REQUEST:
      if (!draft.features.hasOwnProperty(action.id)) {
        draft.features[action.id] = {
          id: action.id,
          enabled: false
        }
      }
      draft.features[action.id].saving = true
      break
    case constants.USER_DISABLE_FEATURE_REQUEST:
      draft.features[action.id].saving = true
      break
    case constants.USER_ENABLE_FEATURE_SUCCESS:
      draft.features[action.id].saving = false
      draft.features[action.id].doneSaving = true
      draft.features[action.id].saveError = ''
      draft.features[action.id].enabled = true
      break
    case constants.USER_DISABLE_FEATURE_SUCCESS:
      draft.features[action.id].saving = false
      draft.features[action.id].doneSaving = true
      draft.features[action.id].saveError = ''
      draft.features[action.id].enabled = false
      break
    case constants.USER_ENABLE_FEATURE_FAILURE:
    case constants.USER_DISABLE_FEATURE_FAILURE:
      draft.features[action.id].saving = false
      draft.features[action.id].doneSaving = false
      draft.features[action.id].saveError = action.errorMessage
      break

    case constants.TOGGLE_USER_FEATURE:
      draft.features[action.id].enabled = !draft.features[action.id].enabled
  }
}, initialState)
