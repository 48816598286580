import produce from 'immer'
import constants from 'constants/administrator-invitations'

const initialState = {
  siteAdmin: {
    id: '',
    password: '',
    confirmPassword: '',
    emailOrLogin: ''
  },
  fetching: false,
  doneFetching: true,
  saving: false,
  saveError: '',
  errors: {},
  errorMessage: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.CONFIRM_ADMIN_INVITATION_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break

    case constants.CONFIRM_ADMIN_INVITATION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.siteAdmin.id = action.data.id
      draft.siteAdmin.emailOrLogin = action.data.email

      break

    case constants.CONFIRM_ADMIN_INVITATION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.errorMessage = action.errorMessage
      draft.errors = action.errors

      break

    case constants.UPDATE_INVITED_SITE_ADMIN_DETAILS:
      draft.siteAdmin[action.key] = action.value

      break
  }
}, initialState)
