import produce from 'immer'
import constants from '../constants/settings'

const initialState = {
  settings: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SETTINGS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SETTINGS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.settings = action.settings
      break
    case constants.FETCH_SETTINGS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SETTINGS:
      draft.settings[action.key] = action.value
      break
    case constants.SAVE_SETTINGS_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_SETTINGS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_SETTINGS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPDATE_SETTINGS_ERROR:
      draft.errors = action.errors
      draft.saveError = action.errorMessage
      break
    case constants.RESET_SETTINGS:
      return initialState
  }
}, initialState)
