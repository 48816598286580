import produce from 'immer'
import constants from 'constants/dedicated-number'

const initialState = {
  dedicatedNumber: {
    siteId: null,
    country: '',
    isActive: '',
    numberId: null,
    phoneNumber: null,
    provider: ''
  },
  assignedNumber: {},
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DEDICATED_NUMBER_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_DEDICATED_NUMBER_SUCCESS:
      draft.fetchError = ''
      draft.dedicatedNumber = action.dedicatedNumber
      draft.fetching = false
      break

    case constants.FETCH_DEDICATED_NUMBER_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.FETCH_ASSIGNED_NUMBER_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_ASSIGNED_NUMBER_SUCCESS:
      draft.fetchError = ''
      draft.assignedNumber = action.assignedNumber
      draft.fetching = false
      break

    case constants.FETCH_ASSIGNED_NUMBER_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.DISSOCIATE_ASSIGNED_TOOL:
      draft.assignedNumber.phoneNumber = null
      draft.assignedNumber.isActive = false
      draft.assignedNumber.message = ''
      break

    case constants.UPDATE_DEDICATED_NUMBER:
      draft.dedicatedNumber = action.dedicatedNumber
      break

    case constants.DEDICATED_NUMBER_ACTIVE_TOGGLE_CHANGE:
      draft.dedicatedNumber.isActive = !draft.dedicatedNumber.isActive
      break

    case constants.UPDATE_ASSIGNED_NUMBER:
      draft.assignedNumber.phoneNumber = action.phoneNumber
      draft.assignedNumber.isActive = true
      break

    case constants.UPDATE_ASSIGNED_NUMBER_MESSAGE:
      draft.assignedNumber.message = action.message
      break

    case constants.SAVE_DEDICATED_NUMBER_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_DEDICATED_NUMBER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break

    case constants.SAVE_DEDICATED_NUMBER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
