import produce from 'immer'
import constants from 'constants/blog-post-email'

const initialState = {
  blogPostEmail: {
  },
  jsonEmail: {
    meta: {},
    sections: []
  },
  htmlSections: [],
  currentSectionId: '1',
  isShowEmailDrawerVisible: false,
  isCreateEmailDrawerVisible: false,
  isPublishEmailModalVisible: false,
  isConfirmationEmailModalVisible: false,
  addSubscribers: true,
  addParticipants: true,
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  sending: false,
  sendError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BLOG_POST_EMAIL_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_BLOG_POST_EMAIL_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.blogPostEmail = action.blogPostEmail

      break

    case constants.FETCH_BLOG_POST_EMAIL_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.UPDATE_BLOG_POST_EMAIL:
      draft.blogPostEmail[action.key] = action.value

      break

    case constants.SAVE_BLOG_POST_EMAIL_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_BLOG_POST_EMAIL_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.blogPostEmail = action.blogPostEmail

      break

    case constants.SAVE_BLOG_POST_EMAIL_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors

      break

    case constants.SEND_BLOG_POST_TEST_EMAIL_REQUEST:
      draft.sending = true

      break

    case constants.SEND_BLOG_POST_TEST_EMAIL_SUCCESS:
      draft.sending = false
      draft.sendError = ''
      draft.errors = {}
      draft.blogPostEmail = action.blogPostEmail

      break

    case constants.SEND_BLOG_POST_TEST_EMAIL_FAILURE:
      draft.sending = false
      draft.errors = action.errors

      break

    case constants.SEND_BLOG_POST_EMAIL_REQUEST:
      draft.sending = true

      break

    case constants.SEND_BLOG_POST_EMAIL_SUCCESS:
      draft.sending = false
      draft.sendError = ''
      draft.errors = {}
      draft.blogPostEmail = action.blogPostEmail

      break

    case constants.SEND_BLOG_POST_EMAIL_FAILURE:
      draft.sending = false
      draft.errors = action.errors

      break

    case constants.ADD_BLOG_POST_EMAIL_HTML_SECTION:
      draft.htmlSections.splice(action.position, 0, action.section)

      break

    case constants.REMOVE_BLOG_POST_EMAIL_HTML_SECTION:
      draft.htmlSections.splice(action.position, 1)

      break

    case constants.UPDATE_BLOG_POST_EMAIL_HTML_SECTION:
      draft.htmlSections[action.position] = action.section

      break

    case constants.UPDATE_BLOG_POST_EMAIL_CHECK_LIST:
      draft[action.key] = action.value

      break

    case constants.UPDATE_BLOG_POST_EMAIL_SECTION_DATA:
      draft.jsonEmail.sections[action.position].data[action.key] = action.value

      break

    case constants.UPDATE_BLOG_POST_EMAIL_SECTION_THEME:
      draft.jsonEmail.sections[action.position].theme[action.key] = action.value

      break

    case constants.RESET_BLOG_POST_EMAIL_HTML_SECTION:
      draft.htmlSections = initialState.htmlSections

      break

    case constants.ADD_BLOG_POST_EMAIL_JSON_SECTION:
      draft.jsonEmail.sections.splice(action.position, 0, action.section)

      break

    case constants.REMOVE_BLOG_POST_EMAIL_JSON_SECTION:
      draft.jsonEmail.sections.splice(action.position, 1)

      break

    case constants.SET_BLOG_POST_EMAIL_SECTION_ID:
      draft.currentSectionId = action.id

      break

    case constants.SET_BLOG_POST_EMAIL_DRAWER_VISIBILITY:
      draft[action.drawerType] = action.isVisible

      break
    case constants.SET_BLOG_POST_EMAIL_MODAL_VISIBILITY:
      draft[action.modalType] = action.isVisible

      break

    case constants.UPDATE_BLOG_POST_EMAIL_JSON_SECTION:
      draft.jsonEmail.sections[action.position] = action.section

      break

    case constants.DELETE_BLOG_POST_EMAIL_SECTION:
      draft.jsonEmail.sections.splice(action.sectionId, 1)
      draft.htmlSections.splice(action.sectionId, 1)

      break

    case constants.RESET_BLOG_POST_EMAIL_JSON_SECTION:
      draft.jsonEmail = initialState.jsonEmail

      break

    case constants.RESET_BLOG_POST_EMAIL:
      return initialState
  }
}, initialState)
