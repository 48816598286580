export default {
  FETCH_HEADER_LINK_REQUEST: 'FETCH_HEADER_LINK_REQUEST',
  FETCH_HEADER_LINK_SUCCESS: 'FETCH_HEADER_LINK_SUCCESS',
  FETCH_HEADER_LINK_FAILURE: 'FETCH_HEADER_LINK_FAILURE',
  SAVE_HEADER_LINK_REQUEST: 'SAVE_HEADER_LINK_REQUEST',
  SAVE_HEADER_LINK_SUCCESS: 'SAVE_HEADER_LINK_SUCCESS',
  SAVE_HEADER_LINK_FAILURE: 'SAVE_HEADER_LINK_FAILURE',
  UPDATE_HEADER_LINK: 'UPDATE_HEADER_LINK',
  RESET_HEADER_LINK: 'RESET_HEADER_LINK',
  DELETE_HEADER_LINK_REQUEST: 'DELETE_HEADER_LINK_REQUEST',
  DELETE_HEADER_LINK_SUCCESS: 'DELETE_HEADER_LINK_SUCCESS',
  DELETE_HEADER_LINK_FAILURE: 'DELETE_HEADER_LINK_FAILURE',
  FETCH_HEADER_LINKS_REQUEST: 'FETCH_HEADER_LINKS_REQUEST',
  FETCH_HEADER_LINKS_SUCCESS: 'FETCH_HEADER_LINKS_SUCCESS',
  FETCH_HEADER_LINKS_FAILURE: 'FETCH_HEADER_LINKS_FAILURE',
  SORT_HEADER_LINKS_SUCCESS: 'SORT_HEADER_LINKS_SUCCESS',
  SORT_HEADER_LINKS_FAILURE: 'SORT_HEADER_LINKS_FAILURE',
  UPDATE_HEADER_LINKS_DRAGGING: 'UPDATE_HEADER_LINKS_DRAGGING',
  UPDATE_HEADER_LINK_ERROR: 'UPDATE_HEADER_LINK_ERROR'
}
