export default {
  FETCH_QANDA_REQUEST: 'FETCH_QANDA_REQUEST',
  FETCH_QANDA_SUCCESS: 'FETCH_QANDA_SUCCESS',
  FETCH_QANDA_FAILURE: 'FETCH_QANDA_FAILURE',
  UPDATE_QANDA: 'UPDATE_QANDA',
  SAVE_QANDA_REQUEST: 'SAVE_QANDA_REQUEST',
  SAVE_QANDA_SUCCESS: 'SAVE_QANDA_SUCCESS',
  SAVE_QANDA_FAILURE: 'SAVE_QANDA_FAILURE',
  RESET_QANDA: 'RESET_QANDA',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB'
}
