import produce from 'immer'
import constants from 'constants/sms-integrations'

const initialState = {
  smsIntegrations: [],
  toolData: [],
  query: {
    page: 1,
    perPage: 5
  },
  meta: {
    total: 0
  },
  filters: {
    isActive: '',
    toolId: null,
    toolType: ''
  },
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SMS_INTEGRATIONS_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_SMS_INTEGRATIONS_SUCCESS:
      draft.fetchError = ''
      draft.smsIntegrations = action.smsIntegrations
      draft.fetching = false
      draft.meta = action.meta
      break

    case constants.FETCH_SMS_INTEGRATIONS_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_TOOL_DATA:
      draft.fetching = false
      draft.toolData = action.toolData
      break

    case constants.UPDATE_SMS_INTEGRATIONS_QUERIES:
      draft.query = { ...draft.query, ...action.query }
      break

    case constants.UPDATE_SMS_INTEGRATIONS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break

    case constants.DISSOCIATE_TOOL_AND_PROJECT:
      draft.smsIntegrations.forEach((smsIntegration) => {
        if (smsIntegration.id === action.id) {
          smsIntegration.toolId = null
          smsIntegration.projectId = null
        }
        return smsIntegration
      })

      draft.toolData.forEach((data) => {
        if (data.id === action.id) {
          data.toolId = null
          data.projectId = null
          data.associationStatus = !data.associationStatus
          data.projectName = ''
          data.name = ''
          data.projectState = ''
          data.state = ''
        }
        return data
      })
      break

    case constants.SAVE_SMS_INTEGRATIONS_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_SMS_INTEGRATIONS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break

    case constants.SAVE_SMS_INTEGRATIONS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
