import produce from 'immer'
import constants from 'constants/recipients'

const initialState = {
  excludedRecipientIds: [],
  recipients: [],
  query: {
    page: 1,
    per_page: 25
  },
  filters: {
    search: ''
  },
  meta: {
    count: 0
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  addSubscribers: true,
  allRecipients: []
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_RECIPIENTS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_ALL_RECIPIENTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.recipients = action.recipients
      draft.meta = action.meta
      break
    case constants.FETCH_ALL_RECIPIENTS_ON_SAVE:
      draft.allRecipients = action.allRecipients
      break
    case constants.FETCH_ALL_RECIPIENTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_RECIPIENTS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_RECIPIENTS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.TOGGLE_RECIPIENT:
      draft.recipients.forEach(recipient => {
        if (recipient.id === action.id) {
          recipient.selected = !recipient.selected
          if (recipient.selected) {
            draft.excludedRecipientIds = draft.excludedRecipientIds
              .filter(id => id !== recipient.id)
          } else {
            draft.excludedRecipientIds.push(recipient.id)
          }
        }
      })
      break
    case constants.TOGGLE_ALL_RECIPIENTS:
      draft.recipients.forEach(recipient => {
        recipient.selected = action.checked
        if (recipient.selected) {
          draft.excludedRecipientIds = draft.excludedRecipientIds.filter(id => id !== recipient.id)
        } else {
          draft.excludedRecipientIds.push(recipient.id)
        }
      })
      break
    case constants.Add_SUBSCRIBERS:
      draft.addSubscribers = action.checked
      break
    case constants.RESET_RECIPIENTS:
      return initialState
  }
}, initialState)
