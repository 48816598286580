import produce from 'immer'
import constants from 'constants/stories'

const initialState = {
  stories: {},
  queryParams: {
    page: 1,
    page_size: 999
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  currentTab: 'details'
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STORIES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_STORIES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.stories = action.stories
      break
    case constants.FETCH_STORIES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.TOGGLE_STORY:
      draft.stories[action.id].active = !draft.stories[action.id].active
      break
    case constants.ACCEPT_STORY_REQUEST:
    case constants.REJECT_STORY_REQUEST:
      draft.stories[action.id].saving = true
      break
    case constants.ACCEPT_STORY_SUCCESS:
      draft.stories[action.id].saving = false
      draft.stories[action.id].saveError = ''
      draft.stories[action.id].state = 'accepted'
      break
    case constants.REJECT_STORY_SUCCESS:
      draft.stories[action.id].saving = false
      draft.stories[action.id].saveError = ''
      draft.stories[action.id].state = 'rejected'
      break
    case constants.ACCEPT_STORY_FAILURE:
    case constants.REJECT_STORY_FAILURE:
      draft.stories[action.id].saving = false
      draft.stories[action.id].saveError = action.errorMessage
      break
    case constants.SET_CURRENT_TAB:
      draft.currentTab = action.currentTab
      break
  }
}, initialState)
