import produce from 'immer'
import constants from 'constants/quick-poll'

const initialState = {
  quickPoll: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  optionsCount: 0,
  activeTab: 'details'
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QUICK_POLL_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QUICK_POLL_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.quickPoll = action.quickPoll
      draft.optionsCount = action.optionsCount
      break
    case constants.FETCH_QUICK_POLL_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_QUICK_POLL:
      draft.quickPoll[action.key] = action.value
      break
    case constants.SAVE_QUICK_POLL_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_QUICK_POLL_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.quickPoll = action.quickPoll
      break
    case constants.SAVE_QUICK_POLL_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.FETCH_QUICK_POLL_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_QUICK_POLL_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.quickPoll.permalink = action.permalink
      draft.quickPoll.holdPostRequest = false
      break
    case constants.FETCH_QUICK_POLL_PERMALINK_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SET_ACTIVE_TAB:
      draft.activeTab = action.activeTab
      break
    case constants.RESET_QUICK_POLL:
      return initialState
  }
}, initialState)
