import produce, { current } from 'immer'
import findIndex from 'lodash/findIndex'
import constants from 'constants/story-email-templates'

const initialState = {
  storyEmailTemplates: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STORY_EMAIL_TEMPLATES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_STORY_EMAIL_TEMPLATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.storyEmailTemplates = action.storyEmailTemplates
      break
    case constants.FETCH_STORY_EMAIL_TEMPLATES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_STORY_EMAIL_TEMPLATE:
      const index = findIndex(draft.storyEmailTemplates, s => s.id === action.id)

      draft.storyEmailTemplates[index] = {
        ...current(draft).storyEmailTemplates[index],
        ...action.attributes
      }
      break
  }
}, initialState)
