export default {
  FETCH_ALL_SELECTED_RECIPIENTS_REQUEST: 'FETCH_ALL_SELECTED_RECIPIENTS_REQUEST',
  FETCH_ALL_SELECTED_RECIPIENTS_SUCCESS: 'FETCH_ALL_SELECTED_RECIPIENTS_SUCCESS',
  FETCH_ALL_SELECTED_RECIPIENTS_FAILURE: 'FETCH_ALL_SELECTED_RECIPIENTS_FAILURE',
  UPDATE_SELECTED_RECIPIENTS_QUERY: 'UPDATE_SELECTED_RECIPIENTS_QUERY',
  UPDATE_SELECTED_RECIPIENTS_FILTERS: 'UPDATE_SELECTED_RECIPIENTS_FILTERS',
  SAVE_ALL_SELECTED_RECIPIENTS_REQUEST: 'SAVE_ALL_SELECTED_RECIPIENTS_REQUEST',
  SAVE_ALL_SELECTED_RECIPIENTS_SUCCESS: 'SAVE_ALL_SELECTED_RECIPIENTS_SUCCESS',
  SAVE_ALL_SELECTED_RECIPIENTS_FAILURE: 'SAVE_ALL_SELECTED_RECIPIENTS_FAILURE',
  UPDATE_SELECTED_RECIPIENTS_COUNT: 'UPDATE_SELECTED_RECIPIENTS_COUNT',
  RESET_SELECTED_RECIPIENTS: 'RESET_SELECTED_RECIPIENTS'
}
