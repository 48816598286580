export default {
  FETCH_LINKS_REQUEST: 'FETCH_KEY_LINKS_REQUEST',
  FETCH_LINKS_SUCCESS: 'FETCH_KEY_LINKS_SUCCESS',
  FETCH_LINKS_FAILURE: 'FETCH_KEY_LINKS_FAILURE',
  CREATE_LINK_REQUEST: 'CREATE_KEY_LINK_REQUEST',
  CREATE_LINK_SUCCESS: 'CREATE_KEY_LINK_SUCCESS',
  CREATE_LINK_FAILURE: 'CREATE_KEY_LINK_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_KEY_LINK_ERRORS',
  RESET_LINK_ERRORS: 'RESET_KEY_LINK_ERRORS',
  DELETE_LINK_REQUEST: 'DELETE_LINK_REQUEST',
  DELETE_LINK_SUCCESS: 'DELETE_LINK_SUCCESS',
  DELETE_LINK_FAILURE: 'DELETE_LINK_FAILURE',
  UNSET_ACTIVE_LINK: 'UNSET_ACTIVE_KEY_LINK',
  SET_ACTIVE_LINK: 'SET_ACTIVE_KEY_LINK',
  SAVE_LINK_REQUEST: 'SAVE_KEY_LINK_REQUEST',
  SAVE_LINK_SUCCESS: 'SAVE_KEY_LINK_SUCCESS',
  SAVE_LINK_FAILURE: 'SAVE_KEY_LINK_FAILURE',
  REORDER_LINKS: 'REORDER_KEY_LINKS',
  CLONE_LINKS_REQUEST: 'CLONE_KEY_LINKS_REQUEST',
  CLONE_LINKS_SUCCESS: 'CLONE_KEY_LINKS_SUCCESS',
  CLONE_LINKS_FAILURE: 'CLONE_KEY_LINKS_FAILURE',
  RESET_LINKS: 'RESET_KEY_LINKS'
}
