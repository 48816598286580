import produce from 'immer'
import constants from 'constants/dedicated-numbers'

const initialState = {
  dedicatedNumbers: [],
  toolData: [],
  query: {
    page: 1,
    perPage: 5
  },
  meta: {
    total: 0
  },
  filters: {
    siteId: null,
    numberId: null,
    smsToolIntegration: {
      toolId: null
    }
  },
  fetching: false,
  fetchError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DEDICATED_NUMBERS_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_DEDICATED_NUMBERS_SUCCESS:
      draft.fetchError = ''
      draft.dedicatedNumbers = action.dedicatedNumbers
      draft.fetching = false
      draft.meta = action.meta
      break

    case constants.FETCH_DEDICATED_NUMBERS_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_TOOL_DATA:
      draft.fetching = false
      draft.toolData = action.toolData
      break

    case constants.REMOVE_TOOL_ID:
      draft.toolData.forEach((data) => {
        if (data.id === action.id) {
          data.associationStatus = !data.associationStatus
          data.projectName = ''
          data.name = ''
          data.projectState = ''
          data.state = ''
        }
        return data
      })
      break

    case constants.TOOL_DATA_ACTIVE_TOGGLE_CHANGE:
      draft.toolData.forEach((data) => {
        if (data.id === action.id) {
          data.isActive = !data.isActive
        }
        return data
      })
      break

    case constants.UPDATE_DEDICATED_NUMBERS_FILTERS:
      draft.filters = action.filters
      break

    case constants.UPDATE_DEDICATED_NUMBERS_QUERIES:
      draft.query = { ...draft.query, ...action.query }
      break
  }
}, initialState)
