import produce from 'immer'
import constants from 'constants/hub-segments'

const initialState = {
  segments: [],
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.ASSOCIATE_HUB_SEGMENTS_REQUEST:
      draft.saving = true

      break

    case constants.ASSOCIATE_HUB_SEGMENTS_SUCCESS:
      draft.saving = false
      draft.saveError = ''

      break

    case constants.ASSOCIATE_HUB_SEGMENTS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage

      break

    case constants.SET_HUB_SEGMENTS:
      draft.segments = action.segments

      break

    case constants.TOGGLE_HUB_SEGMENT:
      let index = draft.segments.indexOf(action.id)
      if (index === -1) {
        draft.segments.push(action.id)
      } else {
        draft.segments.splice(index, 1)
      }

      break
  }
}, initialState)
