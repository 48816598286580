import produce from 'immer'
import constants from '../constants/project-stats'

const initialState = {
  stats: {},
  fetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_STATS_REQUEST:
      draft.fetching = true

      break
    case constants.FETCH_PROJECT_STATS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.stats = action.stats

      break
    case constants.FETCH_PROJECT_STATS_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage

      break
  }
}, initialState)
