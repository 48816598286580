import produce from 'immer'
import constants from '../constants/subscription'

const initialState = {
  filter: 'digests',
  digests: [],
  subscriptions: [],
  emailState: {
    ArchivalSummaryMail: false,
    WeeklyDigestEmail: false
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  unsubscribeStatus: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DIGEST_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_DIGEST_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.digests = action.digests
      break
    case constants.FETCH_DIGEST_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_SUBSCRIBE_DIGEST_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SUBSCRIBE_DIGEST_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.emailState[action.value.subscription] = action.value.subscribed
      break
    case constants.FETCH_SUBSCRIBE_DIGEST_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_UNSUBSCRIBE_DIGEST_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_UNSUBSCRIBE_DIGEST_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.emailState[action.value.subscription] = action.value.subscribed
      break
    case constants.FETCH_UNSUBSCRIBE_DIGEST_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_SUBSCRIPTION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SUBSCRIPTION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.subscriptions = action.subscriptions
      break
    case constants.FETCH_SUBSCRIPTION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_SUBSCRIBE_SUBSCRIPTION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SUBSCRIBE_SUBSCRIPTION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.FETCH_SUBSCRIBE_SUBSCRIPTION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.subscribeStatus = action.errorStatus
      break
    case constants.FETCH_UNSUBSCRIBE_SUBSCRIPTION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_UNSUBSCRIBE_SUBSCRIPTION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.unsubscribeStatus = action.status
      break
    case constants.FETCH_UNSUBSCRIBE_SUBSCRIPTION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.unsubscribeStatus = action.errorStatus
      break
    case constants.SET_SUBSCRIPTION_FILTER:
      draft.filter = action.filter
      break
  }
}, initialState)
