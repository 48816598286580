import React from 'react'
import { Provider } from 'react-redux'
import { getOrCreateStore } from 'lib/initialize-store'

const WithReduxStore = (App, reducer, initialState) => {
  return class AppWithRedux extends React.Component {
    static async getInitialProps (appContext) {
      const reduxStore = getOrCreateStore(reducer, initialState)
      let appProps = {}

      appContext.reduxStore = reduxStore

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState()
      }
    }

    constructor (props) {
      super(props)
      this.reduxStore = getOrCreateStore(reducer, props.initialReduxState)
    }

    render () {
      const { initialReduxState, ...appProps } = this.props

      return (
        <Provider store={this.reduxStore}>
          <App {...appProps} />
        </Provider>
      )
    }
  }
}

export default WithReduxStore
