import produce from 'immer'
import constants from 'constants/site-features'
import merge from 'lodash/merge'
import union from 'lodash/union'

const initialState = {
  siteFeatures: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SITE_FEATURES_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_SITE_FEATURES_SUCCESS:
      // TODO: remove this once the api structure changes
      let features = []
      let rules = []
      action.siteFeatures.forEach(siteFeature => {
        const baseFeature = siteFeature.baseFeature
        features.push(baseFeature.features)
        rules.push(baseFeature.rules)
      })

      const siteFeatures = {
        rules: merge(...rules),
        features: union(...features)
      }

      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.siteFeatures = siteFeatures
      break

    case constants.FETCH_SITE_FEATURES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
