import produce from 'immer'
import constants from 'constants/qanda-questions'

const initialState = {
  meta: {},
  questions: {},
  questionQuery: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QUESTIONS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QUESTIONS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.questions = action.questions
      draft.meta = action.meta
      break
    case constants.FETCH_QUESTIONS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.PUBLICLY_ANSWER_REQUEST:
    case constants.PRIVATELY_ANSWER_REQUEST:
    case constants.ARCHIVE_QUESTION_REQUEST:
    case constants.DELETE_QUESTION_REQUEST:
    case constants.RESTORE_QUESTION_REQUEST:
    case constants.JUNK_QUESTION_REQUEST:
    case constants.SALVAGE_QUESTION_REQUEST:
      draft.questions[action.id].transitioning = true
      break
    case constants.PUBLICLY_ANSWER_SUCCESS:
      draft.questions[action.id].transitioning = false
      draft.questions[action.id].transitionError = ''
      draft.questions[action.id].state = action.state
      draft.questions[action.id].publicAnswer = action.answer
      draft.questions[action.id].updatedAt = action.updatedAt
      draft.questions[action.id].errors = {}
      break
    case constants.PRIVATELY_ANSWER_SUCCESS:
      draft.questions[action.id].transitioning = false
      draft.questions[action.id].transitionError = ''
      draft.questions[action.id].state = action.state
      draft.questions[action.id].updatedAt = action.updatedAt
      draft.questions[action.id].privateAnswers.push(action.answer)
      draft.questions[action.id].errors = {}
      break
    case constants.ARCHIVE_QUESTION_SUCCESS:
    case constants.RESTORE_QUESTION_SUCCESS:
    case constants.JUNK_QUESTION_SUCCESS:
    case constants.SALVAGE_QUESTION_SUCCESS:
      draft.questions[action.id].transitioning = false
      draft.questions[action.id].transitionError = ''
      draft.questions[action.id].errors = {}
      draft.questions[action.id].state = action.state
      break
    case constants.DELETE_QUESTION_SUCCESS:
      delete draft.questions[action.id]
      break
    case constants.PUBLICLY_ANSWER_FAILURE:
    case constants.PRIVATELY_ANSWER_FAILURE:
    case constants.ARCHIVE_QUESTION_FAILURE:
    case constants.DELETE_QUESTION_FAILURE:
    case constants.RESTORE_QUESTION_FAILURE:
    case constants.JUNK_QUESTION_FAILURE:
    case constants.SALVAGE_QUESTION_FAILURE:
      draft.questions[action.id].transitioning = false
      draft.questions[action.id].transitionError = action.errorMessage
      draft.questions[action.id].errors = action.errors
      break
    case constants.UPDATE_QUESTION:
      draft.questions[action.id][action.key] = action.value
      draft.questions[action.id].errors = {}
      break
    case constants.UPDATE_QUESTION_QUERY:
      draft.questionQuery = action.questionQuery
      break
  }
}, initialState)
