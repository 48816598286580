import produce from 'immer'
import constants from '../constants/tag-manager'

const initialState = {
  tagManagerTags: [],
  tagManagerTag: {},
  fetchError: '',
  fetching: false,
  saving: false,
  saveError: '',
  errors: {},
  doneFetching: false,
  queries: {
    page: 1,
    per_page: 25,
    filterable: true,
    context: 'project_tags'
  },
  meta: {
    count: 0
  },
  filters: {
    state: 'count',
    search: '',
    sort: {
      created_at: 'desc'
    }
  },
  projectsByTag: [],
  fetchingTaggedProjects: false,
  fetchTaggedProjectsError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_TAG_MANAGER_TAGS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_TAG_MANAGER_TAGS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.tagManagerTags = action.tagManagerTags
      draft.meta = action.meta
      break
    case constants.FETCH_TAG_MANAGER_TAGS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SAVE_TAG_MANAGER_TAG_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_TAG_MANAGER_TAG_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.tagManagerTag = action.tagManagerTag
      draft.errors = {}
      break
    case constants.SAVE_TAG_MANAGER_TAG_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPDATE_TAG_MANAGER_TAGS_QUERY:
      draft.queries = { ...draft.queries, ...action.queries }
      break
    case constants.UPDATE_TAG_MANAGER_TAGS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.FETCH_PROJECTS_BY_TAG_REQUEST:
      draft.fetchingTaggedProjects = true
      break
    case constants.FETCH_PROJECTS_BY_TAG_SUCCESS:
      draft.fetchingTaggedProjects = false
      draft.fetchTaggedProjectsError = ''
      draft.projectsByTag = action.projectsByTag
      break
    case constants.FETCH_PROJECTS_BY_TAG_FAILURE:
      draft.fetchingTaggedProjects = false
      draft.fetchTaggedProjectsError = action.errorMessage
      break
    case constants.RESET_TAG_MANAGER_TAGS:
      return initialState
    case constants.RESET_PROJECTS_BY_TAG:
      draft.projectsByTag = []
      draft.fetchingTaggedProjects = false
  }
}, initialState)
