export default {
  FETCH_MAPS_REQUEST: 'FETCH_MAPS_REQUEST',
  FETCH_MAPS_SUCCESS: 'FETCH_MAPS_SUCCESS',
  FETCH_MAPS_FAILURE: 'FETCH_MAPS_FAILURE',
  RESET_MAPS: 'RESET_MAPS',
  REORDER_MAPS: 'REORDER_MAPS',
  PUBLISH_MAP_REQUEST: 'PUBLISH_MAP_REQUEST',
  PUBLISH_MAP_SUCCESS: 'PUBLISH_MAP_SUCCESS',
  PUBLISH_MAP_FAILURE: 'PUBLISH_MAP_FAILURE',
  UNPUBLISH_MAP_REQUEST: 'UNPUBLISH_MAP_REQUEST',
  UNPUBLISH_MAP_SUCCESS: 'UNPUBLISH_MAP_SUCCESS',
  UNPUBLISH_MAP_FAILURE: 'UNPUBLISH_MAP_FAILURE',
  ARCHIVE_MAP_REQUEST: 'ARCHIVE_MAP_REQUEST',
  ARCHIVE_MAP_SUCCESS: 'ARCHIVE_MAP_SUCCESS',
  ARCHIVE_MAP_FAILURE: 'ARCHIVE_MAP_FAILURE',
  UNARCHIVE_MAP_REQUEST: 'UNARCHIVE_MAP_REQUEST',
  UNARCHIVE_MAP_SUCCESS: 'UNARCHIVE_MAP_SUCCESS',
  UNARCHIVE_MAP_FAILURE: 'UNARCHIVE_MAP_FAILURE',
  DESTROY_MAP_REQUEST: 'DESTROY_MAP_REQUEST',
  DESTROY_MAP_SUCCESS: 'DESTROY_MAP_SUCCESS',
  DESTROY_MAP_FAILURE: 'DESTROY_MAP_FAILURE'
}
