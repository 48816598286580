export default {
  SET_HUB_PAGE_REVISION: 'SET_HUB_PAGE_REVISION',
  SAVE_HUB_PAGE_REVISION_REQUEST: 'SAVE_HUB_PAGE_REVISION_REQUEST',
  SAVE_HUB_PAGE_REVISION_SUCCESS: 'SAVE_HUB_PAGE_REVISION_SUCCESS',
  SAVE_HUB_PAGE_REVISION_FAILURE: 'SAVE_HUB_PAGE_REVISION_FAILURE',
  UPDATE_HUB_PAGE_REVISION_SECTION_THEME: 'UPDATE_HUB_PAGE_REVISION_SECTION_THEME',
  UPDATE_HUB_PAGE_REVISION_SECTION_DATA: 'UPDATE_HUB_PAGE_REVISION_SECTION_DATA',
  ADD_HUB_PAGE_REVISION_SECTION_DATA: 'ADD_HUB_PAGE_REVISION_SECTION_DATA',
  DELETE_HUB_PAGE_REVISION_SECTION_DATA: 'DELETE_HUB_PAGE_REVISION_SECTION_DATA',
  UPDATE_HUB_PAGE_REVISION_SECTION_META: 'UPDATE_HUB_PAGE_REVISION_SECTION_META',
  ADD_NEW_HUB_PAGE_REVISION_SECTION: 'ADD_NEW_HUB_PAGE_REVISION_SECTION',
  DELETE_HUB_PAGE_REVISION_SECTION: 'DELETE_HUB_PAGE_REVISION_SECTION',
  SELECT_HUB_PAGE_REVISION_TEMPLATE: 'SELECT_HUB_PAGE_REVISION_TEMPLATE',
  UPDATE_HUB_PAGE_REVISION_TEMPLATE_META: 'UPDATE_HUB_PAGE_REVISION_TEMPLATE_META',
  UPLOAD_HUB_PAGE_REVISION_IMAGE_REQUEST: 'UPLOAD_HUB_PAGE_REVISION_IMAGE_REQUEST',
  UPLOAD_HUB_PAGE_REVISION_IMAGE_SUCCESS: 'UPLOAD_HUB_PAGE_REVISION_IMAGE_SUCCESS',
  UPLOAD_HUB_PAGE_REVISION_IMAGE_FAILURE: 'UPLOAD_HUB_PAGE_REVISION_IMAGE_FAILURE',
  SET_HUB_PAGE_REVISION_CURRENT_SECTION_ID: 'SET_HUB_PAGE_REVISION_CURRENT_SECTION_ID',
  SHOW_HUB_PAGE_TEMPLATES_DRAWER: 'SHOW_HUB_PAGE_TEMPLATES_DRAWER',
  SHOW_HUB_PAGE_ADD_NEW_SECTIONS_DRAWER: 'SHOW_HUB_PAGE_ADD_NEW_SECTIONS_DRAWER',
  MOVE_HUB_PAGE_REVISION_SECTION: 'MOVE_HUB_PAGE_REVISION_SECTION',
  REORDER_HUB_PAGE_ITEMS: 'REORDER_HUB_PAGE_ITEMS',
  CHANGE_HUB_PAGE_SECTION_KEY: 'CHANGE_HUB_PAGE_SECTION_KEY',
  UPDATE_HUB_PAGE_REVISION: 'UPDATE_HUB_PAGE_REVISION',
  RESET_HUB_PAGE_REVISION: 'RESET_HUB_PAGE_REVISION'
}
