export default {
  FETCH_STATIC_PAGE_REQUEST: 'FETCH_STATIC_PAGE_REQUEST',
  FETCH_STATIC_PAGE_SUCCESS: 'FETCH_STATIC_PAGE_SUCCESS',
  FETCH_STATIC_PAGE_FAILURE: 'FETCH_STATIC_PAGE_FAILURE',
  SAVE_STATIC_PAGE_REQUEST: 'SAVE_STATIC_PAGE_REQUEST',
  SAVE_STATIC_PAGE_SUCCESS: 'SAVE_STATIC_PAGE_SUCCESS',
  SAVE_STATIC_PAGE_FAILURE: 'SAVE_STATIC_PAGE_FAILURE',
  UPDATE_STATIC_PAGE: 'UPDATE_STATIC_PAGE',
  RESET_STATIC_PAGE: 'RESET_STATIC_PAGE',
  SET_ACTIVE_STATIC_PAGE: 'SET_ACTIVE_STATIC_PAGE',
  PERMALINK_REQUEST: 'PERMALINK_REQUEST',
  PERMALINK_SUCCESS: 'PERMALINK_SUCCESS',
  PERMALINK_FAILURE: 'PERMALINK_FAILURE'
}
