import produce from 'immer'

import constants from '../constants/confirm-administrator'

const initialState = {
  siteData: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.CONFIRM_ADMIN_REQUEST:
      draft.fetching = true
      break
    case constants.CONFIRM_ADMIN_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.siteData = action.siteData
      break
    case constants.CONFIRM_ADMIN_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.INVITE_ADMIN_REQUEST:
      draft.fetching = true
      break
    case constants.INVITE_ADMIN_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.siteData = action.siteData
      draft.errors = {}
      break
    case constants.INVITE_ADMIN_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPDATE_INVITED_ADMIN:
      draft.siteData[action.key] = action.value
      break
  }
}, initialState)
