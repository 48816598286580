export default {
  FETCH_PROJECTS_REQUEST: 'FETCH_PROJECT_ADMIN_AUTHORIZED_PROJECTS_REQUEST',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECT_ADMIN_AUTHORIZED_PROJECTS_SUCCESS',
  FETCH_PROJECTS_FAILURE: 'FETCH_PROJECT_ADMIN_AUTHORIZED_PROJECTS_FAILURE',
  AUTHORIZE_PROJECT_REQUEST: 'AUTHORIZE_PROJECT_TO_PROJECT_ADMIN_REQUEST',
  AUTHORIZE_PROJECT_SUCCESS: 'AUTHORIZE_PROJECT_TO_PROJECT_ADMIN_SUCCESS',
  AUTHORIZE_PROJECT_FAILURE: 'AUTHORIZE_PROJECT_TO_PROJECT_ADMIN_FAILURE',
  UNAUTHORIZE_PROJECT_REQUEST: 'UNAUTHORIZE_PROJECT_TO_PROJECT_ADMIN_REQUEST',
  UNAUTHORIZE_PROJECT_SUCCESS: 'UNAUTHORIZE_PROJECT_TO_PROJECT_ADMIN_SUCCESS',
  UNAUTHORIZE_PROJECT_FAILURE: 'UNAUTHORIZE_PROJECT_TO_PROJECT_ADMIN_FAILURE',
  RESET_PROJECTS: 'RESET_PROJECT_ADMIN_AUTHORIZED_PROJECTS'
}
