import produce from 'immer'
import constants from '../constants/site'

const initialState = {
  filter: 'general',
  site: {
    token: '',
    blocked: false
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  launching: false,
  launchingError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SITE_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SITE_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.site = action.site
      break
    case constants.FETCH_SITE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SET_FILTER:
      draft.filter = action.filter
      break
    case constants.UPDATE_SITE:
      draft.site[action.key] = action.value
      break
    case constants.SAVE_SITE_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_SITE_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_SITE_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.LAUNCH_SITE_REQUEST:
      draft.launching = true
      break
    case constants.LAUNCH_SITE_SUCCESS:
      draft.launching = false
      draft.launchingError = ''
      draft.errors = {}
      break
    case constants.LAUNCH_SITE_FAILURE:
      draft.launching = false
      draft.launchingError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.LOCK_SITE_REQUEST:
      draft.fetching = true
      break
    case constants.LOCK_SITE_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.site.token = action.site.token
      draft.site.blocked = action.site.blocked
      break
    case constants.LOCK_SITE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UNLOCK_SITE_REQUEST:
      draft.fetching = true
      break
    case constants.UNLOCK_SITE_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.site.token = action.site.token
      draft.site.blocked = action.site.blocked
      break
    case constants.UNLOCK_SITE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.LOCK_STATUS_REQUEST:
      draft.fetching = true
      break
    case constants.LOCK_STATUS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.site.token = action.site.token
      draft.site.blocked = action.site.blocked
      break
    case constants.LOCK_STATUS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
