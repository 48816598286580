export default {
  FETCH_BRAINSTORMER_REQUEST: 'FETCH_BRAINSTORMER_REQUEST',
  FETCH_BRAINSTORMER_SUCCESS: 'FETCH_BRAINSTORMER_SUCCESS',
  FETCH_BRAINSTORMER_FAILURE: 'FETCH_BRAINSTORMER_FAILURE',
  UPDATE_BRAINSTORMER: 'UPDATE_BRAINSTORMER',
  SAVE_BRAINSTORMER_REQUEST: 'SAVE_BRAINSTORMER_REQUEST',
  SAVE_BRAINSTORMER_SUCCESS: 'SAVE_BRAINSTORMER_SUCCESS',
  SAVE_BRAINSTORMER_FAILURE: 'SAVE_BRAINSTORMER_FAILURE',
  RESET_BRAINSTORMER: 'RESET_BRAINSTORMER',
  FETCH_BRAINSTORMER_PERMALINK_REQUEST: 'FETCH_BRAINSTORMER_PERMALINK_REQUEST',
  FETCH_BRAINSTORMER_PERMALINK_SUCCESS: 'FETCH_BRAINSTORMER_PERMALINK_SUCCESS',
  FETCH_BRAINSTORMER_PERMALINK_FAILURE: 'FETCH_BRAINSTORMER_PERMALINK_FAILURE',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB'
}
