import produce from 'immer'
import findIndex from 'lodash/findIndex'

import constants from 'constants/static-pages'

const initialState = {
  pages: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  meta: {
    count: 0
  },
  queries: {
    page: 1,
    per_page: 25
  },
  filters: {
    state: 'count', // API returns 'All' as count
    search: '',
    sort: {}
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STATIC_PAGES_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_STATIC_PAGES_SUCCESS:
      draft.pages = action.pages
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.meta = action.meta

      break

    case constants.FETCH_STATIC_PAGES_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = false

      break

    case constants.PUBLISH_STATIC_PAGE_REQUEST:
      draft.pages[findIndex(draft.pages, page => page.id === action.id)].publishing = true

      break

    case constants.PUBLISH_STATIC_PAGE_SUCCESS:
      draft.pages[findIndex(draft.pages, page => page.id === action.page.id)] = {
        ...action.page,
        publishing: false,
        publishError: ''
      }

      break

    case constants.PUBLISH_STATIC_PAGE_FAILURE:
      draft.pages[findIndex(draft.pages, page => page.id === action.page.id)] = {
        ...action.page,
        publishing: false,
        publishError: action.errorMessage
      }

      break

    case constants.UNPUBLISH_STATIC_PAGE_REQUEST:
      draft.pages[findIndex(draft.pages, page => page.id === action.id)].unpublishing = true

      break

    case constants.UNPUBLISH_STATIC_PAGE_SUCCESS:
      draft.pages[findIndex(draft.pages, page => page.id === action.page.id)] = {
        ...action.page,
        unpublishing: false,
        unpublishError: ''
      }

      break

    case constants.UNPUBLISH_STATIC_PAGE_FAILURE:
      draft.pages[findIndex(draft.pages, page => page.id === action.page.id)] = {
        ...action.page,
        unpublishing: false,
        unpublishError: action.errorMessage
      }

      break

    case constants.DELETE_STATIC_PAGE_REQUEST:
      draft.pages[findIndex(draft.pages, page => page.id === action.id)].deleting = true

      break

    case constants.DELETE_STATIC_PAGE_SUCCESS:
      draft.pages = draft.pages.filter(page => page.id !== action.id)

      break

    case constants.DELETE_STATIC_PAGE_FAILURE:
      draft.pages[findIndex(draft.pages, page => page.id === action.page.id)] = {
        ...action.page,
        deleting: false,
        deleteError: action.errorMessage
      }

      break

    case constants.UPDATE_STATIC_PAGES_QUERIES:
      draft.queries = { ...draft.queries, ...action.queries }

      break

    case constants.UPDATE_STATIC_PAGES_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }

      break
    case constants.RESET_STATIC_PAGES:
      return initialState
  }
}, initialState)
