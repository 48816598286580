export default {
  TOGGLE_TEXT_ANALYSIS_SENTIMENTS_COLUMN: 'TOGGLE_TEXT_ANALYSIS_SENTIMENTS_COLUMN',
  UPDATE_TEXT_ANALYSIS_SENTIMENT: 'UPDATE_TEXT_ANALYSIS_SENTIMENT',
  UPDATE_TEXT_ANALYSIS_QUERY: 'UPDATE_TEXT_ANALYSIS_QUERY',
  FETCH_DEMOGRAPHIC_FILTER_RESPONSE_REQUEST: 'FETCH_DEMOGRAPHIC_FILTER_RESPONSE_REQUEST',
  FETCH_DEMOGRAPHIC_FILTER_RESPONSE_SUCCESS: 'FETCH_DEMOGRAPHIC_FILTER_RESPONSE_SUCCESS',
  FETCH_DEMOGRAPHIC_FILTER_RESPONSE_FAILURE: 'FETCH_DEMOGRAPHIC_FILTER_RESPONSE_FAILURE',
  FETCH_SENTIMENT_FILTER_RESPONSE_REQUEST: 'FETCH_SENTIMENT_FILTER_RESPONSE_REQUEST',
  FETCH_SENTIMENT_FILTER_RESPONSE_SUCCESS: 'FETCH_SENTIMENT_FILTER_RESPONSE_SUCCESS',
  FETCH_SENTIMENT_FILTER_RESPONSE_FAILURE: 'FETCH_SENTIMENT_FILTER_RESPONSE_FAILURE',
  SET_QUESTION_TYPE: 'SET_QUESTION_TYPE',
  UPDATE_TEXT_ANALYSIS_SELECTION: 'UPDATE_TEXT_ANALYSIS_SELECTION',
  HIDE_SHOW_TABLE: 'HIDE_SHOW_TABLE',
  UPDATE_TEXT_ANALYSIS_FILTERS: 'UPDATE_TEXT_ANALYSIS_FILTERS'
}
