export default {
  FETCH_TAG_MANAGER_TAGS_REQUEST: 'FETCH_TAG_MANAGER_TAGS_REQUEST',
  FETCH_TAG_MANAGER_TAGS_SUCCESS: 'FETCH_TAG_MANAGER_TAGS_SUCCESS',
  FETCH_TAG_MANAGER_TAGS_FAILURE: 'FETCH_TAG_MANAGER_TAGS_FAILURE',
  SAVE_TAG_MANAGER_TAG_REQUEST: 'SAVE_TAG_MANAGER_TAG_REQUEST',
  SAVE_TAG_MANAGER_TAG_SUCCESS: 'SAVE_TAG_MANAGER_TAG_SUCCESS',
  SAVE_TAG_MANAGER_TAG_FAILURE: 'SAVE_TAG_MANAGER_TAG_FAILURE',
  UPDATE_TAG_MANAGER_TAGS_QUERY: 'UPDATE_TAG_MANAGER_TAGS_QUERY',
  UPDATE_TAG_MANAGER_TAGS_FILTERS: 'UPDATE_TAG_MANAGER_TAGS_FILTERS',
  RESET_TAG_MANAGER_TAGS: 'RESET_TAG_MANAGER_TAGS',
  FETCH_PROJECTS_BY_TAG_REQUEST: 'FETCH_PROJECTS_BY_TAG_REQUEST',
  FETCH_PROJECTS_BY_TAG_SUCCESS: 'FETCH_PROJECTS_BY_TAG_SUCCESS',
  FETCH_PROJECTS_BY_TAG_FAILURE: 'FETCH_PROJECTS_BY_TAG_FAILURE',
  RESET_PROJECTS_BY_TAG: 'RESET_PROJECTS_BY_TAG'
}
