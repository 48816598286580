import produce from 'immer'
import constants from 'constants/documents'

const initialState = {
  drawer: 'list',
  active: '',
  documents: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DOCUMENTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_DOCUMENTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.documents = action.documents
      break
    case constants.FETCH_DOCUMENTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_DOCUMENT:
      draft.documents[action.id].sequence = action.sequence
      draft.documents[action.id].folderId = action.folderId
      break
    case constants.CREATE_DOCUMENT_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_DOCUMENT_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.documents[action.document.id] = action.document
      break
    case constants.CREATE_DOCUMENT_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_DOCUMENT_REQUEST:
      draft.documents[action.id].deleting = true
      break
    case constants.DELETE_DOCUMENT_SUCCESS:
    case constants.REMOVE_DOCUMENT:
      delete draft.documents[action.id]
      break
    case constants.DELETE_DOCUMENT_FAILURE:
      draft.documents[action.id].deleting = false
      draft.documents[action.id].deleteError = action.errorMessage
      break
    case constants.SET_ACTIVE_DOCUMENT:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_DOCUMENT:
      draft.active = ''
      break
    case constants.SAVE_DOCUMENT_REQUEST:
      draft.documents[action.id].saving = true
      break
    case constants.SAVE_DOCUMENT_SUCCESS:
      draft.documents[action.id].saving = false
      draft.documents[action.id].saveError = ''
      draft.documents[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.documents[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_DOCUMENT_FAILURE:
      draft.documents[action.id].saving = false
      draft.documents[action.id].saveError = action.errorMessage
      draft.documents[action.id].errors = action.errors
      break
    case constants.RESET_DOCUMENT_ERRORS:
      draft.documents[action.id].saveError = ''
      draft.documents[action.id].errors = {}
      break
    case constants.CLONE_DOCUMENTS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_DOCUMENTS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      break
    case constants.CLONE_DOCUMENTS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.SET_ACTIVE_DRAWER:
      draft.drawer = action.drawer
      break
    case constants.SELECT_DOCUMENT:
      draft.documents[action.id].selected = true
      break
    case constants.UNSELECT_DOCUMENT:
      draft.documents[action.id].selected = false
      break
    case constants.SET_DOCUMENT:
      draft.documents[action.document.id] = action.document
      break
    case constants.RESET_DOCUMENTS :
      return initialState
  }
}, initialState)
