export default {
  FETCH_OPENCITIES_INTEGRATION_REQUEST: 'FETCH_OPENCITIES_INTEGRATION_REQUEST',
  FETCH_OPENCITIES_INTEGRATION_SUCCESS: 'FETCH_OPENCITIES_INTEGRATION_SUCCESS',
  FETCH_OPENCITIES_INTEGRATION_FAILURE: 'FETCH_OPENCITIES_INTEGRATION_FAILURE',
  UPDATE_OPENCITIES_INTEGRATION: 'UPDATE_OPENCITIES_INTEGRATION',
  RESET_OPENCITIES_INTEGRATION: 'RESET_OPENCITIES_INTEGRATION',
  SAVE_OPENCITIES_INTEGRATION_REQUEST: 'SAVE_OPENCITIES_INTEGRATION_REQUEST',
  SAVE_OPENCITIES_INTEGRATION_SUCCESS: 'SAVE_OPENCITIES_INTEGRATION_SUCCESS',
  SAVE_OPENCITIES_INTEGRATION_FAILURE: 'SAVE_OPENCITIES_INTEGRATION_FAILURE',
  VERIFY_OPENCITIES_INTEGRATION_REQUEST: 'VERIFY_OPENCITIES_INTEGRATION_REQUEST',
  VERIFY_OPENCITIES_INTEGRATION_SUCCESS: 'VERIFY_OPENCITIES_INTEGRATION_SUCCESS',
  VERIFY_OPENCITIES_INTEGRATION_FAILURE: 'VERIFY_OPENCITIES_INTEGRATION_FAILURE'
}
