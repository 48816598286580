export default {
  FETCH_BRAINSTORMERS_REQUEST: 'FETCH_BRAINSTORMERS_REQUEST',
  FETCH_BRAINSTORMERS_SUCCESS: 'FETCH_BRAINSTORMERS_SUCCESS',
  FETCH_BRAINSTORMERS_FAILURE: 'FETCH_BRAINSTORMERS_FAILURE',
  RESET_BRAINSTORMERS: 'RESET_BRAINSTORMERS',
  REORDER_BRAINSTORMERS: 'REORDER_BRAINSTORMERS',
  PUBLISH_BRAINSTORMER_REQUEST: 'PUBLISH_BRAINSTORMER_REQUEST',
  PUBLISH_BRAINSTORMER_SUCCESS: 'PUBLISH_BRAINSTORMER_SUCCESS',
  PUBLISH_BRAINSTORMER_FAILURE: 'PUBLISH_BRAINSTORMER_FAILURE',
  UNPUBLISH_BRAINSTORMER_REQUEST: 'UNPUBLISH_BRAINSTORMER_REQUEST',
  UNPUBLISH_BRAINSTORMER_SUCCESS: 'UNPUBLISH_BRAINSTORMER_SUCCESS',
  UNPUBLISH_BRAINSTORMER_FAILURE: 'UNPUBLISH_BRAINSTORMER_FAILURE',
  ARCHIVE_BRAINSTORMER_REQUEST: 'ARCHIVE_BRAINSTORMER_REQUEST',
  ARCHIVE_BRAINSTORMER_SUCCESS: 'ARCHIVE_BRAINSTORMER_SUCCESS',
  ARCHIVE_BRAINSTORMER_FAILURE: 'ARCHIVE_BRAINSTORMER_FAILURE',
  UNARCHIVE_BRAINSTORMER_REQUEST: 'UNARCHIVE_BRAINSTORMER_REQUEST',
  UNARCHIVE_BRAINSTORMER_SUCCESS: 'UNARCHIVE_BRAINSTORMER_SUCCESS',
  UNARCHIVE_BRAINSTORMER_FAILURE: 'UNARCHIVE_BRAINSTORMER_FAILURE',
  DESTROY_BRAINSTORMER_REQUEST: 'DESTROY_BRAINSTORMER_REQUEST',
  DESTROY_BRAINSTORMER_SUCCESS: 'DESTROY_BRAINSTORMER_SUCCESS',
  DESTROY_BRAINSTORMER_FAILURE: 'DESTROY_BRAINSTORMER_FAILURE',
  FETCH_SCHEDULE_REQUEST: 'FETCH_BRAINSTORMER_SCHEDULE_REQUEST',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_BRAINSTORMER_SCHEDULE_SUCCESS',
  FETCH_SCHEDULE_FAILURE: 'FETCH_BRAINSTORMER_SCHEDULE_FAILURE',
  SAVE_SCHEDULE_REQUEST: 'SAVE_BRAINSTORMER_SCHEDULE_REQUEST',
  SAVE_SCHEDULE_SUCCESS: 'SAVE_BRAINSTORMER_SCHEDULE_SUCCESS',
  SAVE_SCHEDULE_FAILURE: 'SAVE_BRAINSTORMER_SCHEDULE_FAILURE',
  DESTROY_SCHEDULE_REQUEST: 'DESTROY_BRAINSTORMER_SCHEDULE_REQUEST',
  DESTROY_SCHEDULE_SUCCESS: 'DESTROY_BRAINSTORMER_SCHEDULE_SUCCESS',
  DESTROY_SCHEDULE_FAILURE: 'DESTROY_BRAINSTORMER_SCHEDULE_FAILURE',
  RESET_SCHEDULE_ERRORS: 'RESET_BRAINSTORMER_SCHEDULE_ERRORS'
}
