import produce from 'immer'
import constants from 'constants/selected-recipients'

const initialState = {
  recipients: [],
  count: 0,
  query: {
    page: 1,
    per_page: 25
  },
  filters: {
    search: ''
  },
  meta: {
    count: 0
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_ALL_SELECTED_RECIPIENTS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_ALL_SELECTED_RECIPIENTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.recipients = action.recipients
      draft.meta = action.meta
      break
    case constants.FETCH_ALL_SELECTED_RECIPIENTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SELECTED_RECIPIENTS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_SELECTED_RECIPIENTS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.SAVE_ALL_SELECTED_RECIPIENTS_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_ALL_SELECTED_RECIPIENTS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.recipients = action.recipients
      break
    case constants.SAVE_ALL_SELECTED_RECIPIENTS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.UPDATE_SELECTED_RECIPIENTS_COUNT:
      draft.count = action.count
      break
    case constants.RESET_SELECTED_RECIPIENTS:
      return initialState
  }
}, initialState)
