import produce from 'immer'
import constants from '../constants/projects'

const initialState = {
  projects: [],
  recommendations: [],
  allProjectsList: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  query: {
    page: 1,
    per_page: 25
  },
  filters: {
    state: 'all',
    search: '',
    sort: {} // column: order
  },
  search: '',
  meta: {
    all: 0,
    published: 0,
    draft: 0,
    archived: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECTS_REQUEST:
    case constants.FETCH_ALL_PROJECTS_REQUEST:
    case constants.FETCH_RECOMMENDED_PROJECTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECTS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.projects = action.projects
      draft.meta = action.meta
      break
    case constants.FETCH_ALL_PROJECTS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.allProjectsList = action.projects
      break
    case constants.FETCH_PROJECTS_FAILURE:
    case constants.FETCH_ALL_PROJECTS_FAILURE:
    case constants.FETCH_RECOMMENDED_PROJECTS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.FETCH_RECOMMENDED_PROJECTS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.recommendations = action.recommendations
      break
    case constants.UPDATE_PROJECTS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_PROJECTS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.FILTER_PROJECTS_BY_NAME:
      draft.search = action.search
      for (let key in draft.allProjectsList) {
        let project = draft.allProjectsList[key]
        // hide the project if the condition is not satisfied
        project['hidden'] = project.name.toLowerCase().indexOf(action.search.toLowerCase()) === -1
      }
      break
    case constants.TOGGLE_PROJECT:
      draft.allProjectsList.forEach(project => {
        if (project.id === action.id) {
          project.selected = !project.selected
        }
        return project
      })
      break
    case constants.TOGGLE_ALL_PROJECTS:
      draft.allProjectsList.forEach(project => {
        if (!project.hidden) {
          // All the filtered out projects should not be changed, on Select All Checked
          project.selected = action.selected
        }
        return project
      })
      break
    case constants.RESET_PROJECTS_PAGE:
      return initialState
  }
}, initialState)
