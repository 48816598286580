export default {
  FETCH_SUBMISSIONS_REQUEST: 'FETCH_SUBMISSIONS_REQUEST',
  FETCH_SUBMISSIONS_SUCCESS: 'FETCH_SUBMISSIONS_SUCCESS',
  FETCH_SUBMISSIONS_FAILURE: 'FETCH_SUBMISSIONS_FAILURE',
  FETCH_ALL_SUBMISSIONS_REQUEST: 'FETCH_ALL_SUBMISSIONS_REQUEST',
  FETCH_ALL_SUBMISSIONS_SUCCESS: 'FETCH_ALL_SUBMISSIONS_SUCCESS',
  FETCH_ALL_SUBMISSIONS_FAILURE: 'FETCH_ALL_SUBMISSIONS_FAILURE',
  UPDATE_SUBMISSIONS: 'UPDATE_SUBMISSIONS',
  UPDATE_SUBMISSIONS_QUERY: 'UPDATE_SUBMISSIONS_QUERY',
  UPDATE_SUBMISSIONS_FILTERS: 'UPDATE_SUBMISSIONS_FILTERS',
  UPDATE_DOWNLOAD_FILTERS: 'UPDATE_DOWNLOAD_FILTERS',
  RESET_SUBMISSIONS_TABLE: 'RESET_SUBMISSIONS_TABLE',
  SAVE_SUBMISSION_REQUEST: 'SAVE_SUBMISSION_REQUEST',
  SAVE_SUBMISSION_SUCCESS: 'SAVE_SUBMISSION_SUCCESS',
  SAVE_SUBMISSION_FAILURE: 'SAVE_SUBMISSION_FAILURE',
  SHOW_BULK_ACTIONS: 'SHOW_BULK_ACTIONS',
  SHOW_SELECT_ALL_STATEMENT: 'SHOW_SELECT_ALL_STATEMENT',
  HIDE_SELECT_ALL_STATEMENT: 'HIDE_SELECT_ALL_STATEMENT',
  ADD_SUBMISSIONS_IDS: 'ADD_SUBMISSIONS_IDS',
  BULK_REVIEW_REQUEST: 'BULK_REVIEW_REQUEST',
  BULK_REVIEW_SUCCESS: 'BULK_REVIEW_SUCCESS',
  BULK_REVIEW_FAILURE: 'BULK_REVIEW_FAILURE',
  TOGGLE_DOC_EDIT_POPUP: 'TOGGLE_DOC_EDIT_POPUP',
  SAVE_DOCUMENT_REQUEST: 'SAVE_SURVEY_DOCUMENT_REQUEST',
  SAVE_DOCUMENT_SUCCESS: 'SAVE_SURVEY_DOCUMENT_SUCCESS',
  SAVE_DOCUMENT_FAILURE: 'SAVE_SURVEY_DOCUMENT_FAILURE',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  TOGGLE_RESPONSE_DRAWER: 'TOGGLE_RESPONSE_DRAWER',
  UPDATE_SELECTED_SUBMISSION: 'UPDATE_SELECTED_SUBMISSION',
  SET_RESPONSE_DRAWER_ACTIVE_TAB: 'SET_RESPONSE_DRAWER_ACTIVE_TAB',
  SET_FILTER_ACTIVE_TAB: 'SET_FILTER_ACTIVE_TAB',
  INITIATE_SUBMISSION_DOWNLOAD_REQUEST: 'INITIATE_SUBMISSION_DOWNLOAD_REQUEST',
  INITIATE_SUBMISSION_DOWNLOAD_SUCCESS: 'INITIATE_SUBMISSION_DOWNLOAD_SUCCESS',
  INITIATE_SUBMISSION_DOWNLOAD_FAILURE: 'INITIATE_SUBMISSION_DOWNLOAD_FAILURE',
  SUBMISSION_DOWNLOAD_REQUEST: 'SUBMISSION_DOWNLOAD_REQUEST',
  SUBMISSION_DOWNLOAD_SUCCESS: 'SUBMISSION_DOWNLOAD_SUCCESS',
  SUBMISSION_DOWNLOAD_FAILURE: 'SUBMISSION_DOWNLOAD_FAILURE',
  DOWNLOAD_POLLING_REQUEST: 'DOWNLOAD_POLLING_REQUEST',
  DOWNLOAD_POLLING_COMPLETED: 'DOWNLOAD_POLLING_COMPLETED',
  DOWNLOAD_POLLING_FAILURE: 'DOWNLOAD_POLLING_FAILURE',
  SET_SELECTED_DOCUMENTS: 'SET_SELECTED_DOCUMENTS',
  SET_PUBLISH_KEYS: 'SET_PUBLISH_KEYS',
  ADD_DOCUMENTS_IDS: 'ADD_DOCUMENTS_IDS',
  PUBLISH_POPOVER_TOGGLE: 'PUBLISH_POPOVER_TOGGLE',
  PUBLISH_BUTTON_DISABLE: 'PUBLISH_BUTTON_DISABLE',
  UNPUBLISH_SUBMISSION_REQUEST: 'UNPUBLISH_SUBMISSION_REQUEST',
  UNPUBLISH_SUBMISSION_SUCCESS: 'UNPUBLISH_SUBMISSION_SUCCESS',
  UNPUBLISH_SUBMISSION_FAILURE: 'UNPUBLISH_SUBMISSION_FAILURE',
  PUBLISH_SUBMISSION_REQUEST: 'PUBLISH_SUBMISSION_REQUEST',
  PUBLISH_SUBMISSION_SUCCESS: 'PUBLISH_SUBMISSION_SUCCESS',
  PUBLISH_SUBMISSION_FAILURE: 'PUBLISH_SUBMISSION_FAILURE',
  BULK_UNPUBLISH_REQUEST: 'BULK_UNPUBLISH_REQUEST',
  BULK_UNPUBLISH_SUCCESS: 'BULK_UNPUBLISH_SUCCESS',
  BULK_UNPUBLISH_FAILURE: 'BULK_UNPUBLISH_FAILURE',
  BULK_PUBLISH_REQUEST: 'BULK_PUBLISH_REQUEST',
  BULK_PUBLISH_SUCCESS: 'BULK_PUBLISH_SUCCESS',
  BULK_PUBLISH_FAILURE: 'BULK_PUBLISH_FAILURE',
  RESET_ERRORS: 'RESET_ERRORS'
}
