export default {
  FETCH_STAGES_REQUEST: 'FETCH_LIFECYCLE_STAGES_REQUEST',
  FETCH_STAGES_SUCCESS: 'FETCH_LIFECYCLE_STAGES_SUCCESS',
  FETCH_STAGES_FAILURE: 'FETCH_LIFECYCLE_STAGES_FAILURE',
  REORDER_STAGES: 'REORDER_LIFECYCLE_STAGES',
  RESET_STAGES: 'RESET_LIFECYCLE_STAGES',
  DELETE_STAGE_REQUEST: 'DELETE_LIFECYCLE_STAGE_REQUEST',
  DELETE_STAGE_SUCCESS: 'DELETE_LIFECYCLE_STAGE_SUCCESS',
  DELETE_STAGE_FAILURE: 'DELETE_LIFECYCLE_STAGE_FAILURE',
  SET_CURRENT_STAGE_REQUEST: 'SET_CURRENT_LIFECYCLE_STAGE_REQUEST',
  SET_CURRENT_STAGE_SUCCESS: 'SET_CURRENT_LIFECYCLE_STAGE_SUCCESS',
  SET_CURRENT_STAGE_FAILURE: 'SET_CURRENT_LIFECYCLE_STAGE_FAILURE',
  CREATE_STAGE_REQUEST: 'CREATE_LIFECYCLE_STAGE_REQUEST',
  CREATE_STAGE_SUCCESS: 'CREATE_LIFECYCLE_STAGE_SUCCESS',
  CREATE_STAGE_FAILURE: 'CREATE_LIFECYCLE_STAGE_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_LIFECYCLE_STAGE_ERRORS',
  SAVE_STAGE_REQUEST: 'SAVE_LIFECYCLE_STAGE_REQUEST',
  SAVE_STAGE_SUCCESS: 'SAVE_LIFECYCLE_STAGE_SUCCESS',
  SAVE_STAGE_FAILURE: 'SAVE_LIFECYCLE_STAGE_FAILURE',
  RESET_STAGE_ERRORS: 'RESET_LIFECYCLE_STAGE_ERRORS',
  SET_ACTIVE_STAGE: 'SET_ACTIVE_LIFECYCLE_STAGE',
  UNSET_ACTIVE_STAGE: 'UNSET_ACTIVE_LIFECYCLE_STAGE',
  CLONE_STAGES_REQUEST: 'CLONE_LIFECYCLE_STAGES_REQUEST',
  CLONE_STAGES_SUCCESS: 'CLONE_LIFECYCLE_STAGES_SUCCESS',
  CLONE_STAGES_FAILURE: 'CLONE_LIFECYCLE_STAGES_FAILURE',
  UPDATE_STAGE: 'UPDATE_LIFECYCLE_STAGE'
}
