import produce from 'immer'
import constants from 'constants/sms-integration'

const initialState = {
  smsIntegration: {},
  filters: {
    isActive: '',
    toolType: '',
    toolId: null
  },
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  deleting: false,
  deleteError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SMS_INTEGRATION_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_SMS_INTEGRATION_SUCCESS:
      draft.fetchError = ''
      draft.fetching = false
      draft.smsIntegration = action.smsIntegration
      break

    case constants.FETCH_SMS_INTEGRATION_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_SMS_INTEGRATION:
      draft.smsIntegration = action.smsIntegration
      break

    case constants.UPDATE_SMS_INTEGRATION_NUMBER:
      draft.smsIntegration.dedicatedNumberId = action.dedicatedNumberId
      draft.smsIntegration.projectId = action.projectId
      draft.smsIntegration.toolId = action.toolId
      draft.smsIntegration.toolType = action.toolType
      break

    case constants.UPDATE_SMS_INTEGRATION_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break

    case constants.UPDATE_THANK_YOU_MESSAGE:
      draft.smsIntegration.message = action.message
      break

    case constants.DISSOCIATE_TOOL_AND_PROJECT:
      draft.smsIntegration.toolId = null
      draft.smsIntegration.projectId = null
      break

    case constants.SAVE_SMS_INTEGRATION_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_SMS_INTEGRATION_SUCCESS:
      draft.smsIntegration = action.integration
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break

    case constants.SAVE_SMS_INTEGRATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.DELETE_SMS_INTEGRATION_REQUEST:
      draft.deleting = true
      break

    case constants.DELETE_SMS_INTEGRATION_SUCCESS:
      draft.deleting = false
      draft.deleteError = ''
      draft.errors = {}
      break

    case constants.DELETE_SMS_INTEGRATION_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
