export default {
  FETCH_WIDGETS_REQUEST: 'FETCH_WIDGETS_REQUEST',
  FETCH_WIDGETS_SUCCESS: 'FETCH_WIDGETS_SUCCESS',
  FETCH_WIDGETS_FAILURE: 'FETCH_WIDGETS_FAILURE',
  SET_ACTIVE_WIDGET: 'SET_ACTIVE_WIDGET',
  UNSET_ACTIVE_WIDGET: 'UNSET_ACTIVE_WIDGET',
  SAVE_WIDGET_REQUEST: 'SAVE_WIDGET_REQUEST',
  SAVE_WIDGET_SUCCESS: 'SAVE_WIDGET_SUCCESS',
  SAVE_WIDGET_FAILURE: 'SAVE_WIDGET_FAILURE',
  DELETE_WIDGET_REQUEST: 'DELETE_WIDGET_REQUEST',
  DELETE_WIDGET_SUCCESS: 'DELETE_WIDGET_SUCCESS',
  DELETE_WIDGET_FAILURE: 'DELETE_WIDGET_FAILURE',
  RESET_WIDGETS: 'RESET_WIDGETS',
  REORDER_WIDGETS: 'REORDER_WIDGETS',
  CREATE_WIDGET_REQUEST: 'CREATE_WIDGET_REQUEST',
  CREATE_WIDGET_SUCCESS: 'CREATE_WIDGET_SUCCESS',
  CREATE_WIDGET_FAILURE: 'CREATE_WIDGET_FAILURE',
  SET_RESOURCE_COUNT: 'SET_WIDGET_RESOURCE_COUNT'
}
