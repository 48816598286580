export default {
  FETCH_HUBS_REQUEST: 'FETCH_HUBS_REQUEST',
  FETCH_HUBS_SUCCESS: 'FETCH_HUBS_SUCCESS',
  FETCH_HUBS_FAILURE: 'FETCH_HUBS_FAILURE',
  QUERY_HUBS_REQUEST: 'QUERY_HUBS_REQUEST',
  QUERY_HUBS_SUCCESS: 'QUERY_HUBS_SUCCESS',
  QUERY_HUBS_FAILURE: 'QUERY_HUBS_FAILURE',
  UPDATE_HUBS_QUERY: 'UPDATE_HUBS_QUERY',
  UPDATE_HUBS_FILTERS: 'UPDATE_HUBS_FILTERS',
  RESET_HUBS: 'RESET_HUBS'
}
