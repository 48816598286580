import produce from 'immer'
import constants from 'constants/survey'

const initialState = {
  survey: {},
  displayCloneSurvey: false,
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  activeTab: 'manageQuestions'
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SURVEY_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SURVEY_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.survey = action.survey
      break
    case constants.FETCH_SURVEY_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SURVEY:
      draft.survey[action.key] = action.value
      draft[action.key] = action.value
      break
    case constants.SAVE_SURVEY_REQUEST:
    case constants.CLONE_SURVEY_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_SURVEY_SUCCESS:
    case constants.CLONE_SURVEY_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.survey = action.survey
      break
    case constants.SAVE_SURVEY_FAILURE:
    case constants.CLONE_SURVEY_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SET_DEFAULTS:
      for (const key in action.defaults) {
        draft.survey[key] = action.defaults[key]
      }
      break
    case constants.FETCH_SURVEY_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_SURVEY_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.survey.permalink = action.permalink
      draft.survey.holdPostRequest = false
      break
    case constants.FETCH_SURVEY_PERMALINK_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      draft.survey.permalink = ''
      break
    case constants.TOGGLE_SURVEY_CLONE:
      draft.displayCloneSurvey = !draft.displayCloneSurvey
      break
    case constants.SET_ACTIVE_TAB:
      draft.activeTab = action.activeTab
      break
    case constants.RESET_SURVEY:
      return initialState
  }
}, initialState)
