import produce from 'immer'
import constants from 'constants/photos'

const initialState = {
  active: '',
  photos: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PHOTOS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PHOTOS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.photos = action.photos
      break
    case constants.FETCH_PHOTOS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_PHOTOS:
      (() => {
        for (let key in action.updates) {
          draft.photos[key].sequence = action.updates[key]
        }
      })()
      break
    case constants.CREATE_PHOTO_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_PHOTO_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.photos[action.photo.id] = action.photo
      break
    case constants.CREATE_PHOTO_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_PHOTO_REQUEST:
      draft.photos[action.id].deleting = true
      break
    case constants.DELETE_PHOTO_SUCCESS:
      delete draft.photos[action.id]
      break
    case constants.DELETE_PHOTO_FAILURE:
      draft.photos[action.id].deleting = false
      draft.photos[action.id].deleteError = action.errorMessage
      break
    case constants.SET_ACTIVE_PHOTO:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_PHOTO:
      draft.active = ''
      break
    case constants.SAVE_PHOTO_REQUEST:
      draft.photos[action.id].saving = true
      break
    case constants.SAVE_PHOTO_SUCCESS:
      draft.photos[action.id].saving = false
      draft.photos[action.id].saveError = ''
      draft.photos[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.photos[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_PHOTO_FAILURE:
      draft.photos[action.id].saving = false
      draft.photos[action.id].saveError = action.errorMessage
      draft.photos[action.id].errors = action.errors
      break
    case constants.RESET_PHOTO_ERRORS:
      draft.photos[action.id].saveError = ''
      draft.photos[action.id].errors = {}
      break
    case constants.CLONE_PHOTOS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_PHOTOS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.photos.forEach((photo) => {
        draft.photos[photo.id] = photo
      })
      break
    case constants.CLONE_PHOTOS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.RESET_PHOTOS :
      return initialState
  }
}, initialState)
