export default {
  FETCH_SURVEY_RESPONSE_REQUEST: 'FETCH_SURVEY_RESPONSE_REQUEST',
  FETCH_SIGNUP_RESPONSE_REQUEST: 'FETCH_SIGNUP_RESPONSE_REQUEST',
  FETCH_SURVEY_RESPONSE_SUCCESS: 'FETCH_SURVEY_RESPONSE_SUCCESS',
  FETCH_SIGNUP_RESPONSE_SUCCESS: 'FETCH_SIGNUP_RESPONSE_SUCCESS',
  FETCH_SURVEY_RESPONSE_FAILURE: 'FETCH_SURVEY_RESPONSE_FAILURE',
  FETCH_SIGNUP_RESPONSE_FAILURE: 'FETCH_SIGNUP_RESPONSE_FAILURE',
  RESET_SIGNUP_RESPONSE: 'RESET_SIGNUP_RESPONSE',
  RESET_SURVEY_RESPONSE: 'RESET_SURVEY_RESPONSE'
}
