export default {
  FETCH_ALL_RECIPIENTS_REQUEST: 'FETCH_ALL_RECIPIENTS_REQUEST',
  FETCH_ALL_RECIPIENTS_SUCCESS: 'FETCH_ALL_RECIPIENTS_SUCCESS',
  FETCH_ALL_RECIPIENTS_FAILURE: 'FETCH_ALL_RECIPIENTS_FAILURE',
  UPDATE_RECIPIENTS_QUERY: 'UPDATE_RECIPIENTS_QUERY',
  UPDATE_RECIPIENTS_FILTERS: 'UPDATE_RECIPIENTS_FILTERS',
  TOGGLE_RECIPIENT: 'TOGGLE_RECIPIENT',
  TOGGLE_ALL_RECIPIENTS: 'TOGGLE_ALL_RECIPIENTS',
  RESET_RECIPIENTS: 'RESET_RECIPIENTS',
  Add_SUBSCRIBERS: 'Add_SUBSCRIBERS',
  FETCH_ALL_RECIPIENTS_ON_SAVE: 'FETCH_ALL_RECIPIENTS_ON_SAVE'
}
