export default {
  FETCH_SURVEY_EMAIL_TEMPLATES_REQUEST: 'FETCH_SURVEY_EMAIL_TEMPLATES_REQUEST',
  FETCH_SURVEY_EMAIL_TEMPLATES_SUCCESS: 'FETCH_SURVEY_EMAIL_TEMPLATES_SUCCESS',
  FETCH_SURVEY_EMAIL_TEMPLATES_FAILURE: 'FETCH_SURVEY_EMAIL_TEMPLATES_FAILURE',
  CREATE_SURVEY_EMAIL_TEMPLATE_REQUEST: 'CREATE_SURVEY_EMAIL_TEMPLATE_REQUEST',
  CREATE_SURVEY_EMAIL_TEMPLATE_SUCCESS: 'CREATE_SURVEY_EMAIL_TEMPLATE_SUCCESS',
  CREATE_SURVEY_EMAIL_TEMPLATE_FAILURE: 'CREATE_SURVEY_EMAIL_TEMPLATE_FAILURE',
  UPDATE_SURVEY_EMAIL_TEMPLATE: 'UPDATE_SURVEY_EMAIL_TEMPLATE',
  SAVE_SURVEY_EMAIL_TEMPLATE_REQUEST: (identifier = '') => `SAVE_SURVEY_EMAIL_TEMPLATE_REQUEST_${identifier.toUpperCase()}`,
  SAVE_SURVEY_EMAIL_TEMPLATE_SUCCESS: (identifier = '') => `SAVE_SURVEY_EMAIL_TEMPLATE_SUCCESS_${identifier.toUpperCase()}`,
  SAVE_SURVEY_EMAIL_TEMPLATE_FAILURE: (identifier = '') => `SAVE_SURVEY_EMAIL_TEMPLATE_FAILURE_${identifier.toUpperCase()}`
}
