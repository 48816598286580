import produce from 'immer'
import constants from '../constants/message-texts'

const initialState = {
  messageTexts: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_MESSAGE_TEXTS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_MESSAGE_TEXTS_SUCCESS:
      draft.messageTexts = action.messageTexts
      draft.registrationPage = action.messageTexts.registrationPage
      draft.welcomePage = action.messageTexts.welcomePage
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''

      break

    case constants.FETCH_MESSAGE_TEXTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.UPDATE_MESSAGE_TEXT:
      draft.messageTexts[action.key] = action.value
      draft[action.key] = action.value
      break

    case constants.SAVE_MESSAGE_TEXTS_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_MESSAGE_TEXTS_SUCCESS:
      draft.messageTexts[action.id] = action.template
      draft.saving = false
      draft.saveError = ''

      break

    case constants.SAVE_MESSAGE_TEXTS_FAILURE:
      draft.saveError = action.errorMessage
      draft.saving = false

      break
  }
}, initialState)
