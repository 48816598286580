import produce from 'immer'
import constants from 'constants/team-members'

const initialState = {
  active: '',
  teamMembers: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_TEAM_MEMBERS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_TEAM_MEMBERS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.teamMembers = action.teamMembers
      break
    case constants.FETCH_TEAM_MEMBERS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.CREATE_TEAM_MEMBERS_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_TEAM_MEMBERS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.teamMembers[action.teamMember.id] = action.teamMember
      break
    case constants.CREATE_TEAM_MEMBERS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SAVE_TEAM_MEMBER_REQUEST:
      draft.teamMembers[action.id].saving = true
      break
    case constants.SAVE_TEAM_MEMBER_SUCCESS:
      draft.teamMembers[action.id].saving = false
      draft.teamMembers[action.id].saveError = ''
      draft.teamMembers[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.teamMembers[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_TEAM_MEMBER_FAILURE:
      draft.teamMembers[action.id].saving = false
      draft.teamMembers[action.id].saveError = action.errorMessage
      draft.teamMembers[action.id].errors = action.errors
      break
    case constants.DELETE_TEAM_MEMBERS_REQUEST:
      draft.teamMembers[action.id].deleting = true
      break
    case constants.DELETE_TEAM_MEMBERS_SUCCESS:
      delete draft.teamMembers[action.id]
      break
    case constants.DELETE_TEAM_MEMBERS_FAILURE:
      draft.teamMembers[action.id].deleting = false
      draft.teamMembers[action.id].deleteError = action.errorMessage
      break
    case constants.CLONE_TEAM_MEMBERS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_TEAM_MEMBERS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.teamMembers.forEach((teamMember) => {
        draft.teamMembers[teamMember.id] = teamMember
      })
      break
    case constants.CLONE_TEAM_MEMBERS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.SET_ACTIVE_TEAM_MEMBER:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_TEAM_MEMBER:
      draft.active = ''
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.RESET_TEAM_MEMBER_ERRORS:
      draft.teamMembers[action.id].saveError = ''
      draft.teamMembers[action.id].errors = {}
      break
    case constants.REORDER_TEAM_MEMBERS:
      (() => {
        for (let key in action.updates) {
          draft.teamMembers[key].position = action.updates[key]
        }
      })()
      break
    case constants.RESET_TEAM_MEMBERS:
      return initialState
  }
}, initialState)
