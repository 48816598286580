import produce from 'immer'
import constants from 'constants/mailchimp-segments'

const initialState = {
  segments: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SEGMENTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SEGMENTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.segments = action.segments
      break
    case constants.FETCH_SEGMENTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SYNC_SEGMENTS_REQUEST:
      draft.saving = true
      break
    case constants.SYNC_SEGMENTS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.SYNC_SEGMENTS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.SAVE_RESET_MAILCHIMP_SEGMENTS:
      return initialState
  }
}, initialState)
