import produce from 'immer'
import constants from '../constants/mailchimp'

const initialState = {
  activeTab: 'syncGroups'
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.UPDATE_ACTIVE_TAB:
      draft.activeTab = action.activeTab

      break
    case constants.RESET_MAILCHIMP_PAGE:
      return initialState
  }
}, initialState)
