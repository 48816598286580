export default {
  FETCH_STORY_COLLECTION_REQUEST: 'FETCH_STORY_COLLECTION_REQUEST',
  FETCH_STORY_COLLECTION_SUCCESS: 'FETCH_STORY_COLLECTION_SUCCESS',
  FETCH_STORY_COLLECTION_FAILURE: 'FETCH_STORY_COLLECTION_FAILURE',
  UPDATE_STORY_COLLECTION: 'UPDATE_STORY_COLLECTION',
  SAVE_STORY_COLLECTION_REQUEST: 'SAVE_STORY_COLLECTION_REQUEST',
  SAVE_STORY_COLLECTION_SUCCESS: 'SAVE_STORY_COLLECTION_SUCCESS',
  SAVE_STORY_COLLECTION_FAILURE: 'SAVE_STORY_COLLECTION_FAILURE',
  RESET_STORY_COLLECTION: 'RESET_STORY_COLLECTION'
}
