import produce from 'immer'
import constants from '../constants/text-analysis'

const initialState = {
  showTable: false,
  showSentiment: true,
  selectedProjectId: '',
  selectedToolId: '',
  questionType: '',
  filters: {},
  query: {
    page: 1,
    page_size: 10
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.TOGGLE_TEXT_ANALYSIS_SENTIMENTS_COLUMN:
      draft.showSentiment = !draft.showSentiment
      break
    case constants.UPDATE_TEXT_ANALYSIS_SELECTION:
      draft.selectedProjectId = action.projectId
      draft.selectedToolId = action.toolId
      break
    case constants.SET_QUESTION_TYPE:
      draft.questionType = action.questionType
      break
    case constants.HIDE_SHOW_TABLE:
      draft.showTable = action.flag
      break
    case constants.UPDATE_TEXT_ANALYSIS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.UPDATE_TEXT_ANALYSIS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
  }
}, initialState)
