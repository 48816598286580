export default {
  FETCH_QUESTIONS_REQUEST: 'FETCH_QANDA_QUESTIONS_REQUEST',
  FETCH_QUESTIONS_SUCCESS: 'FETCH_QANDA_QUESTIONS_SUCCESS',
  FETCH_QUESTIONS_FAILURE: 'FETCH_QANDA_QUESTIONS_FAILURE',
  PUBLICLY_ANSWER_REQUEST: 'PUBLICLY_ANSWER_REQUEST',
  PUBLICLY_ANSWER_SUCCESS: 'PUBLICLY_ANSWER_SUCCESS',
  PUBLICLY_ANSWER_FAILURE: 'PUBLICLY_ANSWER_FAILURE',
  PRIVATELY_ANSWER_REQUEST: 'PRIVATELY_ANSWER_REQUEST',
  PRIVATELY_ANSWER_SUCCESS: 'PRIVATELY_ANSWER_SUCCESS',
  PRIVATELY_ANSWER_FAILURE: 'PRIVATELY_ANSWER_FAILURE',
  ARCHIVE_QUESTION_REQUEST: 'ARCHIVE_QANDA_QUESTION_REQUEST',
  ARCHIVE_QUESTION_SUCCESS: 'ARCHIVE_QANDA_QUESTION_SUCCESS',
  ARCHIVE_QUESTION_FAILURE: 'ARCHIVE_QANDA_QUESTION_FAILURE',
  RESTORE_QUESTION_REQUEST: 'RESTORE_QANDA_QUESTION_REQUEST',
  RESTORE_QUESTION_SUCCESS: 'RESTORE_QANDA_QUESTION_SUCCESS',
  RESTORE_QUESTION_FAILURE: 'RESTORE_QANDA_QUESTION_FAILURE',
  JUNK_QUESTION_REQUEST: 'JUNK_QANDA_QUESTION_REQUEST',
  JUNK_QUESTION_SUCCESS: 'JUNK_QANDA_QUESTION_SUCCESS',
  JUNK_QUESTION_FAILURE: 'JUNK_QANDA_QUESTION_FAILURE',
  SALVAGE_QUESTION_REQUEST: 'SALVAGE_QANDA_QUESTION_REQUEST',
  SALVAGE_QUESTION_SUCCESS: 'SALVAGE_QANDA_QUESTION_SUCCESS',
  SALVAGE_QUESTION_FAILURE: 'SALVAGE_QANDA_QUESTION_FAILURE',
  TOGGLE_QUESTION: 'TOGGLE_QANDA_QUESTION',
  DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
  DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  UPDATE_QUESTION_QUERY: 'UPDATE_QUESTION_QUERY'
}
