module.exports = {
  FETCH_FOLDERS_REQUEST: 'FETCH_FOLDERS_REQUEST',
  FETCH_FOLDERS_SUCCESS: 'FETCH_FOLDERS_SUCCESS',
  FETCH_FOLDERS_FAILURE: 'FETCH_FOLDERS_FAILURE',
  RESET_FOLDERS: 'RESET_FOLDERS',
  REORDER_FOLDER: 'REORDER_FOLDER',
  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_FOLDER_ERRORS',
  DELETE_FOLDER_REQUEST: 'DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAILURE: 'DELETE_FOLDER_FAILURE',
  SET_ACTIVE_FOLDER: 'SET_ACTIVE_FOLDER',
  UNSET_ACTIVE_FOLDER: 'UNSET_ACTIVE_FOLDER',
  SAVE_FOLDER_REQUEST: 'SAVE_FOLDER_REQUEST',
  SAVE_FOLDER_SUCCESS: 'SAVE_FOLDER_SUCCESS',
  SAVE_FOLDER_FAILURE: 'SAVE_FOLDER_FAILURE',
  RESET_FOLDER_ERRORS: 'RESET_FOLDER_ERRORS',
  SET_FOLDER: 'SET_FOLDER'
}
