export default {
  FETCH_LAYERS_REQUEST: 'FETCH_MAP_LAYERS_REQUEST',
  FETCH_LAYERS_SUCCESS: 'FETCH_MAP_LAYERS_SUCCESS',
  FETCH_LAYERS_FAILURE: 'FETCH_MAP_LAYERS_FAILURE',
  UPDATE_ACTIVE_LAYER_KEY: 'UPDATE_ACTIVE_LAYER_KEY',
  SET_ACTIVE_LAYER_STYLE_KEY: 'SET_ACTIVE_LAYER_STYLE_KEY',
  REPLACE_LAYER_STATE: 'REPLACE_LAYER_STATE',
  UPDATE_LAYER: 'UPDATE_MAP_LAYER',
  UPDATE_MAP_LAYER_METADATA: 'UPDATE_MAP_LAYER_METADATA',
  UPDATE_MAP_LAYER_FEATURE_COLLECTION: 'UPDATE_MAP_LAYER_FEATURE_COLLECTION',
  UPDATE_MAP_LAYER_PROPERTY_VISIBILITY: 'UPDATE_MAP_LAYER_PROPERTY_VISIBILITY',
  UPDATE_MAP_LAYERS_QUERY: 'UPDATE_MAP_LAYERS_QUERY',
  HIDE_LAYER_REQUEST: 'HIDE_MAP_LAYER_REQUEST',
  HIDE_LAYER_SUCCESS: 'HIDE_MAP_LAYER_SUCCESS',
  HIDE_LAYER_FAILURE: 'HIDE_MAP_LAYER_FAILURE',
  UNHIDE_LAYER_REQUEST: 'UNHIDE_MAP_LAYER_REQUEST',
  UNHIDE_LAYER_SUCCESS: 'UNHIDE_MAP_LAYER_SUCCESS',
  UNHIDE_LAYER_FAILURE: 'UNHIDE_MAP_LAYER_FAILURE',
  DESTROY_LAYER_REQUEST: 'DESTROY_MAP_LAYER_REQUEST',
  DESTROY_LAYER_SUCCESS: 'DESTROY_MAP_LAYER_SUCCESS',
  DESTROY_LAYER_FAILURE: 'DESTROY_MAP_LAYER_FAILURE',
  DESTROY_DRAFT_LAYER: 'DESTROY_DRAFT_LAYER',
  SAVE_LAYER_REQUEST: 'SAVE_MAP_LAYER_REQUEST',
  SAVE_LAYER_SUCCESS: 'SAVE_MAP_LAYER_SUCCESS',
  SAVE_LAYER_FAILURE: 'SAVE_MAP_LAYER_FAILURE',
  ADD_MAP_LAYER: 'ADD_MAP_LAYER',
  REORDER_LAYERS: 'REORDER_MAP_LAYERS',
  RESET_LAYERS: 'RESET_MAP_LAYERS',
  RESET_LAYER: 'RESET_MAP_LAYER',
  RESET_ACTIVE_LAYER_KEY: 'RESET_ACTIVE_LAYER_KEY',
  RESET_ACTIVE_LAYER_STYLE_KEY: 'RESET_ACTIVE_LAYER_STYLE_KEY',
  RESET_ERRORS: 'RESET_CREATE_MAP_LAYER_ERRORS',
  RESET_LAYER_ERRORS: 'RESET_MAP_LAYER_ERRORS',
  CUSTOM_LAYER_STATUS: 'CUSTOM_LAYER_STATUS',
}
