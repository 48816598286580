export default {
  FETCH_FEATURES_REQUEST: 'FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_SUCCESS: 'FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE: 'FETCH_FEATURES_FAILURE',
  ENABLE_FEATURE_REQUEST: 'ENABLE_FEATURE_REQUEST',
  ENABLE_FEATURE_SUCCESS: 'ENABLE_FEATURE_SUCCESS',
  ENABLE_FEATURE_FAILURE: 'ENABLE_FEATURE_FAILURE',
  DISABLE_FEATURE_REQUEST: 'DISABLE_FEATURE_REQUEST',
  DISABLE_FEATURE_SUCCESS: 'DISABLE_FEATURE_SUCCESS',
  DISABLE_FEATURE_FAILURE: 'DISABLE_FEATURE_FAILURE',
  TOGGLE_FEATURE: 'TOGGLE_FEATURE'
}
