import produce from 'immer'
import constants from 'constants/marketplaces'

const initialState = {
  marketplaces: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  errors: {},
  queries: {},
  filters: {
    searchQuery: null,
    integrationType: null,
    lang: ''
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_MARKETPLACES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_MARKETPLACES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.marketplaces = action.marketplaces
      break
    case constants.FETCH_MARKETPLACES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_MARKETPLACES_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.RESET_MARKETPLACES:
      return initialState
  }
}, initialState)
