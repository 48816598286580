import produce from 'immer'
import constants from '../constants/submissions'
import find from 'lodash/find'

const initialState = {
  submissions: [],
  allSubmissions: [],
  fetching: false,
  doneFetching: false,
  showTable: false,
  filterActiveTab: 'all',
  showDrawer: false,
  selectedSubmission: {},
  fetchError: '',
  saving: false,
  savingDocument: false,
  saveError: '',
  errors: {},
  bulkError: '',
  showBulkActions: false,
  showSelectStatement: false,
  selectedSubmissionsCount: 0,
  totalSubmissionsCount: 0,
  allSubmissionsSelected: false,
  submissionsIds: [],
  showDocEditPopup: false,
  isResponseDrawerVisible: false,
  activeTab: 'survey',
  isDownloadJobInitiated: false,
  selectedDocuments: [],
  downloading: false,
  doneDownloading: false,
  downloadProps: {},
  downloadError: '',
  isDownloadPolling: false,
  pollError: '',
  unpublishError: '',
  publishError: '',
  widgetId: null,
  folderId: null,
  documentIds: [],
  unpublishing: false,
  publishing: false,
  published: false,
  isPopoverOpen: false,
  isPublishButtonDisable: true,
  query: {
    page: 1,
    page_size: 25
  },
  downloadFilters: {},
  filters: {
    state: 'all',
    survey_documents: {},
    reviewed: null,
    sort: {},
    search: '',
    date: {
      created_at: {
        start: '',
        finish: ''
      }
    }
  },
  meta: {
    all: 0,
    reviewed: 0,
    pendingReview: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SUBMISSIONS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      draft.showTable = true
      break
    case constants.FETCH_SUBMISSIONS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.submissions = action.submissions
      draft.meta = action.meta
      break
    case constants.FETCH_ALL_SUBMISSIONS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.allSubmissions = action.submissions
      break
    case constants.UPDATE_SUBMISSIONS:
      draft.submissions.forEach(submission => {
        if (submission.id === action.id) {
          submission[action.key] = action.value
        }
        return submission
      })
      break
    case constants.UPDATE_SUBMISSIONS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_DOWNLOAD_FILTERS:
      draft.downloadFilters = { ...draft.downloadFilters, ...action.filters }
      break
    case constants.UPDATE_SUBMISSIONS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      // remove selections on data change
      draft.showSelectStatement = false
      draft.allSubmissionsSelected = false
      draft.submissionIds = []
      break
    case constants.FETCH_SUBMISSIONS_FAILURE:
    case constants.FETCH_ALL_SUBMISSIONS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.SAVE_SUBMISSION_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_SUBMISSION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.SAVE_SUBMISSION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.SHOW_BULK_ACTIONS:
      if (action.length > 0) {
        draft.showBulkActions = true
      } else {
        draft.showBulkActions = false
        draft.showSelectStatement = false
        draft.allSubmissionsSelected = false
      }
      break
    case constants.SHOW_SELECT_ALL_STATEMENT:
      draft.allSubmissionsSelected = true
      draft.showSelectStatement = false
      break
    case constants.HIDE_SELECT_ALL_STATEMENT:
      draft.showSelectStatement = false
      draft.allSubmissionsSelected = false
      draft.submissionIds = []
      break
    case constants.ADD_SUBMISSIONS_IDS:
      draft.submissionsIds = [...action.ids]
      if (action.selected) {
        draft.showSelectStatement = false
        draft.allSubmissionsSelected = true
      } else {
        if (draft.submissionsIds.length > 0 && draft.submissionsIds.length !== action.totalSubmissionsCount) {
          draft.showSelectStatement = true
          draft.allSubmissionsSelected = false
        }
        if (draft.submissionsIds.length === action.totalSubmissionsCount) {
          draft.showSelectStatement = false
          draft.allSubmissionsSelected = true
        }
      }
      draft.selectedSubmissionsCount = draft.submissionsIds.length
      draft.totalSubmissionsCount = action.totalSubmissionsCount
      break
    case constants.BULK_REVIEW_REQUEST:
      draft.bulkError = ''
      break
    case constants.BULK_REVIEW_SUCCESS:
      draft.bulkError = ''
      break
    case constants.BULK_REVIEW_FAILURE:
      draft.bulkError = action.errorMessage
      break
    case constants.TOGGLE_DOC_EDIT_POPUP:
      draft.showDocEditPopup = !draft.showDocEditPopup
      break
    case constants.UPDATE_DOCUMENT:
      const submission = find(draft.submissions, ['id', action.submissionId])
      submission.documents.forEach(document => {
        if (document.id === action.document.id) {
          document[action.key] = action.value
        }
        return document
      })

      draft.submissions = draft.submissions.map(draftSubmission => {
        if (draftSubmission.id === submission.id) return submission
        return draftSubmission
      })
      break
    case constants.SAVE_DOCUMENT_REQUEST:
      draft.savingDocument = true
      break
    case constants.SAVE_DOCUMENT_SUCCESS:
      draft.savingDocument = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_DOCUMENT_FAILURE:
      draft.savingDocument = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.TOGGLE_RESPONSE_DRAWER:
      draft.isResponseDrawerVisible = action.toggle
      break
    case constants.UPDATE_SELECTED_SUBMISSION:
      draft.selectedSubmission = action.submission
      break
    case constants.SET_RESPONSE_DRAWER_ACTIVE_TAB:
      draft.activeTab = action.activeTab
      break
    case constants.SET_FILTER_ACTIVE_TAB:
      draft.filterActiveTab = action.activeTab
      break
    case constants.INITIATE_SUBMISSION_DOWNLOAD_REQUEST:
      draft.isDownloadJobInitiated = true
      break
    case constants.INITIATE_SUBMISSION_DOWNLOAD_SUCCESS:
      draft.isDownloadJobInitiated = false
      draft.downloadProps = action.response
      break
    case constants.INITIATE_SUBMISSION_DOWNLOAD_FAILURE:
      draft.isDownloadJobInitiated = false
      draft.downloadError = action.errorMessage
      break
    case constants.DOWNLOAD_POLLING_REQUEST:
      draft.isDownloadPolling = true
      break
    case constants.DOWNLOAD_POLLING_COMPLETED:
      draft.isDownloadPolling = false
      draft.downloadProps = action.response
      break
    case constants.DOWNLOAD_POLLING_FAILURE:
      draft.isDownloadPolling = false
      draft.pollError = action.errorMessage
      break
    case constants.SUBMISSION_DOWNLOAD_REQUEST:
      draft.downloading = true
      draft.doneDownloading = false
      break
    case constants.SUBMISSION_DOWNLOAD_SUCCESS:
      draft.downloading = false
      draft.doneDownloading = true
      break
    case constants.SUBMISSION_DOWNLOAD_FAILURE:
      draft.downloading = false
      draft.doneDownloading = false
      draft.downloadError = action.errorMessage
      break
    case constants.SET_SELECTED_DOCUMENTS:
      draft.selectedDocuments = action.documentIds
      break
    case constants.SET_PUBLISH_KEYS:
      draft.widgetId = action.widgetId
      draft.folderId = action.folderId
      break
    case constants.ADD_DOCUMENTS_IDS:
      draft.documentIds = [...action.ids]
      break
    case constants.PUBLISH_BUTTON_DISABLE:
      draft.isPublishButtonDisable = action.value
      break
    case constants.UNPUBLISH_SUBMISSION_REQUEST:
      draft.unpublishError = ''
      draft.unpublishing = true
      break
    case constants.UNPUBLISH_SUBMISSION_SUCCESS:
      draft.unpublishError = ''
      draft.unpublishing = false
      break
    case constants.UNPUBLISH_SUBMISSION_FAILURE:
      draft.unpublishError = action.errorMessage
      draft.unpublishing = false
      break
    case constants.PUBLISH_SUBMISSION_REQUEST:
      draft.publishError = ''
      draft.publishing = true
      break
    case constants.PUBLISH_SUBMISSION_SUCCESS:
      draft.publishError = ''
      draft.publishing = false
      break
    case constants.PUBLISH_SUBMISSION_FAILURE:
      draft.publishError = action.errorMessage
      draft.publishing = false
      break
    case constants.BULK_UNPUBLISH_REQUEST:
      draft.bulkError = ''
      draft.unpublishing = true
      break
    case constants.BULK_UNPUBLISH_SUCCESS:
      draft.bulkError = ''
      draft.unpublishing = false
      break
    case constants.BULK_UNPUBLISH_FAILURE:
      draft.unpublishing = false
      draft.bulkError = action.errorMessage
      break
    case constants.BULK_PUBLISH_REQUEST:
      draft.bulkError = ''
      draft.publishing = true
      draft.published = false
      break
    case constants.BULK_PUBLISH_SUCCESS:
      draft.bulkError = ''
      draft.publishing = false
      draft.published = true
      break
    case constants.BULK_PUBLISH_FAILURE:
      draft.publishing = false
      draft.published = false
      draft.bulkError = action.errorMessage
      break
    case constants.RESET_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.RESET_SUBMISSIONS_TABLE:
      return initialState
  }
}, initialState)
