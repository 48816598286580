export default {
  FETCH_BLOG_POST_REQUEST: 'FETCH_BLOG_POST_REQUEST',
  FETCH_BLOG_POST_SUCCESS: 'FETCH_BLOG_POST_SUCCESS',
  FETCH_BLOG_POST_FAILURE: 'FETCH_BLOG_POST_FAILURE',
  UPDATE_BLOG_POST: 'UPDATE_BLOG_POST',
  SAVE_BLOG_POST_REQUEST: 'SAVE_BLOG_POST_REQUEST',
  SAVE_BLOG_POST_SUCCESS: 'SAVE_BLOG_POST_SUCCESS',
  SAVE_BLOG_POST_FAILURE: 'SAVE_BLOG_POST_FAILURE',
  RESET_BLOG_POST: 'RESET_BLOG_POST',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_BLOG_POST_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_BLOG_POST_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_BLOG_POST_IMAGE_FAILURE',
  FETCH_BLOG_POST_PERMALINK_REQUEST: 'FETCH_BLOG_POST_PERMALINK_REQUEST',
  FETCH_BLOG_POST_PERMALINK_SUCCESS: 'FETCH_BLOG_POST_PERMALINK_SUCCESS',
  FETCH_BLOG_POST_PERMALINK_FAILURE: 'FETCH_BLOG_POST_PERMALINK_FAILURE',
  SET_BLOG_POST: 'SET_BLOG_POST'
}
