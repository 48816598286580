import produce from 'immer'
import constants from '../constants/blog-posts'

const initialState = {
  blogPosts: {},
  schedules: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BLOG_POSTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_BLOG_POSTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.blogPosts = action.blogPosts
      for (const key in action.blogPosts) {
        draft.schedules[key] = {
          fetching: false,
          doneFetching: false,
          fetchError: ''
        }
      }
      draft.meta = action.meta
      break
    case constants.FETCH_BLOG_POSTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_SCHEDULE_REQUEST:
      draft.schedules[action.id].fetching = true
      break
    case constants.FETCH_SCHEDULE_SUCCESS:
      draft.schedules[action.id].fetching = false
      draft.schedules[action.id].doneFetching = true
      draft.schedules[action.id].fetchError = ''
      for (const key in action.schedule) {
        draft.schedules[action.id][key] = action.schedule[key]
      }
      break
    case constants.FETCH_SCHEDULE_FAILURE:
      draft.schedules[action.id].fetching = false
      draft.schedules[action.id].doneFetching = true
      draft.schedules[action.id].fetchError = action.errorMessage
      break
    case constants.SAVE_SCHEDULE_REQUEST:
      draft.schedules[action.id].saving = true
      break
    case constants.SAVE_SCHEDULE_SUCCESS:
      draft.schedules[action.id].saving = false
      draft.schedules[action.id].saveError = ''
      draft.schedules[action.id].errors = {}
      for (const key in action.schedule) {
        draft.schedules[action.id][key] = action.schedule[key]
      }
      break
    case constants.SAVE_SCHEDULE_FAILURE:
      draft.schedules[action.id].saving = false
      draft.schedules[action.id].saveError = action.errorMessage
      draft.schedules[action.id].errors = action.errors
      break
    case constants.RESET_SCHEDULE_ERRORS:
      draft.schedules[action.id].errors = {}
      break
    case constants.DESTROY_SCHEDULE_REQUEST:
      draft.schedules[action.id].destroying = true
      break
    case constants.DESTROY_SCHEDULE_SUCCESS:
      draft.schedules[action.id].destroying = false
      draft.schedules[action.id].destroyError = ''
      delete draft.schedules[action.id].action
      delete draft.schedules[action.id].scheduledAt
      break
    case constants.DESTROY_SCHEDULE_FAILURE:
      draft.schedules[action.id].destroying = false
      draft.schedules[action.id].destroyError = action.errorMessage
      break
    case constants.PUBLISH_BLOG_POST_REQUEST:
    case constants.UNPUBLISH_BLOG_POST_REQUEST:
    case constants.ARCHIVE_BLOG_POST_REQUEST:
    case constants.UNARCHIVE_BLOG_POST_REQUEST:
    case constants.DESTROY_BLOG_POST_REQUEST:
      draft.blogPosts[action.id].transitioning = true
      break
    case constants.PUBLISH_BLOG_POST_SUCCESS:
    case constants.UNARCHIVE_BLOG_POST_SUCCESS:
      draft.blogPosts[action.id].state = 'published'
      draft.blogPosts[action.id].transitioning = false
      draft.blogPosts[action.id].transitionError = ''
      break
    case constants.UNPUBLISH_BLOG_POST_SUCCESS:
      draft.blogPosts[action.id].state = 'draft'
      draft.blogPosts[action.id].transitioning = false
      draft.blogPosts[action.id].transitionError = ''
      break
    case constants.ARCHIVE_BLOG_POST_SUCCESS:
      draft.blogPosts[action.id].state = 'archived'
      draft.blogPosts[action.id].archivalReasonMessage = action.archivalReasonMessage
      draft.blogPosts[action.id].transitioning = false
      draft.blogPosts[action.id].transitionError = ''
      break
    case constants.DESTROY_BLOG_POST_SUCCESS:
      delete draft.blogPosts[action.id]
      delete draft.schedules[action.id]
      break
    case constants.PUBLISH_BLOG_POST_FAILURE:
    case constants.UNPUBLISH_BLOG_POST_FAILURE:
    case constants.ARCHIVE_BLOG_POST_FAILURE:
    case constants.UNARCHIVE_BLOG_POST_FAILURE:
    case constants.DESTROY_BLOG_POST_FAILURE:
      draft.blogPosts[action.id].transitioning = false
      draft.blogPosts[action.id].transitionError = action.errorMessage
      break
    case constants.REORDER_BLOG_POSTS:
      for (const key in action.updates) {
        draft.blogPosts[key].sequence = action.updates[key]
      }
      break
    case constants.RESET_BLOG_POSTS:
      return initialState
  }
}, initialState)
