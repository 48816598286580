import produce from 'immer'
import findIndex from 'lodash/findIndex'
import constants from 'constants/survey-page-questions'

const initialState = {
  pages: [],
  questions: [],
  registrationQuestions: [],
  deletedQuestions: [],
  openQuestions: [],
  includedQuestions: [],
  includedSufQuestions: [],
  metadata: false,
  includeLogo: {
    title: true,
    cover: true,
    header: true,
    conclusion: true
  },
  coverNote: '',
  conclusion: '',
  report: {
    header: '',
    footer: ''
  },
  logoUrl: '',
  saving: false,
  saveError: '',
  downloading: false,
  downloadError: '',
  showDownloadPopup: false,
  showErrorPopup: false
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SURVEY_PAGES_REQUEST:
      draft.saving = true
      break
    case constants.FETCH_SURVEY_PAGES_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.pages = action.pages
      break
    case constants.FETCH_SURVEY_PAGES_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.FETCH_SURVEY_PAGE_QUESTIONS_REQUEST:
      draft.saving = true
      break
    case constants.FETCH_SURVEY_PAGE_QUESTIONS_SUCCESS:
      action.questions.forEach(question => {
        question.sectionId = question.section.id
        if (question.deleted) {
          draft.deletedQuestions.push(question.id)
          question.checked = false
        } else if (question.type === 'SingleLineQuestion' || question.type === 'EssayQuestion' || question.type === 'EmailQuestion' || question.type === 'NumberQuestion') {
          draft.openQuestions.push(question.id)
          question.checked = false
        } else {
          draft.includedQuestions.push(question.id)
          question.checked = true
        }
        draft.questions.push(question)
      })
      draft.saving = false
      draft.saveError = ''
      break
    case constants.FETCH_SURVEY_PAGE_QUESTIONS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.FETCH_REGISTRATION_QUESTIONS_REQUEST:
      draft.saving = true
      break
    case constants.FETCH_REGISTRATION_QUESTIONS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      action.questions.forEach(question => {
        if (!question.type.includes('SignupForm')) {
          question.checked = true
          draft.registrationQuestions.push(question)
          draft.includedSufQuestions.push(question.id)
        }
      })
      break
    case constants.FETCH_REGISTRATION_QUESTIONS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.UPDATE_CHECKED_REGISTRATION_QUESTIONS:
      draft.registrationQuestions[findIndex(draft.registrationQuestions, question => question.id === action.key)].checked = action.value
      if (action.value) draft.includedSufQuestions.push(action.key)
      else draft.includedSufQuestions.splice(draft.includedSufQuestions.indexOf(action.key), 1)
      break
    case constants.UPDATE_ADD_REMOVE_REGISTRATION_QUESTIONS:
      if (action.key) {
        draft.registrationQuestions.forEach(question => {
          draft.includedSufQuestions.push(question.id)
          question.checked = true
        })
      } else {
        draft.registrationQuestions.filter(item => {
          item.checked = false
        })
        draft.includedSufQuestions = []
      }
      break
    case constants.UPDATE_CHECKED_SURVEY_PAGE_QUESTIONS:
      draft.questions[findIndex(draft.questions, question => question.id === action.key)].checked = action.value
      if (action.value) draft.includedQuestions.push(action.key)
      else draft.includedQuestions.splice(draft.includedQuestions.indexOf(action.key), 1)
      break
    case constants.UPDATE_ADD_REMOVE_DELETED_QUESTIONS:
      if (action.key) {
        draft.includedQuestions = [...draft.includedQuestions, ...draft.deletedQuestions]
        draft.deletedQuestions.filter(item => {
          draft.questions[findIndex(draft.questions, question => question.id === item)].checked = true
        })
      } else {
        draft.deletedQuestions.filter(item => {
          draft.questions[findIndex(draft.questions, question => question.id === item)].checked = false
          return draft.includedQuestions.splice(draft.includedQuestions.indexOf(item), 1)
        })
      }
      break
    case constants.UPDATE_ADD_REMOVE_OPEN_QUESTIONS:
      if (action.key) {
        draft.includedQuestions = [...draft.includedQuestions, ...draft.openQuestions]
        draft.openQuestions.filter(item => {
          draft.questions[findIndex(draft.questions, question => question.id === item)].checked = true
        })
      } else {
        draft.openQuestions.filter(item => {
          draft.questions[findIndex(draft.questions, question => question.id === item)].checked = false
          return draft.includedQuestions.splice(draft.includedQuestions.indexOf(item), 1)
        })
      }
      break
    case constants.UPDATE_ADD_REMOVE_SCREEN_NAME:
      draft.metadata = action.key
      break
    case constants.SHOW_DOWNLOAD_POPUP:
      draft.showDownloadPopup = !draft.showDownloadPopup
      draft.downloading = false
      break
    case constants.SHOW_ERROR_ALERT_POPUP:
      draft.showErrorPopup = !draft.showErrorPopup
      draft.downloading = false
      break
    case constants.ENABLE_DISABLE_PAGE_LAYOUT_LOGO:
      draft.includeLogo[action.key] = action.value
      break
    case constants.UPDATE_LOGO_URL:
      draft.logoUrl = action.value
      break
    case constants.UPDATE_COVER_NOTE:
      draft.coverNote = action.value
      break
    case constants.UPDATE_REPORT_HEADER_FOOTER:
      draft.report[action.key] = action.value
      break
    case constants.UPDATE_CONCLUSION:
      draft.conclusion = action.value
      break
    case constants.FETCH_DOWNLOAD_REPORT_REQUEST:
      draft.downloading = true
      draft.showDownloadPopup = false
      draft.showErrorPopup = false
      break
    case constants.FETCH_DOWNLOAD_REPORT_SUCCESS:
      draft.downloading = false
      draft.downloadError = ''
      draft.showDownloadPopup = false
      draft.showErrorPopup = false
      break
    case constants.FETCH_DOWNLOAD_REPORT_FAILURE:
      draft.downloading = false
      draft.downloadError = action.errorMessage
      draft.showDownloadPopup = false
      draft.showErrorPopup = false
      break
    case constants.RESET_SURVEY_PAGE_QUESTIONS:
      return initialState
  }
}, initialState)
