import produce from 'immer'
import constants from 'constants/pinned-guest-book'

const initialState = {
  guestBook: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_GUEST_BOOK_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_GUEST_BOOK_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.guestBook = action.guestBook
      break
    case constants.FETCH_GUEST_BOOK_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_GUEST_BOOK:
      return initialState
    case constants.PUBLISH_GUEST_BOOK_REQUEST:
    case constants.UNPUBLISH_GUEST_BOOK_REQUEST:
    case constants.ARCHIVE_GUEST_BOOK_REQUEST:
    case constants.UNARCHIVE_GUEST_BOOK_REQUEST:
    case constants.DESTROY_GUEST_BOOK_REQUEST:
      draft.guestBook.transitioning = true
      break
    case constants.PUBLISH_GUEST_BOOK_SUCCESS:
    case constants.UNARCHIVE_GUEST_BOOK_SUCCESS:
      draft.guestBook.state = 'published'
      draft.guestBook.transitioning = false
      draft.guestBook.transitionError = ''
      break
    case constants.UNPUBLISH_GUEST_BOOK_SUCCESS:
      draft.guestBook.state = 'draft'
      draft.guestBook.transitioning = false
      draft.guestBook.transitionError = ''
      break
    case constants.ARCHIVE_GUEST_BOOK_SUCCESS:
      draft.guestBook.state = 'archived'
      draft.guestBook.archivalReasonMessage = action.archivalReasonMessage
      draft.guestBook.transitioning = false
      draft.guestBook.transitionError = ''
      break
    case constants.DESTROY_GUEST_BOOK_SUCCESS:
      draft.guestBook = {}
      break
    case constants.PUBLISH_GUEST_BOOK_FAILURE:
    case constants.UNPUBLISH_GUEST_BOOK_FAILURE:
    case constants.ARCHIVE_GUEST_BOOK_FAILURE:
    case constants.UNARCHIVE_GUEST_BOOK_FAILURE:
    case constants.DESTROY_GUEST_BOOK_FAILURE:
      draft.guestBook.transitioning = false
      draft.guestBook.transitionError = action.errorMessage
      break
  }
}, initialState)
