export default {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  SET_ADAPTER: 'SET_USER_ADAPTER',
  RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
  VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',
  FORGOT_USER_PASSWORD_REQUEST: 'FORGOT_USER_PASSWORD_REQUEST',
  FORGOT_USER_PASSWORD_SUCCESS: 'FORGOT_USER_PASSWORD_SUCCESS',
  FORGOT_USER_PASSWORD_FAILURE: 'FORGOT_USER_PASSWORD_FAILURE',
  RESEND_CONFRIM_EMAIL_REQUEST: 'RESEND_CONFRIM_EMAIL_REQUEST',
  RESEND_CONFRIM_EMAIL_SUCCESS: 'RESEND_CONFRIM_EMAIL_SUCCESS',
  RESEND_CONFRIM_EMAIL_FAILURE: 'RESEND_CONFRIM_EMAIL_FAILURE',
  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE'
}
