import produce from 'immer'
import constants from 'constants/theme'

const initialState = {
  theme: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_THEME_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_THEME_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.theme = action.theme
      break
    case constants.FETCH_THEME_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_THEME:
      draft.theme[action.key] = action.value
      break
    case constants.SAVE_THEME_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_THEME_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_THEME_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
