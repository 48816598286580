export default {
  FETCH_TEAM_MEMBERS_REQUEST: 'FETCH_TEAM_MEMBERS_REQUEST',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_MEMBERS_FAILURE: 'FETCH_TEAM_MEMBERS_FAILURE',
  CREATE_TEAM_MEMBERS_REQUEST: 'CREATE_TEAM_MEMBERS_REQUEST',
  CREATE_TEAM_MEMBERS_SUCCESS: 'CREATE_TEAM_MEMBERS_SUCCESS',
  CREATE_TEAM_MEMBERS_FAILURE: 'CREATE_TEAM_MEMBERS_FAILURE',
  SAVE_TEAM_MEMBER_REQUEST: 'SAVE_TEAM_MEMBER_REQUEST',
  SAVE_TEAM_MEMBER_SUCCESS: 'SAVE_TEAM_MEMBER_SUCCESS',
  SAVE_TEAM_MEMBER_FAILURE: 'SAVE_TEAM_MEMBER_FAILURE',
  DELETE_TEAM_MEMBERS_REQUEST: 'DELETE_TEAM_MEMBERS_REQUEST',
  DELETE_TEAM_MEMBERS_SUCCESS: 'DELETE_TEAM_MEMBERS_SUCCESS',
  DELETE_TEAM_MEMBERS_FAILURE: 'DELETE_TEAM_MEMBERS_FAILURE',
  CLONE_TEAM_MEMBERS_REQUEST: 'CLONE_TEAM_MEMBERS_REQUEST',
  CLONE_TEAM_MEMBERS_SUCCESS: 'CLONE_TEAM_MEMBERS_SUCCESS',
  CLONE_TEAM_MEMBERS_FAILURE: 'CLONE_TEAM_MEMBERS_FAILURE',
  SET_ACTIVE_TEAM_MEMBER: 'SET_ACTIVE_TEAM_MEMBER',
  UNSET_ACTIVE_TEAM_MEMBER: 'UNSET_ACTIVE_TEAM_MEMBER',
  RESET_CREATE_ERRORS: 'RESET_CREATE_TEAM_MEMBERS_ERRORS',
  REORDER_TEAM_MEMBERS: 'REORDER_TEAM_MEMBERS',
  RESET_TEAM_MEMBERS: 'RESET_TEAM_MEMBERS',
  RESET_TEAM_MEMBER_ERRORS: 'RESET_TEAM_MEMBER_ERRORS'
}
