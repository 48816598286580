export default {
  FETCH_ADMINISTRATORS_REQUEST: 'FETCH_ADMINISTRATORS_REQUEST',
  FETCH_ADMINISTRATORS_SUCCESS: 'FETCH_ADMINISTRATORS_SUCCESS',
  FETCH_ADMINISTRATORS_FAILURE: 'FETCH_ADMINISTRATORS_FAILURE',
  BLOCK_ADMINISTRATOR_REQUEST: 'BLOCK_ADMINISTRATOR_REQUEST',
  BLOCK_ADMINISTRATOR_SUCCESS: 'BLOCK_ADMINISTRATOR_SUCCESS',
  BLOCK_ADMINISTRATOR_FAILURE: 'BLOCK_ADMINISTRATOR_FAILURE',
  UPDATE_TO_PROJECT_ADMIN_ROLE_REQUEST: 'UPDATE_TO_PROJECT_ADMIN_ROLE_REQUEST',
  UPDATE_TO_PROJECT_ADMIN_ROLE_SUCCESS: 'UPDATE_TO_PROJECT_ADMIN_ROLE_SUCCESS',
  UPDATE_TO_PROJECT_ADMIN_ROLE_FAILURE: 'UPDATE_TO_PROJECT_ADMIN_ROLE_FAILURE',
  UPDATE_TO_SITE_ADMIN_ROLE_REQUEST: 'UPDATE_TO_SITE_ADMIN_ROLE_REQUEST',
  UPDATE_TO_SITE_ADMIN_ROLE_SUCCESS: 'UPDATE_TO_SITE_ADMIN_ROLE_SUCCESS',
  UPDATE_TO_SITE_ADMIN_ROLE_FAILURE: 'UPDATE_TO_SITE_ADMIN_ROLE_FAILURE',
  UPDATE_TO_HUB_ADMIN_ROLE_REQUEST: 'UPDATE_TO_HUB_ADMIN_ROLE_REQUEST',
  UPDATE_TO_HUB_ADMIN_ROLE_SUCCESS: 'UPDATE_TO_HUB_ADMIN_ROLE_SUCCESS',
  UPDATE_TO_HUB_ADMIN_ROLE_FAILURE: 'UPDATE_TO_HUB_ADMIN_ROLE_FAILURE',
  UNBLOCK_ADMINISTRATOR_REQUEST: 'UNBLOCK_ADMINISTRATOR_REQUEST',
  UNBLOCK_ADMINISTRATOR_SUCCESS: 'UNBLOCK_ADMINISTRATOR_SUCCESS',
  UNBLOCK_ADMINISTRATOR_FAILURE: 'UNBLOCK_ADMINISTRATOR_FAILURE',
  RESET_ADMINISTRATORS: 'RESET_ADMINISTRATORS',
  UPDATE_ADMINISTRATORS_QUERIES: 'UPDATE_ADMINISTRATORS_QUERIES',
  UPDATE_ADMINISTRATORS_FILTERS: 'UPDATE_ADMINISTRATORS_FILTERS',
  FETCH_HUB_ADMINISTRATORS_REQUEST: 'FETCH_HUB_ADMINISTRATORS_REQUEST',
  FETCH_HUB_ADMINISTRATORS_SUCCESS: 'FETCH_HUB_ADMINISTRATORS_SUCCESS',
  FETCH_HUB_ADMINISTRATORS_FAILURE: 'FETCH_HUB_ADMINISTRATORS_FAILURE',
  UPDATE_TO_PARTICIPANT_ROLE_REQUEST: 'UPDATE_TO_PARTICIPANT_ROLE_REQUEST',
  UPDATE_TO_PARTICIPANT_ROLE_SUCCESS: 'UPDATE_TO_PARTICIPANT_ROLE_SUCCESS',
  UPDATE_TO_PARTICIPANT_ROLE_FAILURE: 'UPDATE_TO_PARTICIPANT_ROLE_FAILURE'
}
