import produce from 'immer'
import constants from 'constants/register-banner'

const initialState = {
  banner: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BANNER_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_BANNER_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.banner = action.banner
      break
    case constants.FETCH_BANNER_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SAVE_BANNER_REQUEST:
    case constants.CREATE_BANNER_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_BANNER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.CREATE_BANNER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.banner = action.banner
      break
    case constants.SAVE_BANNER_FAILURE:
    case constants.CREATE_BANNER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_BANNER:
      return initialState
  }
}, initialState)
