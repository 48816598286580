export default {
  FETCH_STATIC_PAGES_REQUEST: 'FETCH_STATIC_PAGES_REQUEST',
  FETCH_STATIC_PAGES_SUCCESS: 'FETCH_STATIC_PAGES_SUCCESS',
  FETCH_STATIC_PAGES_FAILURE: 'FETCH_STATIC_PAGES_FAILURE',
  PUBLISH_STATIC_PAGE_REQUEST: 'PUBLISH_STATIC_PAGE_REQUEST',
  PUBLISH_STATIC_PAGE_SUCCESS: 'PUBLISH_STATIC_PAGE_SUCCESS',
  PUBLISH_STATIC_PAGE_FAILURE: 'PUBLISH_STATIC_PAGE_FAILURE',
  UNPUBLISH_STATIC_PAGE_REQUEST: 'UNPUBLISH_STATIC_PAGE_REQUEST',
  UNPUBLISH_STATIC_PAGE_SUCCESS: 'UNPUBLISH_STATIC_PAGE_SUCCESS',
  UNPUBLISH_STATIC_PAGE_FAILURE: 'UNPUBLISH_STATIC_PAGE_FAILURE',
  DELETE_STATIC_PAGE_REQUEST: 'DELETE_STATIC_PAGE_REQUEST',
  DELETE_STATIC_PAGE_SUCCESS: 'DELETE_STATIC_PAGE_SUCCESS',
  DELETE_STATIC_PAGE_FAILURE: 'DELETE_STATIC_PAGE_FAILURE',
  RESET_STATIC_PAGES: 'RESET_STATIC_PAGES',
  UPDATE_STATIC_PAGES_QUERIES: 'UPDATE_STATIC_PAGES_QUERIES',
  UPDATE_STATIC_PAGES_FILTERS: 'UPDATE_STATIC_PAGES_FILTERS'
}
