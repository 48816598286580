import produce from 'immer'
import constants from '../constants/project-admin-authorized-projects'

const initialState = {
  projects: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.projects = action.projects
      break
    case constants.FETCH_PROJECTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.AUTHORIZE_PROJECT_REQUEST:
    case constants.UNAUTHORIZE_PROJECT_REQUEST:
      draft.saving = true
      break
    case constants.AUTHORIZE_PROJECT_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.projects[action.id] = { id: action.id }
      break
    case constants.UNAUTHORIZE_PROJECT_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      delete draft.projects[action.id]
      break
    case constants.AUTHORIZE_PROJECT_FAILURE:
    case constants.UNAUTHORIZE_PROJECT_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.RESET_PROJECTS:
      return initialState
  }
}, initialState)
