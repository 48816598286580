import produce from 'immer'
import constants from '../constants/static-page'

const initialState = {
  page: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  savingPermalink: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STATIC_PAGE_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_STATIC_PAGE_SUCCESS:
      draft.page = action.page
      draft.permalink = action.page.permalink
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''

      break

    case constants.FETCH_STATIC_PAGE_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = false

      break

    case constants.UPDATE_STATIC_PAGE:
      draft.page[action.key] = action.value
      draft[action.key] = action.value
      break

    case constants.SAVE_STATIC_PAGE_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_STATIC_PAGE_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}

      break

    case constants.SAVE_STATIC_PAGE_FAILURE:
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      draft.saving = false

      break

    case constants.PERMALINK_REQUEST:
      draft.savingPermalink = true

      break

    case constants.PERMALINK_SUCCESS:
      draft.savingPermalink = false
      draft.permalink = action.permalink
      draft.page.permalink = action.permalink

      break

    case constants.PERMALINK_FAILURE:
      draft.savingPermalink = false

      break

    case constants.SET_ACTIVE_STATIC_PAGE:
      draft.page = { ...action.page }
      Object.keys(action.page).map(key => {
        draft[key] = action.page[key]
      })
      break

    case constants.RESET_STATIC_PAGE:
      return initialState
  }
}, initialState)
