import withReduxStore from 'hocs/with-redux-store'
import { enableES5 } from 'immer'
import administrator from 'reducers/administrator'
import administratorInvitations from 'reducers/administrator-invitations'
import administrators from 'reducers/administrators'
import auditTrails from 'reducers/audit-trails'
import blogPost from 'reducers/blog-post'
import blogPostComments from 'reducers/blog-post-comments'
import blogPostEmail from 'reducers/blog-post-email'
import blogPostEmails from 'reducers/blog-post-emails'
import blogPosts from 'reducers/blog-posts'
import brainstormer from 'reducers/brainstormer'
import brainstormers from 'reducers/brainstormers'
import confirmAdministrator from 'reducers/confirm-administrator'
import dashboard from 'reducers/dashboard'
import dashboards from 'reducers/dashboards'
import dedicatedNumber from 'reducers/dedicated-number'
import dedicatedNumbers from 'reducers/dedicated-numbers'
import documents from 'reducers/documents'
import emailAddresses from 'reducers/email-addresses'
import emailTemplates from 'reducers/email-templates'
import faqs from 'reducers/faqs'
import features from 'reducers/features'
import widgetFolders from 'reducers/folders'
import adminFooterLinks from 'reducers/footer-links'
import forum from 'reducers/forum'
import forumEmailTemplate from 'reducers/forum-email-template'
import forumEmailTemplates from 'reducers/forum-email-templates'
import forums from 'reducers/forums'
import generatePermalinkStaticPage from 'reducers/generate-permalink-static-page'
import googleTranslateIntegration from 'reducers/google-translate-integration'
import guestBook from 'reducers/guest-book'
import adminHeaderLinks from 'reducers/header-links'
import homePageRevision from 'reducers/home-page-revision'
import homePageRevisions from 'reducers/home-page-revisions'
import hub from 'reducers/hub'
import hubAdminAuthorizedHubs from 'reducers/hub-admin-authorized-hubs'
import hubPageRevision from 'reducers/hub-page-revision'
import hubPageRevisions from 'reducers/hub-page-revisions'
import hubSegments from 'reducers/hub-segments'
import hubs from 'reducers/hubs'
import keyDates from 'reducers/key-dates'
import keyLinks from 'reducers/key-links'
import layout from 'reducers/layout'
import liquidTemplates from 'reducers/liquid-templates'
import mailchimp from 'reducers/mailchimp'
import mailchimpSegments from 'reducers/mailchimp-segments'
import map from 'reducers/map'
import mapLayers from 'reducers/map-layers'
import maps from 'reducers/maps'
import markerCategories from 'reducers/marker-categories'
import marketplace from 'reducers/marketplace'
import marketplaces from 'reducers/marketplaces'
import messageTexts from 'reducers/message-texts'
import adminNavigations from 'reducers/navigations'
import newsletter from 'reducers/newsletter'
import newsletterStats from 'reducers/newsletter-stats'
import newsletters from 'reducers/newsletters'
import notifications from 'reducers/notifications'
import participantSegments from 'reducers/participant-segments'
import photos from 'reducers/photos'
import pinnedGuestBook from 'reducers/pinned-guest-book'
import pinnedItems from 'reducers/pinned-items'
import pinnedQanda from 'reducers/pinned-qanda'
import pinnedStoryCollection from 'reducers/pinned-story-collection'
import project from 'reducers/project'
import projectAdminAuthorizedProjects from 'reducers/project-admin-authorized-projects'
import projectApproval from 'reducers/project-approval'
import projectFinder from 'reducers/project-finder'
import projectFinders from 'reducers/project-finders'
import projectGovDeliveryIntegration from 'reducers/project-gov-delivery-integration'
import projectSchedule from 'reducers/project-schedule'
import projectSegments from 'reducers/project-segments'
import projectStats from 'reducers/project-stats'
import projectTags from 'reducers/project-tags'
import projectUsers from 'reducers/project-users'
import projects from 'reducers/projects'
import qanda from 'reducers/qanda'
import qandaEmailTemplate from 'reducers/qanda-email-template'
import qandaEmailTemplates from 'reducers/qanda-email-templates'
import qandaQuestions from 'reducers/qanda-questions'
import quickPoll from 'reducers/quick-poll'
import quickPollOptions from 'reducers/quick-poll-options'
import quickPolls from 'reducers/quick-polls'
import recipients from 'reducers/recipients'
import registerBanner from 'reducers/register-banner'
import relatedProjects from 'reducers/related-projects'
import selectedRecipients from 'reducers/selected-recipients'
import siteSettings from 'reducers/settings'
import signupFormQuestions from 'reducers/signup-form-questions'
import signupResponses from 'reducers/signup-responses'
import currentSite from 'reducers/site'
import siteFeatures from 'reducers/site-features'
import smsIntegration from 'reducers/sms-integration'
import smsIntegrations from 'reducers/sms-integrations'
import stages from 'reducers/stages'
import staticPage from 'reducers/static-page'
import staticPages from 'reducers/static-pages'
import stories from 'reducers/stories'
import storyCollection from 'reducers/story-collection'
import storyEmailTemplate from 'reducers/story-email-template'
import storyEmailTemplates from 'reducers/story-email-templates'
import submissions from 'reducers/submissions'
import subscription from 'reducers/subscription'
import suburbs from 'reducers/suburbs'
import survey from 'reducers/survey'
import surveyEmailTemplate from 'reducers/survey-email-template'
import surveyEmailTemplates from 'reducers/survey-email-templates'
import surveyPageQuestions from 'reducers/survey-page-questions'
import surveyResponses from 'reducers/survey-responses'
import surveys from 'reducers/surveys'
import tagManager from 'reducers/tag-manager'
import tags from 'reducers/tags'
import teamMembers from 'reducers/team-members'
import textAnalysisResponses from 'reducers/text-analysis'
import textAnalysisFiltersResponses from 'reducers/text-analysis-filters'
import theme from 'reducers/theme'
import tools from 'reducers/tools'
import currentUser from 'reducers/user'
import userFeatures from 'reducers/user-feature'
import usersBySegments from 'reducers/users-by-segments'
import videos from 'reducers/videos'
import widgetQuickPolls from 'reducers/widget-quick-polls'
import widgets from 'reducers/widgets'
import projectClone from 'reducers/clone-project'
import token from 'reducers/token'
import materialSidebar from 'reducers/material-sidebar'
import opencitiesIntegration from 'reducers/opencities-integration'
import librariesDetails from 'reducers/libraries'
import places from 'reducers/places'
import { combineReducers } from 'redux'

// Required for IE11
enableES5()

const reducer = combineReducers({
  materialSidebar,
  tools,
  videos,
  photos,
  mailchimpSegments,
  mailchimp,
  projects,
  projectStats,
  staticPages,
  staticPage,
  administratorInvitations,
  currentUser,
  currentSite,
  siteSettings,
  emailAddresses,
  administrator,
  administrators,
  project,
  projectTags,
  hub,
  hubs,
  blogPost,
  emailTemplates,
  messageTexts,
  features,
  pinnedItems,
  blogPosts,
  brainstormer,
  brainstormers,
  faqs,
  forum,
  forums,
  guestBook,
  qanda,
  qandaQuestions,
  pinnedGuestBook,
  pinnedQanda,
  storyCollection,
  stories,
  pinnedStoryCollection,
  quickPoll,
  quickPollOptions,
  quickPolls,
  stages,
  survey,
  surveys,
  signupFormQuestions,
  auditTrails,
  newsletter,
  newsletters,
  newsletterStats,
  notifications,
  participantSegments,
  marketplace,
  marketplaces,
  recipients,
  selectedRecipients,
  adminHeaderLinks,
  adminFooterLinks,
  adminNavigations,
  tags,
  suburbs,
  keyLinks,
  documents,
  widgets,
  keyDates,
  registerBanner,
  usersBySegments,
  projectSegments,
  projectAdminAuthorizedProjects,
  hubAdminAuthorizedHubs,
  projectSchedule,
  places,
  map,
  maps,
  teamMembers,
  markerCategories,
  mapLayers,
  relatedProjects,
  storyEmailTemplate,
  storyEmailTemplates,
  forumEmailTemplate,
  forumEmailTemplates,
  surveyEmailTemplate,
  surveyEmailTemplates,
  qandaEmailTemplate,
  qandaEmailTemplates,
  widgetFolders,
  projectGovDeliveryIntegration,
  widgetQuickPolls,
  homePageRevisions,
  homePageRevision,
  theme,
  generatePermalinkStaticPage,
  projectApproval,
  dashboard,
  dashboards,
  layout,
  surveyPageQuestions,
  liquidTemplates,
  submissions,
  googleTranslateIntegration,
  surveyResponses,
  signupResponses,
  hubPageRevisions,
  hubPageRevision,
  projectFinders,
  projectFinder,
  subscription,
  hubSegments,
  smsIntegrations,
  smsIntegration,
  dedicatedNumbers,
  dedicatedNumber,
  textAnalysisResponses,
  blogPostEmail,
  blogPostEmails,
  textAnalysisFiltersResponses,
  siteFeatures,
  projectUsers,
  blogPostComments,
  userFeatures,
  confirmAdministrator,
  tagManager,
  projectClone,
  token,
  opencitiesIntegration,
  librariesDetails,
})

const initialState = reducer(undefined, {})

const withAdminStore = (App) => withReduxStore(App, reducer, initialState)

export default withAdminStore
