import produce from 'immer'
import constants from '../constants/email-addresses'

const initialState = {
  emails: {},
  ui: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_EMAILS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_EMAILS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.emails = action.emails
      for (const key in action.emails) {
        draft.ui[key] = {
          errors: {},
          saving: false,
          saveError: ''
        }
      }
      break
    case constants.FETCH_EMAILS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_EMAIL:
      draft.emails[action.id][action.key] = action.value
      break
    case constants.SAVE_EMAIL_REQUEST:
      draft.ui[action.id].saving = true
      break
    case constants.SAVE_EMAIL_SUCCESS:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = ''
      draft.ui[action.id].errors = {}
      break
    case constants.SAVE_EMAIL_FAILURE:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = action.errorMessage
      draft.ui[action.id].errors = action.errors
      break
    case constants.SAVE_EMAILS_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_EMAILS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.SAVE_EMAILS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
  }
}, initialState)
