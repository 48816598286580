export default {
  FETCH_EMAILS_REQUEST: 'FETCH_EMAIL_ADDRESSES_REQUEST',
  FETCH_EMAILS_SUCCESS: 'FETCH_EMAIL_ADDRESSES_SUCCESS',
  FETCH_EMAILS_FAILURE: 'FETCH_EMAIL_ADDRESSES_FAILURE',
  UPDATE_EMAIL: 'UPDATE_EMAIL_ADDRESS',
  SAVE_EMAILS_REQUEST: 'SAVE_EMAIL_ADDRESSES_REQUEST',
  SAVE_EMAILS_SUCCESS: 'SAVE_EMAIL_ADDRESSES_SUCCESS',
  SAVE_EMAILS_FAILURE: 'SAVE_EMAIL_ADDRESSES_FAILURE',
  SAVE_EMAIL_REQUEST: 'SAVE_EMAIL_ADDRESS_REQUEST',
  SAVE_EMAIL_SUCCESS: 'SAVE_EMAIL_ADDRESS_SUCCESS',
  SAVE_EMAIL_FAILURE: 'SAVE_EMAIL_ADDRESS_FAILURE'
}
