export default {
  FETCH_USER_ENABLE_FEATURE_REQUEST: 'FETCH_USER_ENABLE_FEATURE_REQUEST',
  FETCH_USER_ENABLE_FEATURE_SUCCESS: 'FETCH_USER_ENABLE_FEATURE_SUCCESS',
  FETCH_USER_ENABLE_FEATURE_FAILURE: 'FETCH_USER_ENABLE_FEATURE_FAILURE',
  USER_ENABLE_FEATURE_REQUEST: 'USER_ENABLE_FEATURE_REQUEST',
  USER_ENABLE_FEATURE_SUCCESS: 'USER_ENABLE_FEATURE_SUCCESS',
  USER_ENABLE_FEATURE_FAILURE: 'USER_ENABLE_FEATURE_FAILURE',
  USER_DISABLE_FEATURE_REQUEST: 'USER_DISABLE_FEATURE_REQUEST',
  USER_DISABLE_FEATURE_SUCCESS: 'USER_DISABLE_FEATURE_SUCCESS',
  USER_DISABLE_FEATURE_FAILURE: 'USER_DISABLE_FEATURE_FAILURE',
  TOGGLE_USER_FEATURE: 'TOGGLE_USER_FEATURE'
}
