import produce from 'immer'
import constants from 'constants/audit-trails'

const initialState = {
  auditTrails: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  errors: {},
  meta: {
    count: 0
  },
  showAdvancedFilter: false,
  showDateRangeFilter: false,
  filters: {
    trackable_type: '',
    project_id: '',
    search: '',
    front_end: '',
    sort: {
      created_at: 'desc'
    },
    date: {
      created_at: {
        start: '',
        finish: ''
      }
    },
    not: {
      event_type: ['pinned_item:created', 'pinned_item:updated', 'pinned_item:deleted', 'survey_document_download:created']
    }
  },
  query: {
    sort: 'created_at',
    order: 'desc',
    advancedFilterToggle: false,
    site_settings: false,
    page: 1,
    per_page: 25,
    include: ['project', 'owner', 'trackable'],
    fields: {
      'project': ['name', 'permalink'],
      'user': ['login', 'type'],
      'forum-topic': ['name', 'permalink'],
      'blog-post': ['name', 'permalink'],
      'survey-tool': ['name', 'permalink'],
      'map': ['name', 'permalink'],
      'quick-poll': ['name', 'permalink'],
      'guest-book': ['name', 'permalink'],
      'brainstormer': ['name', 'permalink'],
      'qanda': ['name', 'permalink'],
      'story-telling-tool': ['name', 'permalink']
    }
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_AUDIT_TRAILS_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_AUDIT_TRAILS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.auditTrails = action.auditTrails
      draft.meta.count = action.meta.count
      break

    case constants.FETCH_AUDIT_TRAILS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_AUDIT_TRAILS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break

    case constants.UPDATE_AUDIT_TRAILS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break

    case constants.UPDATE_ADVANCED_FILTER_VISIBILITY:
      draft.showAdvancedFilter = action.flag
      break

    case constants.UPDATE_DATE_RANGE_FILTER_VISIBILITY:
      draft.showDateRangeFilter = action.flag
      break

    case constants.RESET_AUDIT_TRAILS:
      return initialState
  }
}, initialState)
