import produce from 'immer'
import constants from 'constants/header-links'

const initialState = {
  headerLinks: [],
  headerLink: {
    title: '',
    region: 'header',
    linkableType: 'Project',
    linkableId: '',
    linkableUrl: '',
    parentId: '',
    disabledParentId: false
  },
  queries: {
    region: 'header'
  },
  filters: {
    search: 'header',
    sort: { sequence: 'asc' }
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  deleting: false,
  deleteError: '',
  dragging: false
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_HEADER_LINKS_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_HEADER_LINKS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.headerLinks = action.headerLinks
      break

    case constants.FETCH_HEADER_LINKS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_HEADER_LINKS_DRAGGING:
      draft.dragging = action.value
      break

    case constants.FETCH_HEADER_LINK_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_HEADER_LINK_SUCCESS:
      draft.headerLink = action.headerLink
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''

      break

    case constants.FETCH_HEADER_LINK_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = false

      break

    case constants.UPDATE_HEADER_LINK:
      draft.headerLink[action.key] = action.value

      break

    case constants.SAVE_HEADER_LINK_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_HEADER_LINK_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}

      break

    case constants.SAVE_HEADER_LINK_FAILURE:
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      draft.saving = false

      break

    case constants.DELETE_HEADER_LINK_REQUEST:
      draft.deleting = true
      draft.deleteError = ''

      break

    case constants.DELETE_HEADER_LINK_SUCCESS:
      draft.deleting = false
      draft.deleteError = ''

      break

    case constants.DELETE_HEADER_LINK_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage

      break

    case constants.RESET_HEADER_LINK:
      draft.headerLink = {
        title: '',
        region: 'header',
        linkableType: 'Project',
        linkableId: '',
        linkableUrl: '',
        parentId: '',
        disabledParentId: false
      }
      draft.errors = {}
      draft.saveError = ''
      draft.dragging = false
      break

    case constants.UPDATE_HEADER_LINK_ERROR:
      draft.errors[action.key] = action.value
      break
  }
}, initialState)
