import produce from 'immer'
import constants from 'constants/key-dates'

const initialState = {
  active: '',
  dates: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_KEY_DATES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_KEY_DATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.dates = action.dates
      break
    case constants.FETCH_KEY_DATES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.DELETE_KEY_DATE_REQUEST:
      draft.dates[action.id].deleting = true
      break
    case constants.DELETE_KEY_DATE_SUCCESS:
      delete draft.dates[action.id]
      break
    case constants.DELETE_KEY_DATE_FAILURE:
      draft.dates[action.id].deleting = false
      draft.dates[action.id].deleteError = action.errorMessage
      break
    case constants.SET_ACTIVE_KEY_DATE:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_KEY_DATE:
      draft.active = ''
      break
    case constants.REORDER_KEY_DATES:
      (() => {
        for (let key in action.updates) {
          draft.dates[key].position = action.updates[key]
        }
      })()
      break
    case constants.CREATE_KEY_DATE_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_KEY_DATE_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.dates[action.date.id] = action.date
      break
    case constants.CREATE_KEY_DATE_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_KEY_DATE_REQUEST:
      draft.dates[action.id].saving = true
      break
    case constants.SAVE_KEY_DATE_SUCCESS:
      draft.dates[action.id].saving = false
      draft.dates[action.id].saveError = ''
      draft.dates[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.dates[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_KEY_DATE_FAILURE:
      draft.dates[action.id].saving = false
      draft.dates[action.id].saveError = action.errorMessage
      draft.dates[action.id].errors = action.errors
      break
    case constants.RESET_KEY_DATE_ERRORS:
      draft.dates[action.id].saveError = ''
      draft.dates[action.id].errors = {}
      break
    case constants.CLONE_KEY_DATES_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_KEY_DATES_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.dates.forEach((date) => {
        draft.dates[date.id] = date
      })
      break
    case constants.CLONE_KEY_DATES_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.RESET_KEY_DATES:
      return initialState
  }
}, initialState)
