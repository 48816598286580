export default {
  FETCH_PROJECTS_REQUEST: 'FETCH_PROJECTS_REQUEST',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_FAILURE: 'FETCH_PROJECTS_FAILURE',
  FETCH_ALL_PROJECTS_REQUEST: 'FETCH_ALL_PROJECTS_REQUEST',
  FETCH_ALL_PROJECTS_SUCCESS: 'FETCH_ALL_PROJECTS_SUCCESS',
  FETCH_ALL_PROJECTS_FAILURE: 'FETCH_ALL_PROJECTS_FAILURE',
  UPDATE_PROJECTS_QUERY: 'UPDATE_PROJECTS_QUERY',
  UPDATE_PROJECTS_FILTERS: 'UPDATE_PROJECTS_FILTERS',
  FILTER_PROJECTS_BY_NAME: 'FILTER_PROJECTS_BY_NAME',
  TOGGLE_PROJECT: 'TOGGLE_PROJECT',
  TOGGLE_ALL_PROJECTS: 'TOGGLE_ALL_PROJECTS',
  RESET_PROJECTS_PAGE: 'RESET_PROJECTS_PAGE',
  FETCH_RECOMMENDED_PROJECTS_REQUEST: 'FETCH_RECOMMENDED_PROJECTS_REQUEST',
  FETCH_RECOMMENDED_PROJECTS_SUCCESS: 'FETCH_RECOMMENDED_PROJECTS_SUCCESS',
  FETCH_RECOMMENDED_PROJECTS_FAILURE: 'FETCH_RECOMMENDED_PROJECTS_FAILURE'
}
