export default {
  FETCH_SEGMENTS_REQUEST: 'FETCH_PARTICIPANT_SEGMENTS_REQUEST',
  FETCH_SEGMENTS_SUCCESS: 'FETCH_PARTICIPANT_SEGMENTS_SUCCESS',
  FETCH_SEGMENTS_FAILURE: 'FETCH_PARTICIPANT_SEGMENTS_FAILURE',
  TOGGLE_SEGMENT: 'TOGGLE_PARTICIPANT_SEGMENT',
  TOGGLE_ALL_SEGMENTS: 'TOGGLE_ALL_SEGMENTS',
  FILTER_BY_NAME: 'FILTER_BY_NAME',
  RESET_SEGMENTS: 'RESET_PARTICIPANT_SEGMENTS',
  SELECT_SEGMENTS: 'SELECT_PARTICIPANT_SEGMENTS'
}
