export default {
  FETCH_GUEST_BOOK_REQUEST: 'FETCH_GUEST_BOOK_REQUEST',
  FETCH_GUEST_BOOK_SUCCESS: 'FETCH_GUEST_BOOK_SUCCESS',
  FETCH_GUEST_BOOK_FAILURE: 'FETCH_GUEST_BOOK_FAILURE',
  UPDATE_GUEST_BOOK: 'UPDATE_GUEST_BOOK',
  SAVE_GUEST_BOOK_REQUEST: 'SAVE_GUEST_BOOK_REQUEST',
  SAVE_GUEST_BOOK_SUCCESS: 'SAVE_GUEST_BOOK_SUCCESS',
  SAVE_GUEST_BOOK_FAILURE: 'SAVE_GUEST_BOOK_FAILURE',
  RESET_GUEST_BOOK: 'RESET_GUEST_BOOK'
}
