import produce from 'immer'
import constants from '../constants/surveys'

const initialState = {
  surveys: {},
  schedules: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SURVEYS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SURVEYS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.surveys = action.surveys
      draft.schedules = action.schedules
      break
    case constants.FETCH_SURVEYS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SURVEY_FROM_LIST:
      draft.surveys[action.id][action.key] = action.value
      break
    case constants.SAVE_SCHEDULE_REQUEST:
      draft.schedules[action.id].saving = true
      break
    case constants.SAVE_SCHEDULE_SUCCESS:
      draft.schedules[action.id].saving = false
      draft.schedules[action.id].saveError = ''
      draft.schedules[action.id].errors = {}
      for (const key in action.schedule) {
        draft.schedules[action.id][key] = action.schedule[key]
      }
      break
    case constants.SAVE_SCHEDULE_FAILURE:
      draft.schedules[action.id].saving = false
      draft.schedules[action.id].saveError = action.errorMessage
      draft.schedules[action.id].errors = action.errors
      break
    case constants.RESET_SCHEDULE_ERRORS:
      draft.schedules[action.id].errors = {}
      break
    case constants.DESTROY_SCHEDULE_REQUEST:
      draft.schedules[action.id].destroying = true
      break
    case constants.DESTROY_SCHEDULE_SUCCESS:
      draft.schedules[action.id].destroying = false
      draft.schedules[action.id].destroyError = ''
      delete draft.schedules[action.id].action
      delete draft.schedules[action.id].scheduledAt
      break
    case constants.DESTROY_SCHEDULE_FAILURE:
      draft.schedules[action.id].destroying = false
      draft.schedules[action.id].destroyError = action.errorMessage
      break
    case constants.RESET_SURVEYS:
      return initialState
    case constants.REORDER_SURVEYS:
      for (const key in action.updates) {
        draft.surveys[key].sequence = action.updates[key]
      }
      break
    case constants.PUBLISH_SURVEY_REQUEST:
    case constants.UNPUBLISH_SURVEY_REQUEST:
    case constants.ARCHIVE_SURVEY_REQUEST:
    case constants.UNARCHIVE_SURVEY_REQUEST:
    case constants.DESTROY_SURVEY_REQUEST:
      draft.surveys[action.id].transitioning = true
      break
    case constants.PUBLISH_SURVEY_SUCCESS:
    case constants.UNARCHIVE_SURVEY_SUCCESS:
      draft.surveys[action.id].state = 'published'
      draft.surveys[action.id].transitioning = false
      draft.surveys[action.id].transitionError = ''
      break
    case constants.UNPUBLISH_SURVEY_SUCCESS:
      draft.surveys[action.id].state = 'draft'
      draft.surveys[action.id].transitioning = false
      draft.surveys[action.id].transitionError = ''
      break
    case constants.ARCHIVE_SURVEY_SUCCESS:
      draft.surveys[action.id].state = 'archived'
      draft.surveys[action.id].archivalReasonMessage = action.archivalReasonMessage
      draft.surveys[action.id].transitioning = false
      draft.surveys[action.id].transitionError = ''
      break
    case constants.DESTROY_SURVEY_SUCCESS:
      delete draft.surveys[action.id]
      delete draft.schedules[action.id]
      break
    case constants.PUBLISH_SURVEY_FAILURE:
    case constants.UNPUBLISH_SURVEY_FAILURE:
    case constants.ARCHIVE_SURVEY_FAILURE:
    case constants.UNARCHIVE_SURVEY_FAILURE:
    case constants.DESTROY_SURVEY_FAILURE:
      draft.surveys[action.id].transitioning = false
      draft.surveys[action.id].transitionError = action.errorMessage
      break
  }
}, initialState)
