import produce from 'immer';

import constants from '../constants/user';

const initialState = {
  user: {},
  adapter: {},
  status: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  errors: [],
};

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_USER_REQUEST:
      draft.fetching = true;
      draft.fetchError = '';
      break;
    case constants.FETCH_USER_SUCCESS:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = '';
      draft.user = action.user;
      break;
    case constants.FETCH_USER_FAILURE:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = action.errorMessage;
      draft.errors = action.errors;
      break;
    case constants.RESET_USER_PASSWORD_SUCCESS:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = '';
      draft.errors = [];
      break;
    case constants.VERIFY_USER_REQUEST:
      draft.fetching = true;
      draft.fetchError = '';
      break;
    case constants.VERIFY_USER_SUCCESS:
      draft.fetching = false;
      draft.status = action.user;
      draft.fetchError = action.message || '';
      break;
    case constants.VERIFY_USER_FAILURE:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = action.errorMessage;
      draft.errors = action.errors;
      break;
    case constants.FORGOT_USER_PASSWORD_REQUEST:
      draft.fetching = true;
      draft.fetchError = '';
      break;
    case constants.FORGOT_USER_PASSWORD_SUCCESS:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = '';
      break;
    case constants.FORGOT_USER_PASSWORD_FAILURE:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = action.errorMessage;
      break;
    case constants.RESEND_CONFRIM_EMAIL_REQUEST:
      draft.fetching = true;
      draft.fetchError = '';
      break;
    case constants.RESEND_CONFRIM_EMAIL_SUCCESS:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = '';
      draft.user = action.user;
      break;
    case constants.RESEND_CONFRIM_EMAIL_FAILURE:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = action.errorMessage;
      break;
    case constants.VERIFY_OTP_REQUEST:
      draft.fetching = true;
      draft.fetchError = '';
      break;
    case constants.VERIFY_OTP_SUCCESS:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = '';
      break;
    case constants.VERIFY_OTP_FAILURE:
      draft.fetching = false;
      draft.doneFetching = true;
      draft.fetchError = action.errorMessage;
      break;
    case constants.SET_ADAPTER:
      draft.adapter = action.adapter;
      break;
  }
}, initialState);
