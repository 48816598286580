export default {
  FETCH_PROJECT_FINDER_REQUEST: 'FETCH_PROJECT_FINDER_REQUEST',
  FETCH_PROJECT_FINDER_SUCCESS: 'FETCH_PROJECT_FINDER_SUCCESS',
  FETCH_PROJECT_FINDER_FAILURE: 'FETCH_PROJECT_FINDER_FAILURE',
  UPDATE_PROJECT_FINDER: 'UPDATE_PROJECT_FINDER',
  UPDATE_PROJECT_FINDER_LOCALES: 'UPDATE_PROJECT_FINDER_LOCALES',
  RESET_PROJECT_FINDER: 'RESET_PROJECT_FINDER',
  SAVE_PROJECT_FINDER_REQUEST: 'SAVE_PROJECT_FINDER_REQUEST',
  SAVE_PROJECT_FINDER_SUCCESS: 'SAVE_PROJECT_FINDER_SUCCESS',
  SAVE_PROJECT_FINDER_FAILURE: 'SAVE_PROJECT_FINDER_FAILURE',
  SET_PROJECT_FINDER: 'SET_PROJECT_FINDER',
  TOGGLE_REARRANGE_ORDER: 'TOGGLE_REARRANGE_ORDER'
}
