import produce from 'immer'
import constants from 'constants/marketplace'

const initialState = {
  marketplace: {},
  fetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_MARKETPLACE_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_MARKETPLACE_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.marketplace = action.marketplace
      break
    case constants.FETCH_MARKETPLACE_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
