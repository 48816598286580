import produce from 'immer'
import constants from 'constants/quick-poll-options'

const initialState = {
  options: {},
  ui: {},
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.UPDATE_OPTION:
      draft.options[action.id][action.key] = action.value
      break
    case constants.ADD_OPTION:
      draft.options[action.option.id] = action.option
      draft.ui[action.option.id] = {
        saving: false,
        saveError: '',
        errors: {},
        transitioning: false,
        transitionError: ''
      }
      break
    case constants.REMOVE_OPTION:
      delete draft.options[action.id]
      delete draft.ui[action.id]
      break
    case constants.SAVE_OPTION_REQUEST:
      draft.ui[action.id].saving = true
      break
    case constants.SAVE_OPTION_SUCCESS:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = ''
      draft.ui[action.id].errors = {}
      break
    case constants.SAVE_OPTION_FAILURE:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = action.errorMessage
      draft.ui[action.id].errors = action.errors
      break
    case constants.SAVE_OPTIONS_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_OPTIONS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.SAVE_OPTIONS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.SET_OPTIONS:
      draft.options = action.options
      for (const key in action.options) {
        draft.ui[key] = {
          saving: false,
          saveError: '',
          errors: {},
          transitioning: false,
          transitionError: ''
        }
      }
      break
    case constants.HIDE_OPTION_REQUEST:
    case constants.UNHIDE_OPTION_REQUEST:
    case constants.DESTROY_OPTION_REQUEST:
      draft.ui[action.id].transitioning = true
      break
    case constants.HIDE_OPTION_SUCCESS:
      draft.options[action.id].deleted = true
      draft.ui[action.id].transitioning = false
      draft.ui[action.id].transitionError = ''
      break
    case constants.UNHIDE_OPTION_SUCCESS:
      draft.options[action.id].deleted = false
      draft.ui[action.id].transitioning = false
      draft.ui[action.id].transitionError = ''
      break
    case constants.DESTROY_OPTION_SUCCESS:
      delete draft.options[action.id]
      delete draft.ui[action.id]
      break
    case constants.HIDE_OPTION_FAILURE:
    case constants.UNHIDE_OPTION_FAILURE:
    case constants.DESTROY_OPTION_FAILURE:
      draft.ui[action.id].transitioning = false
      draft.ui[action.id].transitionError = action.errorMessage
      break
    case constants.RESET_OPTIONS:
      return initialState
  }
}, initialState)
