import produce from 'immer'
import constants from 'constants/material-sidebar'

const initialState = {
  sidebar: {
    collapse: true
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.SET_MUI_SIDEBAR_VISIBILITY:
      draft.sidebar = action.sidebar
      break
  }
}, initialState)
