import produce from 'immer'
import constants from 'constants/home-page-revisions'

const initialState = {
  lastRevision: {},
  lastPublishedRevision: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  queries: {
    page: 1,
    per_page: 1
  },
  filters: {
    sort: {
      id: 'desc'
    }
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_LAST_HOME_PAGE_REVISIONS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_LAST_HOME_PAGE_REVISIONS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.lastRevision = action.revision

      break

    case constants.FETCH_LAST_HOME_PAGE_REVISIONS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.lastPublishedRevision = action.revision

      break

    case constants.FETCH_LAST_PUBLISHED_HOME_PAGE_REVISIONS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break
  }
}, initialState)
