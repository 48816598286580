import produce from 'immer'
import constants from 'constants/project-schedule'

const initialState = {
  schedule: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  destroying: false,
  destroyError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SCHEDULE_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SCHEDULE_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.schedule = action.schedule
      break
    case constants.FETCH_SCHEDULE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SAVE_SCHEDULE_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_SCHEDULE_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.schedule = action.schedule
      draft.errors = {}
      break
    case constants.SAVE_SCHEDULE_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.DESTROY_SCHEDULE_REQUEST:
      draft.destroying = true
      break
    case constants.DESTROY_SCHEDULE_SUCCESS:
      draft.destroying = false
      draft.destroyError = ''
      draft.schedule = {}
      break
    case constants.DESTROY_SCHEDULE_FAILURE:
      draft.destroying = false
      draft.destroyError = action.errorMessage
      break
    case constants.RESET_ERRORS:
      draft.errors = {}
      break
    case constants.RESET_SCHEDULE:
      return initialState
  }
}, initialState)
