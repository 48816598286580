import produce from 'immer'
import constants from 'constants/key-links'

const initialState = {
  active: '',
  links: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_LINKS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_LINKS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.links = action.links
      break
    case constants.FETCH_LINKS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.CREATE_LINK_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_LINK_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.links[action.keyLink.id] = action.keyLink
      break
    case constants.CREATE_LINK_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.RESET_LINK_ERRORS:
      draft.links[action.id].saveError = ''
      draft.links[action.id].errors = {}
      break
    case constants.DELETE_LINK_REQUEST:
      draft.links[action.id].deleting = true
      break
    case constants.DELETE_LINK_SUCCESS:
      delete draft.links[action.id]
      break
    case constants.DELETE_LINK_FAILURE:
      draft.links[action.id].deleting = false
      draft.links[action.id].deleteError = action.errorMessage
      break
    case constants.SAVE_LINK_REQUEST:
      draft.links[action.id].saving = true
      break
    case constants.SAVE_LINK_SUCCESS:
      draft.links[action.id].saving = false
      draft.links[action.id].saveError = ''
      draft.links[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.links[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_LINK_FAILURE:
      draft.links[action.id].saving = false
      draft.links[action.id].saveError = action.errorMessage
      draft.links[action.id].errors = action.errors
      break
    case constants.SET_ACTIVE_LINK:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_LINK:
      draft.active = ''
      break
    case constants.REORDER_LINKS:
      (() => {
        for (let key in action.updates) {
          draft.links[key].position = action.updates[key]
        }
      })()
      break
    case constants.CLONE_LINKS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_LINKS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.links.forEach((link) => {
        draft.links[link.id] = link
      })
      break
    case constants.CLONE_LINKS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.RESET_LINKS:
      return initialState
  }
}, initialState)
