import produce from 'immer'
import constants from 'constants/faqs'

const initialState = {
  drawer: 'list',
  active: '',
  faqs: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FAQS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FAQS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.faqs = action.faqs
      break
    case constants.FETCH_FAQS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_FAQ:
      draft.faqs[action.id].sequence = action.sequence
      draft.faqs[action.id].folderId = action.folderId
      break
    case constants.CREATE_FAQ_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_FAQ_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.faqs[action.faq.id] = action.faq
      break
    case constants.CREATE_FAQ_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_FAQ_REQUEST:
      draft.faqs[action.id].deleting = true
      break
    case constants.DELETE_FAQ_SUCCESS:
    case constants.REMOVE_FAQ:
      delete draft.faqs[action.id]
      break
    case constants.DELETE_FAQ_FAILURE:
      draft.faqs[action.id].deleting = false
      draft.faqs[action.id].deleteError = action.errorMessage
      break
    case constants.SET_ACTIVE_FAQ:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_FAQ:
      draft.active = ''
      break
    case constants.SAVE_FAQ_REQUEST:
      draft.faqs[action.id].saving = true
      break
    case constants.SAVE_FAQ_SUCCESS:
      draft.faqs[action.id].saving = false
      draft.faqs[action.id].saveError = ''
      draft.faqs[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.faqs[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_FAQ_FAILURE:
      draft.faqs[action.id].saving = false
      draft.faqs[action.id].saveError = action.errorMessage
      draft.faqs[action.id].errors = action.errors
      break
    case constants.RESET_FAQ_ERRORS:
      draft.faqs[action.id].saveError = ''
      draft.faqs[action.id].errors = {}
      break
    case constants.CLONE_FAQS_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_FAQS_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      break
    case constants.CLONE_FAQS_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.SET_ACTIVE_DRAWER:
      draft.drawer = action.drawer
      break
    case constants.SELECT_FAQ:
      draft.faqs[action.id].selected = true
      break
    case constants.UNSELECT_FAQ:
      draft.faqs[action.id].selected = false
      break
    case constants.SET_FAQ:
      draft.faqs[action.faq.id] = action.faq
      break
    case constants.RESET_FAQS :
      return initialState
  }
}, initialState)
