import produce from 'immer'
import constants from 'constants/pinned-items'

const initialState = {
  active: undefined,
  items: {},
  ui: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  creating: false,
  createError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PINNED_ITEMS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PINNED_ITEMS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.items = action.items
      draft.ui = {}
      for (const key in action.items) {
        draft.ui[key] = {
          saving: false,
          saveError: '',
          errors: {}
        }
      }
      break
    case constants.FETCH_PINNED_ITEMS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.CREATE_PINNED_ITEM_REQUEST:
      draft.creating = true
      break
    case constants.CREATE_PINNED_ITEM_SUCCESS:
      draft.creating = false
      draft.createError = ''
      draft.items[action.item.id] = action.item
      draft.ui[action.item.id] = {
        saving: false,
        saveError: '',
        errors: {}
      }
      break
    case constants.CREATE_PINNED_ITEM_FAILURE:
      draft.creating = false
      draft.createError = action.errorMessage
      break
    case constants.SET_ACTIVE:
      draft.active = action.id
      break
    case constants.REORDER_PINNED_ITEMS:
      for (const key in action.updates) {
        draft.items[key].sequence = action.updates[key]
      }
      break
    case constants.RENAME_PINNED_ITEM_REQUEST:
      draft.ui[action.id].saving = true
      break
    case constants.RENAME_PINNED_ITEM_SUCCESS:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = ''
      draft.ui[action.id].errors = {}
      draft.items[action.id].name = action.name
      break
    case constants.RENAME_PINNED_ITEM_FAILURE:
      draft.ui[action.id].saving = false
      draft.ui[action.id].saveError = action.errorMessage
      draft.ui[action.id].errors = action.errors
      break
    case constants.RESET_PINNED_ITEM_ERRORS:
      draft.ui[action.id].errors = {}
      break
    case constants.RESET_PINNED_ITEMS:
      return initialState
    case constants.DESTROY_PINNED_ITEM_REQUEST:
      draft.ui[action.id].destroying = true
      break
    case constants.DESTROY_PINNED_ITEM_SUCCESS:
      delete draft.items[action.id]
      delete draft.ui[action.id]
      break
    case constants.DESTROY_PINNED_ITEM_FAILURE:
      draft.ui[action.id].destroying = false
      draft.ui[action.id].destroyError = action.errorMessage
      break
  }
}, initialState)
