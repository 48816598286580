import produce from 'immer'
import constants from 'constants/signup-form-questions'

const initialState = {
  questions: {},
  options: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QUESTIONS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QUESTIONS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.questions = action.questions
      draft.options = action.options
      break
    case constants.FETCH_QUESTIONS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_QUESTIONS:
      draft.questions[action.key] = action.value
      break
    case constants.SAVE_QUESTIONS_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_QUESTIONS_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_QUESTIONS_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
