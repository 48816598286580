import produce from 'immer'
import constants from 'constants/dashboard'

const initialState = {
  dashboard: {
    title: '',
    description: '',
    embedLink: '',
    sequence: ''
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  deleting: false,
  deleteError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DASHBOARD_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_DASHBOARD_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.dashboard = action.dashboard
      draft.title = action.dashboard.title
      draft.description = action.dashboard.description
      draft.embedLink = action.dashboard.embedLink
      draft.sequence = action.dashboard.sequence
      break
    case constants.FETCH_DASHBOARD_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.CREATE_DASHBOARD_REPORT_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_DASHBOARD_REPORT_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.CREATE_DASHBOARD_REPORT_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPDATE_DASHBOARD_REPORT:
      draft.dashboard[action.key] = action.value
      break
    case constants.DELETE_DASHBOARD_REQUEST:
      draft.deleting = true
      draft.deleteError = ''
      break
    case constants.DELETE_DASHBOARD_SUCCESS:
      draft.deleting = false
      draft.deleteError = ''
      break
    case constants.DELETE_DASHBOARD_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage
      break
  }
}, initialState)
