import produce from 'immer'
import constants from 'constants/project-approval'

const initialState = {
  projectApprovalLinks: {},
  activities: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  creating: false,
  createError: '',
  deleting: false,
  deleteError: '',
  errors: {},
  tab: 'generate',
  meta: {
    count: 0
  },
  tokenExpiryDays: 0,
  filters: {
    trackable_type: 'PrivateToken',
    project_id: ''
  },
  query: {
    include: ['owner']
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_SHARE_LINKS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECT_SHARE_LINKS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.projectApprovalLinks = action.projectApprovalLinks
      draft.meta = action.meta
      break
    case constants.FETCH_PROJECT_SHARE_LINKS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.CREATE_PROJECT_SHARE_LINK_REQUEST:
      draft.creating = true
      break
    case constants.CREATE_PROJECT_SHARE_LINK_SUCCESS:
      draft.creating = false
      draft.createError = ''
      break
    case constants.CREATE_PROJECT_SHARE_LINK_FAILURE:
      draft.creating = false
      draft.createError = action.errorMessage
      break
    case constants.DELETE_SHARE_LINK_REQUEST:
      draft.deleting = true
      break
    case constants.DELETE_SHARE_LINK_SUCCESS:
      draft.deleting = false
      draft.deleteError = ''
      break
    case constants.DELETE_SHARE_LINK_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage
      break
    case constants.UPDATE_PROJECT_APPROVAL_SHARE_LINK:
      draft.tokenExpiryDays = action.value
      break
    case constants.UPDATE_PROJECT_APPROVAL_TAB:
      draft.tab = action.value
      break
    case constants.FETCH_PROJECT_SHARE_LINK_ACTIVITIES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECT_SHARE_LINK_ACTIVITIES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.activities = action.activities
      draft.meta.count = action.meta.count
      break
    case constants.FETCH_PROJECT_SHARE_LINK_ACTIVITIES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
