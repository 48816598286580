import produce from 'immer'
import constants from 'constants/stages'

const initialState = {
  active: '',
  stages: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  cloning: false,
  cloneError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.UPDATE_STAGE:
      draft.stages[action.id][action.key] = action.value
      break
    case constants.FETCH_STAGES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_STAGES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.stages = action.stages
      break
    case constants.FETCH_STAGES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.DELETE_STAGE_REQUEST:
      draft.stages[action.id].deleting = true
      break
    case constants.DELETE_STAGE_SUCCESS:
      delete draft.stages[action.id]
      break
    case constants.DELETE_STAGE_FAILURE:
      draft.stages[action.id].deleting = false
      draft.stages[action.id].deleteError = action.errorMessage
      break
    case constants.SET_CURRENT_STAGE_REQUEST:
      draft.stages[action.id].saving = true
      break
    case constants.SET_CURRENT_STAGE_SUCCESS:
      (() => {
        for (let key in draft.stages) {
          let stage = draft.stages[key]
          if (action.id === stage.id) {
            stage.saving = false
            stage.saveError = ''
            stage.currentStage = true
          } else if (action.previous && stage.id === action.previous) {
            stage.currentStage = false
          }
        }
      })()
      break
    case constants.SET_CURRENT_STAGE_FAILURE:
      draft.stages[action.id].saving = false
      draft.stages[action.id].saveError = action.errorMessage
      break
    case constants.REORDER_STAGES:
      (() => {
        for (let key in action.updates) {
          draft.stages[key].sequence = action.updates[key]
        }
      })()
      break
    case constants.CREATE_STAGE_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_STAGE_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.stages[action.stage.id] = action.stage
      break
    case constants.CREATE_STAGE_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_STAGE_REQUEST:
      draft.stages[action.id].saving = true
      break
    case constants.SAVE_STAGE_SUCCESS:
      draft.stages[action.id].saving = false
      draft.stages[action.id].saveError = ''
      draft.stages[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.stages[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_STAGE_FAILURE:
      draft.stages[action.id].saving = false
      draft.stages[action.id].saveError = action.errorMessage
      draft.stages[action.id].errors = action.errors
      break
    case constants.RESET_STAGE_ERRORS:
      draft.stages[action.id].saveError = ''
      draft.stages[action.id].errors = {}
      break
    case constants.SET_ACTIVE_STAGE:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_STAGE:
      draft.active = ''
      break
    case constants.CLONE_STAGES_REQUEST:
      draft.cloning = true
      break
    case constants.CLONE_STAGES_SUCCESS:
      draft.cloning = false
      draft.cloneError = ''
      action.stages.forEach((stage) => {
        draft.stages[stage.id] = stage
      })
      break
    case constants.CLONE_STAGES_FAILURE:
      draft.cloning = false
      draft.cloneError = action.errorMessage
      break
    case constants.RESET_STAGES:
      return initialState
  }
}, initialState)
