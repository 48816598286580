export default {
  FETCH_PROJECT_SHARE_LINKS_REQUEST: 'FETCH_PROJECT_SHARE_LINKS_REQUEST',
  FETCH_PROJECT_SHARE_LINKS_SUCCESS: 'FETCH_PROJECT_SHARE_LINKS_SUCCESS',
  FETCH_PROJECT_SHARE_LINKS_FAILURE: 'FETCH_PROJECT_SHARE_LINKS_FAILURE',
  CREATE_PROJECT_SHARE_LINK_REQUEST: 'CREATE_PROJECT_SHARE_LINK_REQUEST',
  CREATE_PROJECT_SHARE_LINK_SUCCESS: 'CREATE_PROJECT_SHARE_LINK_SUCCESS',
  CREATE_PROJECT_SHARE_LINK_FAILURE: 'CREATE_PROJECT_SHARE_LINK_FAILURE',
  DELETE_SHARE_LINK_REQUEST: 'DELETE_SHARE_LINK_REQUEST',
  DELETE_SHARE_LINK_SUCCESS: 'DELETE_SHARE_LINK_SUCCESS',
  DELETE_SHARE_LINK_FAILURE: 'DELETE_SHARE_LINK_FAILURE',
  UPDATE_PROJECT_APPROVAL_SHARE_LINK: 'UPDATE_PROJECT_APPROVAL_SHARE_LINK',
  UPDATE_PROJECT_APPROVAL_TAB: 'UPDATE_PROJECT_APPROVAL_TAB',
  FETCH_PROJECT_SHARE_LINK_ACTIVITIES_REQUEST: 'FETCH_PROJECT_SHARE_LINK_ACTIVITIES_REQUEST',
  FETCH_PROJECT_SHARE_LINK_ACTIVITIES_SUCCESS: 'FETCH_PROJECT_SHARE_LINK_ACTIVITIES_SUCCESS',
  FETCH_PROJECT_SHARE_LINK_ACTIVITIES_FAILURE: 'FETCH_PROJECT_SHARE_LINK_ACTIVITIES_FAILURE'
}
