import produce from 'immer'
import constants from 'constants/notifications'

const initialState = {
  fetching: false,
  fetchingProjects: false,
  doneFetching: false,
  doneFetchingProjects: false,
  fetchError: '',
  fetchProjectsError: '',
  hideLoadMore: false,
  notifications: [],
  projects: [],
  filters: {
    trackable_type: '',
    project_id: '',
    search: '',
    front_end: '1'
  },
  query: {
    page: 1,
    per_page: 50,
    include: ['project', 'owner', 'trackable'],
    fields: {
      'project': ['name', 'permalink'],
      'user': ['login', 'type'],
      'forum-topic': ['name', 'permalink'],
      'blog-post': ['name', 'permalink'],
      'survey-tool': ['name', 'permalink'],
      'map': ['name', 'permalink'],
      'quick-poll': ['name', 'permalink'],
      'guest-book': ['name', 'permalink'],
      'brainstormer': ['name', 'permalink'],
      'qanda': ['name', 'permalink'],
      'story-telling-tool': ['name', 'permalink']
    }
  },
  meta: {
    count: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_NOTIFICATIONS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_NOTIFICATIONS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.meta = action.meta
      draft.notifications = action.notifications
      draft.hideLoadMore = (action.meta.count === action.notifications.length)
      break
    case constants.FETCH_NOTIFICATIONS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_NOTIFICATIONS_PROJECTS_REQUEST:
      draft.fetchingProjects = true
      break
    case constants.FETCH_NOTIFICATIONS_PROJECTS_SUCCESS:
      draft.fetchingProjects = false
      draft.doneFetchingProjects = true
      draft.fetchError = ''
      draft.projects = action.projects
      break
    case constants.FETCH_NOTIFICATIONS_PROJECTS_FAILURE:
      draft.fetchingProjects = false
      draft.doneFetchingProjects = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_NOTIFICATIONS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_NOTIFICATIONS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.RESET_NOTIFICATIONS:
      return initialState
  }
}, initialState)
