import produce from 'immer'
import constants from 'constants/suburbs'

const initialState = {
  suburbs: [],
  query: {
    per_page: 25,
    page: 1
  },
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SUBURBS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SUBURBS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.suburbs = action.suburbs
      break
    case constants.FETCH_SUBURBS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SUBURBS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
  }
}, initialState)
