import produce from 'immer'

import constants from '../constants/generate-permalink-static-page'

const initialState = {
  page: {
    permalink: ''
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  generatingPermalink: false,
  generatePermalinkError: '',
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.GENERATE_STATIC_PAGE_PERMALINK_REQUEST:
      draft.generatingPermalink = true
      break

    case constants.GENERATE_STATIC_PAGE_PERMALINK_SUCCESS:
      draft.generatingPermalink = false
      draft.page.permalink = action.value
      break

    case constants.GENERATE_STATIC_PAGE_PERMALINK_FAILURE:
      draft.generatingPermalink = false
      draft.saveError = action.errorMessage

      break
  }
}, initialState)
