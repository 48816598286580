export default {
  FETCH_STORIES_REQUEST: 'FETCH_STORIES_REQUEST',
  FETCH_STORIES_SUCCESS: 'FETCH_STORIES_SUCCESS',
  FETCH_STORIES_FAILURE: 'FETCH_STORIES_FAILURE',
  TOGGLE_STORY: 'TOGGLE_STORY',
  ACCEPT_STORY_REQUEST: 'ACCEPT_STORY_REQUEST',
  ACCEPT_STORY_SUCCESS: 'ACCEPT_STORY_SUCCESS',
  ACCEPT_STORY_FAILURE: 'ACCEPT_STORY_FAILURE',
  REJECT_STORY_REQUEST: 'REJECT_STORY_REQUEST',
  REJECT_STORY_SUCCESS: 'REJECT_STORY_SUCCESS',
  REJECT_STORY_FAILURE: 'REJECT_STORY_FAILURE',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB'

}
