import produce from 'immer'
import constants from 'constants/liquid-templates'
import findIndex from 'lodash/findIndex'

const initialState = {
  liquidTemplates: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_LIQUID_TEMPLATES_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_LIQUID_TEMPLATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.liquidTemplates = action.liquidTemplates

      break

    case constants.FETCH_LIQUID_TEMPLATES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.UPDATE_LIQUID_TEMPLATE:
      draft.liquidTemplates[findIndex(draft.liquidTemplates, template => template.id === action.templateId)][action.key] = action.value

      break

    case constants.SAVE_LIQUID_TEMPLATES_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_LIQUID_TEMPLATES_SUCCESS:
      draft.saving = false
      draft.saveError = ''

      break

    case constants.SAVE_LIQUID_TEMPLATES_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage

      break
  }
}, initialState)
