export default {
  FETCH_GUEST_BOOK_REQUEST: 'FETCH_PINNED_GUEST_BOOK_REQUEST',
  FETCH_GUEST_BOOK_SUCCESS: 'FETCH_PINNED_GUEST_BOOK_SUCCESS',
  FETCH_GUEST_BOOK_FAILURE: 'FETCH_PINNED_GUEST_BOOK_FAILURE',
  RESET_GUEST_BOOK: 'RESET_PINNED_GUEST_BOOK',
  PUBLISH_GUEST_BOOK_REQUEST: 'PUBLISH_GUEST_BOOK_REQUEST',
  PUBLISH_GUEST_BOOK_SUCCESS: 'PUBLISH_GUEST_BOOK_SUCCESS',
  PUBLISH_GUEST_BOOK_FAILURE: 'PUBLISH_GUEST_BOOK_FAILURE',
  UNPUBLISH_GUEST_BOOK_REQUEST: 'UNPUBLISH_GUEST_BOOK_REQUEST',
  UNPUBLISH_GUEST_BOOK_SUCCESS: 'UNPUBLISH_GUEST_BOOK_SUCCESS',
  UNPUBLISH_GUEST_BOOK_FAILURE: 'UNPUBLISH_GUEST_BOOK_FAILURE',
  ARCHIVE_GUEST_BOOK_REQUEST: 'ARCHIVE_GUEST_BOOK_REQUEST',
  ARCHIVE_GUEST_BOOK_SUCCESS: 'ARCHIVE_GUEST_BOOK_SUCCESS',
  ARCHIVE_GUEST_BOOK_FAILURE: 'ARCHIVE_GUEST_BOOK_FAILURE',
  UNARCHIVE_GUEST_BOOK_REQUEST: 'UNARCHIVE_GUEST_BOOK_REQUEST',
  UNARCHIVE_GUEST_BOOK_SUCCESS: 'UNARCHIVE_GUEST_BOOK_SUCCESS',
  UNARCHIVE_GUEST_BOOK_FAILURE: 'UNARCHIVE_GUEST_BOOK_FAILURE',
  DESTROY_GUEST_BOOK_REQUEST: 'DESTROY_GUEST_BOOK_REQUEST',
  DESTROY_GUEST_BOOK_SUCCESS: 'DESTROY_GUEST_BOOK_SUCCESS',
  DESTROY_GUEST_BOOK_FAILURE: 'DESTROY_GUEST_BOOK_FAILURE'
}
