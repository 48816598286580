import produce from 'immer'
import constants from 'constants/pinned-story-collection'

const initialState = {
  storyCollection: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STORY_COLLECTION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_STORY_COLLECTION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.storyCollection = action.storyCollection
      break
    case constants.FETCH_STORY_COLLECTION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_STORY_COLLECTION:
      return initialState
    case constants.PUBLISH_STORY_COLLECTION_REQUEST:
    case constants.UNPUBLISH_STORY_COLLECTION_REQUEST:
    case constants.ARCHIVE_STORY_COLLECTION_REQUEST:
    case constants.UNARCHIVE_STORY_COLLECTION_REQUEST:
    case constants.DESTROY_STORY_COLLECTION_REQUEST:
      draft.storyCollection.transitioning = true
      break
    case constants.PUBLISH_STORY_COLLECTION_SUCCESS:
    case constants.UNARCHIVE_STORY_COLLECTION_SUCCESS:
      draft.storyCollection.state = 'published'
      draft.storyCollection.transitioning = false
      draft.storyCollection.transitionError = ''
      break
    case constants.UNPUBLISH_STORY_COLLECTION_SUCCESS:
      draft.storyCollection.state = 'draft'
      draft.storyCollection.transitioning = false
      draft.storyCollection.transitionError = ''
      break
    case constants.ARCHIVE_STORY_COLLECTION_SUCCESS:
      draft.storyCollection.state = 'archived'
      draft.storyCollection.transitioning = false
      draft.storyCollection.transitionError = ''
      break
    case constants.DESTROY_STORY_COLLECTION_SUCCESS:
      draft.storyCollection = {}
      break
    case constants.PUBLISH_STORY_COLLECTION_FAILURE:
    case constants.UNPUBLISH_STORY_COLLECTION_FAILURE:
    case constants.ARCHIVE_STORY_COLLECTION_FAILURE:
    case constants.UNARCHIVE_STORY_COLLECTION_FAILURE:
    case constants.DESTROY_STORY_COLLECTION_FAILURE:
      draft.storyCollection.transitioning = false
      draft.storyCollection.transitionError = action.errorMessage
      break
  }
}, initialState)
