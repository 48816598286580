import produce from 'immer'
import constants from '../constants/blog-post-comments'

const initialState = {
  comments: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  query: {
    es: true
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BLOG_POST_COMMENTS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_BLOG_POST_COMMENTS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.comments = action.comments
      break
    case constants.FETCH_BLOG_POST_COMMENTS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.UPDATE_BLOG_POST_COMMENT_SENTIMENT:
      draft.comments.forEach(comment => {
        if (comment.id === action.id) {
          comment[action.key] = action.value
        }
        return comment
      })
      break
  }
}, initialState)
