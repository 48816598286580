export default {
  FETCH_GOOGLE_TRANSLATE_INTEGRATION_REQUEST: 'FETCH_GOOGLE_TRANSLATE_INTEGRATION_REQUEST',
  FETCH_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS: 'FETCH_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS',
  FETCH_GOOGLE_TRANSLATE_INTEGRATION_FAILURE: 'FETCH_GOOGLE_TRANSLATE_INTEGRATION_FAILURE',
  UPDATE_GOOGLE_TRANSLATE_INTEGRATION: 'UPDATE_GOOGLE_TRANSLATE_INTEGRATION',
  RESET_GOOGLE_TRANSLATE_INTEGRATION: 'RESET_GOOGLE_TRANSLATE_INTEGRATION',
  SAVE_GOOGLE_TRANSLATE_INTEGRATION_REQUEST: 'SAVE_GOOGLE_TRANSLATE_INTEGRATION_REQUEST',
  SAVE_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS: 'SAVE_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS',
  SAVE_GOOGLE_TRANSLATE_INTEGRATION_FAILURE: 'SAVE_GOOGLE_TRANSLATE_INTEGRATIONS_FAILURE'
}
