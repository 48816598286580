import produce from 'immer'
import findIndex from 'lodash/findIndex'

import constants from 'constants/administrators'

const initialState = {
  administrators: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  meta: {
    count: 0
  },
  queries: {
    page: 1,
    per_page: 25,
    filterable: true
  },
  filters: {
    search: '',
    sort: {
      login: 'desc'
    }
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_ADMINISTRATORS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_ADMINISTRATORS_SUCCESS:
      draft.administrators = action.administrators
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.meta = action.meta

      break

    case constants.FETCH_ADMINISTRATORS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = false

      break

    case constants.BLOCK_ADMINISTRATOR_REQUEST:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.id)].blocking = true

      break

    case constants.BLOCK_ADMINISTRATOR_SUCCESS:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.administrator.id)] = {
        ...action.administrator,
        blocking: false,
        blockError: ''
      }

      break

    case constants.BLOCK_ADMINISTRATOR_FAILURE:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.administrator.id)] = {
        ...action.administrator,
        blocking: false,
        blockError: action.errorMessage
      }

      break

    case constants.UNBLOCK_ADMINISTRATOR_REQUEST:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.id)].unblocking = true

      break

    case constants.UNBLOCK_ADMINISTRATOR_SUCCESS:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.administrator.id)] = {
        ...action.administrator,
        unblocking: false,
        unblockError: ''
      }

      break

    case constants.UNBLOCK_ADMINISTRATOR_FAILURE:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.administrator.id)] = {
        ...action.administrator,
        unblocking: false,
        unblockError: action.errorMessage
      }

      break

    case constants.UPDATE_ADMINISTRATORS_QUERIES:
      draft.queries = { ...draft.queries, ...action.queries }

      break

    case constants.UPDATE_ADMINISTRATORS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }

      break

    case constants.FETCH_HUB_ADMINISTRATORS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_HUB_ADMINISTRATORS_SUCCESS:
      draft.administrators = action.administrators
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''

      break

    case constants.FETCH_HUB_ADMINISTRATORS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = false

      break

    case constants.UPDATE_TO_PROJECT_ADMIN_ROLE_REQUEST:
    case constants.UPDATE_TO_SITE_ADMIN_ROLE_REQUEST:
    case constants.UPDATE_TO_HUB_ADMIN_ROLE_REQUEST:
    case constants.UPDATE_TO_PARTICIPANT_ROLE_REQUEST:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.id)].updatingRole = true

      break

    case constants.UPDATE_TO_PROJECT_ADMIN_ROLE_SUCCESS:
    case constants.UPDATE_TO_SITE_ADMIN_ROLE_SUCCESS:
    case constants.UPDATE_TO_HUB_ADMIN_ROLE_SUCCESS:
    case constants.UPDATE_TO_PARTICIPANT_ROLE_SUCCESS:
      draft.administrators[findIndex(draft.administrators, administrator => administrator.id === action.administrator.id)] = {
        ...action.administrator,
        updatingRole: false,
        updateRoleError: ''
      }

      break

    case constants.UPDATE_TO_PROJECT_ADMIN_ROLE_FAILURE:
    case constants.UPDATE_TO_SITE_ADMIN_ROLE_FAILURE:
    case constants.UPDATE_TO_HUB_ADMIN_ROLE_FAILURE:
    case constants.UPDATE_TO_PARTICIPANT_ROLE_FAILURE:
      const index = findIndex(draft.administrators, administrator => administrator.id === action.id)
      draft.administrators[index] = {
        ...draft.administrators[index],
        updatingRole: false,
        updateRoleError: action.errorMessage,
        updateRoleErrors: action.errors
      }

      break

    case constants.RESET_ADMINISTRATORS:
      return initialState
  }
}, initialState)
