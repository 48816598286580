import produce from 'immer'
import constants from 'constants/pinned-qanda'

const initialState = {
  qanda: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QANDA_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QANDA_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.qanda = action.qanda
      break
    case constants.FETCH_QANDA_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_QANDA:
      return initialState
    case constants.PUBLISH_QANDA_REQUEST:
    case constants.UNPUBLISH_QANDA_REQUEST:
    case constants.ARCHIVE_QANDA_REQUEST:
    case constants.UNARCHIVE_QANDA_REQUEST:
    case constants.DESTROY_QANDA_REQUEST:
      draft.qanda.transitioning = true
      break
    case constants.PUBLISH_QANDA_SUCCESS:
    case constants.UNARCHIVE_QANDA_SUCCESS:
      draft.qanda.state = 'published'
      draft.qanda.transitioning = false
      draft.qanda.transitionError = ''
      break
    case constants.UNPUBLISH_QANDA_SUCCESS:
      draft.qanda.state = 'draft'
      draft.qanda.transitioning = false
      draft.qanda.transitionError = ''
      break
    case constants.ARCHIVE_QANDA_SUCCESS:
      draft.qanda.state = 'archived'
      draft.qanda.archivalReasonMessage = action.archivalReasonMessage
      draft.qanda.transitioning = false
      draft.qanda.transitionError = ''
      break
    case constants.DESTROY_QANDA_SUCCESS:
      draft.qanda = {}
      break
    case constants.PUBLISH_QANDA_FAILURE:
    case constants.UNPUBLISH_QANDA_FAILURE:
    case constants.ARCHIVE_QANDA_FAILURE:
    case constants.UNARCHIVE_QANDA_FAILURE:
    case constants.DESTROY_QANDA_FAILURE:
      draft.qanda.transitioning = false
      draft.qanda.transitionError = action.errorMessage
      break
  }
}, initialState)
