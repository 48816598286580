export default {
  FETCH_SURVEY_PAGES_REQUEST: 'FETCH_SURVEY_PAGES_REQUEST',
  FETCH_SURVEY_PAGES_SUCCESS: 'FETCH_SURVEY_PAGES_SUCCESS',
  FETCH_SURVEY_PAGES_FAILURE: 'FETCH_SURVEY_PAGES_FAILURE',
  FETCH_SURVEY_PAGE_QUESTIONS_REQUEST: 'FETCH_SURVEY_PAGE_QUESTIONS_REQUEST',
  FETCH_SURVEY_PAGE_QUESTIONS_SUCCESS: 'FETCH_SURVEY_PAGE_QUESTIONS_SUCCESS',
  FETCH_SURVEY_PAGE_QUESTIONS_FAILURE: 'FETCH_SURVEY_PAGE_QUESTIONS_FAILURE',
  FETCH_REGISTRATION_QUESTIONS_REQUEST: 'FETCH_REGISTRATION_QUESTIONS_REQUEST',
  FETCH_REGISTRATION_QUESTIONS_SUCCESS: 'FETCH_REGISTRATION_QUESTIONS_SUCCESS',
  FETCH_REGISTRATION_QUESTIONS_FAILURE: 'FETCH_REGISTRATION_QUESTIONS_FAILURE',
  UPDATE_CHECKED_REGISTRATION_QUESTIONS: 'UPDATE_CHECKED_REGISTRATION_QUESTIONS',
  UPDATE_ADD_REMOVE_REGISTRATION_QUESTIONS: 'UPDATE_ADD_REMOVE_REGISTRATION_QUESTIONS',
  UPDATE_CHECKED_SURVEY_PAGE_QUESTIONS: 'UPDATE_CHECKED_SURVEY_PAGE_QUESTIONS',
  UPDATE_ADD_REMOVE_DELETED_QUESTIONS: 'UPDATE_ADD_REMOVE_DELETED_QUESTIONS',
  UPDATE_ADD_REMOVE_OPEN_QUESTIONS: 'UPDATE_ADD_REMOVE_OPEN_QUESTIONS',
  UPDATE_ADD_REMOVE_SCREEN_NAME: 'UPDATE_ADD_REMOVE_SCREEN_NAME',
  RESET_SURVEY_PAGE_QUESTIONS: 'RESET_SURVEY_PAGE_QUESTIONS',
  ENABLE_DISABLE_PAGE_LAYOUT_LOGO: 'ENABLE_DISABLE_PAGE_LAYOUT_LOGO',
  UPDATE_LOGO_URL: 'UPDATE_LOGO_URL',
  UPDATE_COVER_NOTE: 'UPDATE_COVER_NOTE',
  UPDATE_REPORT_HEADER_FOOTER: 'UPDATE_REPORT_HEADER_FOOTER',
  UPDATE_CONCLUSION: 'UPDATE_CONCLUSION',
  FETCH_DOWNLOAD_REPORT_REQUEST: 'FETCH_DOWNLOAD_REPORT_REQUEST',
  FETCH_DOWNLOAD_REPORT_SUCCESS: 'FETCH_DOWNLOAD_REPORT_SUCCESS',
  FETCH_DOWNLOAD_REPORT_FAILURE: 'FETCH_DOWNLOAD_REPORT_FAILURE',
  SHOW_DOWNLOAD_POPUP: 'SHOW_DOWNLOAD_POPUP',
  SHOW_ERROR_ALERT_POPUP: 'SHOW_ERROR_ALERT_POPUP'
}
