export default {
  FETCH_SMS_INTEGRATION_REQUEST: 'FETCH_SMS_INTEGRATION_REQUEST',
  FETCH_SMS_INTEGRATION_SUCCESS: 'FETCH_SMS_INTEGRATION_SUCCESS',
  FETCH_SMS_INTEGRATION_FAILURE: 'FETCH_SMS_INTEGRATION_FAILURE',
  SAVE_SMS_INTEGRATION_REQUEST: 'SAVE_SMS_INTEGRATION_REQUEST',
  SAVE_SMS_INTEGRATION_SUCCESS: 'SAVE_SMS_INTEGRATION_SUCCESS',
  SAVE_SMS_INTEGRATION_FAILURE: 'SAVE_SMS_INTEGRATION_FAILURE',
  UPDATE_SMS_INTEGRATION_NUMBER: 'UPDATE_SMS_INTEGRATION_NUMBER',
  UPDATE_SMS_INTEGRATION_FILTERS: 'UPDATE_SMS_INTEGRATION_FILTERS',
  UPDATE_THANK_YOU_MESSAGE: 'UPDATE_THANK_YOU_MESSAGE',
  DISSOCIATE_TOOL_AND_PROJECT: 'DISSOCIATE_TOOL_AND_PROJECT',
  UPDATE_SMS_INTEGRATION: 'UPDATE_SMS_INTEGRATION',
  DELETE_SMS_INTEGRATION_REQUEST: 'DELETE_SMS_INTEGRATION_REQUEST',
  DELETE_SMS_INTEGRATION_SUCCESS: 'DELETE_SMS_INTEGRATION_SUCCESS',
  DELETE_SMS_INTEGRATION_FAILURE: 'DELETE_SMS_INTEGRATION_FAILURE'
}
