export default {
  FETCH_PINNED_ITEMS_REQUEST: 'FETCH_PINNED_ITEMS_REQUEST',
  FETCH_PINNED_ITEMS_SUCCESS: 'FETCH_PINNED_ITEMS_SUCCESS',
  FETCH_PINNED_ITEMS_FAILURE: 'FETCH_PINNED_ITEMS_FAILURE',
  CREATE_PINNED_ITEM_REQUEST: 'CREATE_PINNED_ITEM_REQUEST',
  CREATE_PINNED_ITEM_SUCCESS: 'CREATE_PINNED_ITEM_SUCCESS',
  CREATE_PINNED_ITEM_FAILURE: 'CREATE_PINNED_ITEM_FAILURE',
  SET_ACTIVE: 'SET_ACTIVE_PINNED_ITEM',
  REORDER_PINNED_ITEMS: 'REORDER_PINNED_ITEMS',
  RENAME_PINNED_ITEM_REQUEST: 'RENAME_PINNED_ITEM_REQUEST',
  RENAME_PINNED_ITEM_SUCCESS: 'RENAME_PINNED_ITEM_SUCCESS',
  RENAME_PINNED_ITEM_FAILURE: 'RENAME_PINNED_ITEM_FAILURE',
  RESET_PINNED_ITEM_ERRORS: 'RESET_PINNED_ITEM_ERRORS',
  RESET_PINNED_ITEMS: 'RESET_PINNED_ITEMS',
  DESTROY_PINNED_ITEM_REQUEST: 'DESTROY_PINNED_ITEM_REQUEST',
  DESTROY_PINNED_ITEM_SUCCESS: 'DESTROY_PINNED_ITEM_SUCCESS',
  DESTROY_PINNED_ITEM_FAILURE: 'DESTROY_PINNED_ITEM_FAILURE'
}
