module.exports = {
  UPDATE_OPTION: 'UPDATE_QUICK_POLL_OPTION',
  ADD_OPTION: 'ADD_QUICK_POLL_OPTION',
  REMOVE_OPTION: 'REMOVE_QUICK_POLL_OPTION',
  SAVE_OPTION_REQUEST: 'SAVE_QUICK_POLL_OPTION_REQUEST',
  SAVE_OPTION_SUCCESS: 'SAVE_QUICK_POLL_OPTION_SUCCESS',
  SAVE_OPTION_FAILURE: 'SAVE_QUICK_POLL_OPTION_FAILURE',
  SAVE_OPTIONS_REQUEST: 'SAVE_QUICK_POLL_OPTIONS_REQUEST',
  SAVE_OPTIONS_SUCCESS: 'SAVE_QUICK_POLL_OPTIONS_SUCCESS',
  SAVE_OPTIONS_FAILURE: 'SAVE_QUICK_POLL_OPTIONS_FAILURE',
  SET_OPTIONS: 'SET_QUICK_POLL_OPTIONS',
  RESET_OPTIONS: 'RESET_QUICK_POLL_OPTIONS',
  HIDE_OPTION_REQUEST: 'HIDE_QUICK_POLL_OPTION_REQUEST',
  HIDE_OPTION_SUCCESS: 'HIDE_QUICK_POLL_OPTION_SUCCESS',
  HIDE_OPTION_FAILURE: 'HIDE_QUICK_POLL_OPTION_FAILURE',
  UNHIDE_OPTION_REQUEST: 'UNHIDE_QUICK_POLL_OPTION_REQUEST',
  UNHIDE_OPTION_SUCCESS: 'UNHIDE_QUICK_POLL_OPTION_SUCCESS',
  UNHIDE_OPTION_FAILURE: 'UNHIDE_QUICK_POLL_OPTION_FAILURE',
  DESTROY_OPTION_REQUEST: 'DESTROY_QUICK_POLL_OPTION_REQUEST',
  DESTROY_OPTION_SUCCESS: 'DESTROY_QUICK_POLL_OPTION_SUCCESS',
  DESTROY_OPTION_FAILURE: 'DESTROY_QUICK_POLL_OPTION_FAILURE'
}
