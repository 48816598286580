import produce from 'immer'
import constants from 'constants/forum'

const initialState = {
  forum: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FORUM_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FORUM_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.forum = action.forum
      break
    case constants.FETCH_FORUM_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_FORUM:
      draft.forum[action.key] = action.value
      break
    case constants.SAVE_FORUM_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_FORUM_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_FORUM_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.FETCH_FORUM_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_FORUM_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.forum.permalink = action.permalink
      draft.forum.holdPostRequest = false
      break
    case constants.FETCH_FORUM_PERMALINK_FAILURE:
      draft.fetching = false
      draft.forum.permalink = ''
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_FORUM:
      return initialState
  }
}, initialState)
