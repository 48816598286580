export default {
  FETCH_PROJECT_REQUEST: 'FETCH_PROJECT_REQUEST',
  FETCH_PROJECT_SUCCESS: 'FETCH_PROJECT_SUCCESS',
  FETCH_PROJECT_FAILURE: 'FETCH_PROJECT_FAILURE',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_CROPPED_IMAGE: 'UPDATE_CROPPED_IMAGE',
  TOGGLE_IMAGE_SETTINGS: 'TOGGLE_IMAGE_SETTINGS',
  SAVE_PROJECT_REQUEST: 'SAVE_PROJECT_REQUEST',
  SAVE_PROJECT_SUCCESS: 'SAVE_PROJECT_SUCCESS',
  SAVE_PROJECT_FAILURE: 'SAVE_PROJECT_FAILURE',
  RESET_PROJECT: 'RESET_PROJECT',
  RESET_ERRORS: 'RESET_PROJECT_ERRORS',
  UPLOAD_BANNER_REQUEST: 'UPLOAD_BANNER_REQUEST',
  UPLOAD_BANNER_SUCCESS: 'UPLOAD_BANNER_SUCCESS',
  UPLOAD_BANNER_FAILURE: 'UPLOAD_BANNER_FAILURE',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_PROJECT_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_PROJECT_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_PROJECT_IMAGE_FAILURE',
  PUBLISH_PROJECT_REQUEST: 'PUBLISH_PROJECT_REQUEST',
  PUBLISH_PROJECT_SUCCESS: 'PUBLISH_PROJECT_SUCCESS',
  PUBLISH_PROJECT_FAILURE: 'PUBLISH_PROJECT_FAILURE',
  UNPUBLISH_PROJECT_REQUEST: 'UNPUBLISH_PROJECT_REQUEST',
  UNPUBLISH_PROJECT_SUCCESS: 'UNPUBLISH_PROJECT_SUCCESS',
  UNPUBLISH_PROJECT_FAILURE: 'UNPUBLISH_PROJECT_FAILURE',
  ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
  ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
  ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',
  UNARCHIVE_PROJECT_REQUEST: 'UNARCHIVE_PROJECT_REQUEST',
  UNARCHIVE_PROJECT_SUCCESS: 'UNARCHIVE_PROJECT_SUCCESS',
  UNARCHIVE_PROJECT_FAILURE: 'UNARCHIVE_PROJECT_FAILURE',
  HIDE_PROJECT_REQUEST: 'HIDE_PROJECT_REQUEST',
  HIDE_PROJECT_SUCCESS: 'HIDE_PROJECT_SUCCESS',
  HIDE_PROJECT_FAILURE: 'HIDE_PROJECT_FAILURE',
  UNHIDE_PROJECT_REQUEST: 'UNHIDE_PROJECT_REQUEST',
  UNHIDE_PROJECT_SUCCESS: 'UNHIDE_PROJECT_SUCCESS',
  UNHIDE_PROJECT_FAILURE: 'UNHIDE_PROJECT_FAILURE',
  DESTROY_PROJECT_REQUEST: 'DESTROY_PROJECT_REQUEST',
  DESTROY_PROJECT_SUCCESS: 'DESTROY_PROJECT_SUCCESS',
  DESTROY_PROJECT_FAILURE: 'DESTROY_PROJECT_FAILURE',
  CLONE_PROJECT_REQUEST: 'CLONE_PROJECT_REQUEST',
  CLONE_PROJECT_SUCCESS: 'CLONE_PROJECT_SUCCESS',
  CLONE_PROJECT_FAILURE: 'CLONE_PROJECT_FAILURE',
  TOGGLE_PROJECT_SETTINGS: 'TOGGLE_PROJECT_SETTINGS',
  FETCH_PROJECT_PERMALINK_REQUEST: 'FETCH_PROJECT_PERMALINK_REQUEST',
  FETCH_PROJECT_PERMALINK_SUCCESS: 'FETCH_PROJECT_PERMALINK_SUCCESS',
  FETCH_PROJECT_PERMALINK_FAILURE: 'FETCH_PROJECT_PERMALINK_FAILURE',
  TRACK_CHANGES: 'TRACK_CHANGES'
}
