export default {
  FETCH_LAYOUT_REQUEST: 'FETCH_LAYOUT_REQUEST',
  FETCH_LAYOUT_SUCCESS: 'FETCH_LAYOUT_SUCCESS',
  FETCH_LAYOUT_FAILURE: 'FETCH_LAYOUT_FAILURE',
  UPDATE_LAYOUT: 'UPDATE_LAYOUT',
  SAVE_LAYOUT_REQUEST: 'SAVE_LAYOUT_REQUEST',
  SAVE_LAYOUT_SUCCESS: 'SAVE_LAYOUT_SUCCESS',
  SAVE_LAYOUT_FAILURE: 'SAVE_LAYOUT_FAILURE',
  RESET_LAYOUT_REQUEST: 'RESET_LAYOUT_REQUEST',
  RESET_LAYOUT_SUCCESS: 'RESET_LAYOUT_SUCCESS',
  RESET_LAYOUT_FAILURE: 'RESET_LAYOUT_FAILURE',
  RESET_LAYOUT_COLOR_REQUEST: 'RESET_THEME_COLOR_REQUEST',
  RESET_LAYOUT_COLOR_SUCCESS: 'RESET_THEME_COLOR_SUCCESS',
  RESET_LAYOUT_COLOR_FAILURE: 'RESET_THEME_COLOR_FAILURE'
}
