export default {
  FETCH_KEY_DATES_REQUEST: 'FETCH_KEY_DATES_REQUEST',
  FETCH_KEY_DATES_SUCCESS: 'FETCH_KEY_DATES_SUCCESS',
  FETCH_KEY_DATES_FAILURE: 'FETCH_KEY_DATES_FAILURE',
  REORDER_KEY_DATES: 'REORDER_KEY_DATES',
  DELETE_KEY_DATE_REQUEST: 'DELETE_KEY_DATE_REQUEST',
  DELETE_KEY_DATE_SUCCESS: 'DELETE_KEY_DATE_SUCCESS',
  DELETE_KEY_DATE_FAILURE: 'DELETE_KEY_DATE_FAILURE',
  SET_ACTIVE_KEY_DATE: 'SET_ACTIVE_KEY_DATE',
  UNSET_ACTIVE_KEY_DATE: 'UNSET_ACTIVE_KEY_DATE',
  CREATE_KEY_DATE_REQUEST: 'CREATE_KEY_DATE_REQUEST',
  CREATE_KEY_DATE_SUCCESS: 'CREATE_KEY_DATE_SUCCESS',
  CREATE_KEY_DATE_FAILURE: 'CREATE_KEY_DATE_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_KEY_DATE_ERRORS',
  SAVE_KEY_DATE_REQUEST: 'SAVE_KEY_DATE_REQUEST',
  SAVE_KEY_DATE_SUCCESS: 'SAVE_KEY_DATE_SUCCESS',
  SAVE_KEY_DATE_FAILURE: 'SAVE_KEY_DATE_FAILURE',
  RESET_KEY_DATE_ERRORS: 'RESET_KEY_DATE_ERRORS',
  CLONE_KEY_DATES_REQUEST: 'CLONE_KEY_DATES_REQUEST',
  CLONE_KEY_DATES_SUCCESS: 'CLONE_KEY_DATES_SUCCESS',
  CLONE_KEY_DATES_FAILURE: 'CLONE_KEY_DATES_FAILURE',
  RESET_KEY_DATES: 'RESET_KEY_DATES'
}
