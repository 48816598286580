import produce from 'immer'
import constants from 'constants/administrator'

const initialState = {
  administrator: {},
  signupFormResponse: {},
  invitationToken: '',
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_ADMINISTRATOR_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_ADMINISTRATOR_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.administrator = action.administrator
      draft.signupFormResponse = action.response
      break
    case constants.FETCH_ADMINISTRATOR_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_RESPONSES:
      draft.signupFormResponse[action.key] = action.value
      break
    case constants.UPDATE_ADMINISTRATOR:
      draft.administrator[action.key] = action.value
      break
    case constants.SAVE_ADMINISTRATOR_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_ADMINISTRATOR_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_ADMINISTRATOR_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SAVE_ADMIN_INVITATION_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_ADMIN_INVITATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_ADMIN_INVITATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.DELETE_ADMIN_INVITATION_REQUEST:
      draft.saving = true
      break
    case constants.DELETE_ADMIN_INVITATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_ADMIN_INVITATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPDATE_INVITATION_TOKEN:
      draft.invitationToken = action.token
      break
    case constants.RESET_ADMINISTRATOR:
      return initialState
  }
}, initialState)
