import produce from 'immer'
import constants from 'constants/newsletters'

const initialState = {
  newsletters: [],
  query: {
    page: 1,
    per_page: 25
  },
  filters: {
    search: '',
    not: {},
    sent_at: [],
    state: 'all'
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  meta: {
    all: 0,
    draft: 0,
    published: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_NEWSLETTERS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_NEWSLETTERS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.newsletters = action.newsletters
      draft.meta.all = action.meta.count
      draft.meta.draft = action.meta.draftCount
      draft.meta.published = action.meta.sentCount
      break
    case constants.FETCH_NEWSLETTERS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_NEWSLETTERS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_NEWSLETTERS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.RESET_NEWSLETTERS:
      return initialState
    case constants.RESET_FILTERS:
      return initialState
  }
}, initialState)
