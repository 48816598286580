export default {
  FETCH_SURVEY_REQUEST: 'FETCH_SURVEY_REQUEST',
  FETCH_SURVEY_SUCCESS: 'FETCH_SURVEY_SUCCESS',
  FETCH_SURVEY_FAILURE: 'FETCH_SURVEY_FAILURE',
  UPDATE_SURVEY: 'UPDATE_SURVEY',
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SAVE_SURVEY_REQUEST: 'SAVE_SURVEY_REQUEST',
  SAVE_SURVEY_SUCCESS: 'SAVE_SURVEY_SUCCESS',
  SAVE_SURVEY_FAILURE: 'SAVE_SURVEY_FAILURE',
  CLONE_SURVEY_REQUEST: 'CLONE_SURVEY_REQUEST',
  CLONE_SURVEY_SUCCESS: 'CLONE_SURVEY_SUCCESS',
  CLONE_SURVEY_FAILURE: 'CLONE_SURVEY_FAILURE',
  SET_DEFAULTS: 'SET_SURVEY_DEFAULTS',
  RESET_SURVEY: 'RESET_SURVEY',
  FETCH_SURVEY_PERMALINK_REQUEST: 'FETCH_SURVEY_PERMALINK_REQUEST',
  FETCH_SURVEY_PERMALINK_SUCCESS: 'FETCH_SURVEY_PERMALINK_SUCCESS',
  FETCH_SURVEY_PERMALINK_FAILURE: 'FETCH_SURVEY_PERMALINK_FAILURE',
  TOGGLE_SURVEY_CLONE: 'TOGGLE_SURVEY_CLONE'
}
