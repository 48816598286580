export default {
  FETCH_QANDA_REQUEST: 'FETCH_PINNED_QANDA_REQUEST',
  FETCH_QANDA_SUCCESS: 'FETCH_PINNED_QANDA_SUCCESS',
  FETCH_QANDA_FAILURE: 'FETCH_PINNED_QANDA_FAILURE',
  RESET_QANDA: 'RESET_PINNED_QANDA',
  PUBLISH_QANDA_REQUEST: 'PUBLISH_QANDA_REQUEST',
  PUBLISH_QANDA_SUCCESS: 'PUBLISH_QANDA_SUCCESS',
  PUBLISH_QANDA_FAILURE: 'PUBLISH_QANDA_FAILURE',
  UNPUBLISH_QANDA_REQUEST: 'UNPUBLISH_QANDA_REQUEST',
  UNPUBLISH_QANDA_SUCCESS: 'UNPUBLISH_QANDA_SUCCESS',
  UNPUBLISH_QANDA_FAILURE: 'UNPUBLISH_QANDA_FAILURE',
  ARCHIVE_QANDA_REQUEST: 'ARCHIVE_QANDA_REQUEST',
  ARCHIVE_QANDA_SUCCESS: 'ARCHIVE_QANDA_SUCCESS',
  ARCHIVE_QANDA_FAILURE: 'ARCHIVE_QANDA_FAILURE',
  UNARCHIVE_QANDA_REQUEST: 'UNARCHIVE_QANDA_REQUEST',
  UNARCHIVE_QANDA_SUCCESS: 'UNARCHIVE_QANDA_SUCCESS',
  UNARCHIVE_QANDA_FAILURE: 'UNARCHIVE_QANDA_FAILURE',
  DESTROY_QANDA_REQUEST: 'DESTROY_QANDA_REQUEST',
  DESTROY_QANDA_SUCCESS: 'DESTROY_QANDA_SUCCESS',
  DESTROY_QANDA_FAILURE: 'DESTROY_QANDA_FAILURE'
}
