export default {
  FETCH_DOCUMENTS_REQUEST: 'FETCH_DOCUMENTS_REQUEST',
  FETCH_DOCUMENTS_SUCCESS: 'FETCH_DOCUMENTS_SUCCESS',
  FETCH_DOCUMENTS_FAILURE: 'FETCH_DOCUMENTS_FAILURE',
  RESET_DOCUMENTS: 'RESET_DOCUMENTS',
  REORDER_DOCUMENT: 'REORDER_DOCUMENT',
  CREATE_DOCUMENT_REQUEST: 'CREATE_DOCUMENT_REQUEST',
  CREATE_DOCUMENT_SUCCESS: 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE: 'CREATE_DOCUMENT_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_DOCUMENT_ERRORS',
  DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
  SET_ACTIVE_DOCUMENT: 'SET_ACTIVE_DOCUMENT',
  UNSET_ACTIVE_DOCUMENT: 'UNSET_ACTIVE_DOCUMENT',
  CLONE_DOCUMENTS_REQUEST: 'CLONE_DOCUMENTS_REQUEST',
  CLONE_DOCUMENTS_SUCCESS: 'CLONE_DOCUMENTS_SUCCESS',
  CLONE_DOCUMENTS_FAILURE: 'CLONE_DOCUMENTS_FAILURE',
  SAVE_DOCUMENT_REQUEST: 'SAVE_DOCUMENT_REQUEST',
  SAVE_DOCUMENT_SUCCESS: 'SAVE_DOCUMENT_SUCCESS',
  SAVE_DOCUMENT_FAILURE: 'SAVE_DOCUMENT_FAILURE',
  RESET_DOCUMENT_ERRORS: 'RESET_DOCUMENT_ERRORS',
  SET_ACTIVE_DRAWER: 'SET_ACTIVE_DOCUMENT_DRAWER',
  SELECT_DOCUMENT: 'SELECT_DOCUMENT',
  UNSELECT_DOCUMENT: 'UNSELECT_DOCUMENT',
  SET_DOCUMENT: 'SET_DOCUMENT',
  REMOVE_DOCUMENT: 'REMOVE_DOCUMENT'
}
