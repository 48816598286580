export default {
  FETCH_QUICK_POLLS_REQUEST: 'FETCH_QUICK_POLLS_REQUEST',
  FETCH_QUICK_POLLS_SUCCESS: 'FETCH_QUICK_POLLS_SUCCESS',
  FETCH_QUICK_POLLS_FAILURE: 'FETCH_QUICK_POLLS_FAILURE',
  RESET_QUICK_POLLS: 'RESET_QUICK_POLLS',
  REORDER_QUICK_POLLS: 'REORDER_QUICK_POLLS',
  PUBLISH_QUICK_POLL_REQUEST: 'PUBLISH_QUICK_POLL_REQUEST',
  PUBLISH_QUICK_POLL_SUCCESS: 'PUBLISH_QUICK_POLL_SUCCESS',
  PUBLISH_QUICK_POLL_FAILURE: 'PUBLISH_QUICK_POLL_FAILURE',
  UNPUBLISH_QUICK_POLL_REQUEST: 'UNPUBLISH_QUICK_POLL_REQUEST',
  UNPUBLISH_QUICK_POLL_SUCCESS: 'UNPUBLISH_QUICK_POLL_SUCCESS',
  UNPUBLISH_QUICK_POLL_FAILURE: 'UNPUBLISH_QUICK_POLL_FAILURE',
  ARCHIVE_QUICK_POLL_REQUEST: 'ARCHIVE_QUICK_POLL_REQUEST',
  ARCHIVE_QUICK_POLL_SUCCESS: 'ARCHIVE_QUICK_POLL_SUCCESS',
  ARCHIVE_QUICK_POLL_FAILURE: 'ARCHIVE_QUICK_POLL_FAILURE',
  UNARCHIVE_QUICK_POLL_REQUEST: 'UNARCHIVE_QUICK_POLL_REQUEST',
  UNARCHIVE_QUICK_POLL_SUCCESS: 'UNARCHIVE_QUICK_POLL_SUCCESS',
  UNARCHIVE_QUICK_POLL_FAILURE: 'UNARCHIVE_QUICK_POLL_FAILURE',
  DESTROY_QUICK_POLL_REQUEST: 'DESTROY_QUICK_POLL_REQUEST',
  DESTROY_QUICK_POLL_SUCCESS: 'DESTROY_QUICK_POLL_SUCCESS',
  DESTROY_QUICK_POLL_FAILURE: 'DESTROY_QUICK_POLL_FAILURE'
}
