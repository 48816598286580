export default {
  FETCH_NEWSLETTER_REQUEST: 'FETCH_NEWSLETTER_REQUEST',
  FETCH_NEWSLETTER_SUCCESS: 'FETCH_NEWSLETTER_SUCCESS',
  FETCH_NEWSLETTER_FAILURE: 'FETCH_NEWSLETTER_FAILURE',
  UPDATE_NEWSLETTER: 'UPDATE_NEWSLETTER',
  UPDATE_EMAILS_STRING_NEWSLETTER: 'UPDATE_EMAILS_STRING_NEWSLETTER',
  SPLIT_EMAILS_STRING_NEWSLETTER: 'SPLIT_EMAILS_STRING_NEWSLETTER',
  SAVE_NEWSLETTER_REQUEST: 'SAVE_NEWSLETTER_REQUEST',
  SAVE_NEWSLETTER_SUCCESS: 'SAVE_NEWSLETTER_SUCCESS',
  SAVE_NEWSLETTER_FAILURE: 'SAVE_NEWSLETTER_FAILURE',
  DELETE_NEWSLETTER_REQUEST: 'DELETE_NEWSLETTER_REQUEST',
  DELETE_NEWSLETTER_SUCCESS: 'DELETE_NEWSLETTER_SUCCESS',
  DELETE_NEWSLETTER_FAILURE: 'DELETE_NEWSLETTER_FAILURE',
  SEND_NEWSLETTER_REQUEST: 'SEND_NEWSLETTER_REQUEST',
  SEND_NEWSLETTER_SUCCESS: 'SEND_NEWSLETTER_SUCCESS',
  SEND_NEWSLETTER_FAILURE: 'SEND_NEWSLETTER_FAILURE',
  PUBLISH_NEWSLETTER_REQUEST: 'PUBLISH_NEWSLETTER_REQUEST',
  PUBLISH_NEWSLETTER_SUCCESS: 'PUBLISH_NEWSLETTER_SUCCESS',
  PUBLISH_NEWSLETTER_FAILURE: 'PUBLISH_NEWSLETTER_FAILURE',
  RESET_NEWSLETTER: 'RESET_NEWSLETTER'
}
