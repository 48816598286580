import produce from 'immer'
import constants from '../constants/maps'

const initialState = {
  maps: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_MAPS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_MAPS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.maps = action.maps
      break
    case constants.FETCH_MAPS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_MAPS:
      for (const key in action.updates) {
        draft.maps[key].sequence = action.updates[key]
      }
      break
    case constants.RESET_MAPS:
      return initialState
    case constants.PUBLISH_MAP_REQUEST:
    case constants.UNPUBLISH_MAP_REQUEST:
    case constants.ARCHIVE_MAP_REQUEST:
    case constants.UNARCHIVE_MAP_REQUEST:
    case constants.DESTROY_MAP_REQUEST:
      draft.maps[action.id].transitioning = true
      break
    case constants.PUBLISH_MAP_SUCCESS:
    case constants.UNARCHIVE_MAP_SUCCESS:
      draft.maps[action.id].state = 'published'
      draft.maps[action.id].transitioning = false
      draft.maps[action.id].transitionError = ''
      break
    case constants.UNPUBLISH_MAP_SUCCESS:
      draft.maps[action.id].state = 'draft'
      draft.maps[action.id].transitioning = false
      draft.maps[action.id].transitionError = ''
      break
    case constants.ARCHIVE_MAP_SUCCESS:
      draft.maps[action.id].state = 'archived'
      draft.maps[action.id].archivalReasonMessage = action.archivalReasonMessage
      draft.maps[action.id].transitioning = false
      draft.maps[action.id].transitionError = ''
      break
    case constants.DESTROY_MAP_SUCCESS:
      delete draft.maps[action.id]
      break
    case constants.PUBLISH_MAP_FAILURE:
    case constants.UNPUBLISH_MAP_FAILURE:
    case constants.ARCHIVE_MAP_FAILURE:
    case constants.UNARCHIVE_MAP_FAILURE:
    case constants.DESTROY_MAP_FAILURE:
      draft.maps[action.id].transitioning = false
      draft.maps[action.id].transitionError = action.errorMessage
      break
  }
}, initialState)
