export default {
  FETCH_FAQS_REQUEST: 'FETCH_FAQS_REQUEST',
  FETCH_FAQS_SUCCESS: 'FETCH_FAQS_SUCCESS',
  FETCH_FAQS_FAILURE: 'FETCH_FAQS_FAILURE',
  RESET_FAQS: 'RESET_FAQS',
  REORDER_FAQ: 'REORDER_FAQ',
  CREATE_FAQ_REQUEST: 'CREATE_FAQ_REQUEST',
  CREATE_FAQ_SUCCESS: 'CREATE_FAQ_SUCCESS',
  CREATE_FAQ_FAILURE: 'CREATE_FAQ_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_FAQ_ERRORS',
  DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
  DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
  DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',
  SET_ACTIVE_FAQ: 'SET_ACTIVE_FAQ',
  UNSET_ACTIVE_FAQ: 'UNSET_ACTIVE_FAQ',
  CLONE_FAQS_REQUEST: 'CLONE_FAQS_REQUEST',
  CLONE_FAQS_SUCCESS: 'CLONE_FAQS_SUCCESS',
  CLONE_FAQS_FAILURE: 'CLONE_FAQS_FAILURE',
  SAVE_FAQ_REQUEST: 'SAVE_FAQ_REQUEST',
  SAVE_FAQ_SUCCESS: 'SAVE_FAQ_SUCCESS',
  SAVE_FAQ_FAILURE: 'SAVE_FAQ_FAILURE',
  RESET_FAQ_ERRORS: 'RESET_FAQ_ERRORS',
  SET_ACTIVE_DRAWER: 'SET_ACTIVE_FAQ_DRAWER',
  SELECT_FAQ: 'SELECT_FAQ',
  UNSELECT_FAQ: 'UNSELECT_FAQ',
  SET_FAQ: 'SET_FAQ',
  REMOVE_FAQ: 'REMOVE_FAQ'
}
