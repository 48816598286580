export default {
  FETCH_QUICK_POLLS_REQUEST: 'FETCH_WIDGET_QUICK_POLLS_REQUEST',
  FETCH_QUICK_POLLS_SUCCESS: 'FETCH_WIDGET_QUICK_POLLS_SUCCESS',
  FETCH_QUICK_POLLS_FAILURE: 'FETCH_WIDGET_QUICK_POLLS_FAILURE',
  RESET_QUICK_POLLS: 'RESET_WIDGET_QUICK_POLLS',
  SELECT_QUICK_POLL_REQUEST: 'SELECT_WIDGET_QUICK_POLL_REQUEST',
  SELECT_QUICK_POLL_SUCCESS: 'SELECT_WIDGET_QUICK_POLL_SUCCESS',
  SELECT_QUICK_POLL_FAILURE: 'SELECT_WIDGET_QUICK_POLL_FAILURE',
  UNSELECT_QUICK_POLL_REQUEST: 'UNSELECT_WIDGET_QUICK_POLL_REQUEST',
  UNSELECT_QUICK_POLL_SUCCESS: 'UNSELECT_WIDGET_QUICK_POLL_SUCCESS',
  UNSELECT_QUICK_POLL_FAILURE: 'UNSELECT_WIDGET_QUICK_POLL_FAILURE',
  PUBLISH_QUICK_POLL_WIDGET_REQUEST: 'PUBLISH_QUICK_POLL_WIDGET_REQUEST',
  PUBLISH_QUICK_POLL_WIDGET_SUCCESS: 'PUBLISH_QUICK_POLL_WIDGET_SUCCESS',
  PUBLISH_QUICK_POLL_WIDGET_FAILURE: 'PUBLISH_QUICK_POLL_WIDGET_FAILURE',
  UNPUBLISH_QUICK_POLL_WIDGET_REQUEST: 'UNPUBLISH_QUICK_POLL_WIDGET_REQUEST',
  UNPUBLISH_QUICK_POLL_WIDGET_SUCCESS: 'UNPUBLISH_QUICK_POLL_WIDGET_SUCCESS',
  UNPUBLISH_QUICK_POLL_WIDGET_FAILURE: 'UNPUBLISH_QUICK_POLL_WIDGET_FAILURE',
  ARCHIVE_QUICK_POLL_WIDGET_REQUEST: 'ARCHIVE_QUICK_POLL_WIDGET_REQUEST',
  ARCHIVE_QUICK_POLL_WIDGET_SUCCESS: 'ARCHIVE_QUICK_POLL_WIDGET_SUCCESS',
  ARCHIVE_QUICK_POLL_WIDGET_FAILURE: 'ARCHIVE_QUICK_POLL_WIDGET_FAILURE',
  UNARCHIVE_QUICK_POLL_WIDGET_REQUEST: 'UNARCHIVE_QUICK_POLL_WIDGET_REQUEST',
  UNARCHIVE_QUICK_POLL_WIDGET_SUCCESS: 'UNARCHIVE_QUICK_POLL_WIDGET_SUCCESS',
  UNARCHIVE_QUICK_POLL_WIDGET_FAILURE: 'UNARCHIVE_QUICK_POLL_WIDGET_FAILURE',
  DESTROY_QUICK_POLL_WIDGET_REQUEST: 'DESTROY_QUICK_POLL_WIDGET_REQUEST',
  DESTROY_QUICK_POLL_WIDGET_SUCCESS: 'DESTROY_QUICK_POLL_WIDGET_SUCCESS',
  DESTROY_QUICK_POLL_WIDGET_FAILURE: 'DESTROY_QUICK_POLL_WIDGET_FAILURE'
}
