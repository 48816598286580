export default {
  FETCH_SMS_INTEGRATIONS_REQUEST: 'FETCH_SMS_INTEGRATIONS_REQUEST',
  FETCH_SMS_INTEGRATIONS_SUCCESS: 'FETCH_SMS_INTEGRATIONS_SUCCESS',
  FETCH_SMS_INTEGRATIONS_FAILURE: 'FETCH_SMS_INTEGRATIONS_FAILURE',
  SAVE_SMS_INTEGRATIONS_REQUEST: 'SAVE_SMS_INTEGRATIONS_REQUEST',
  SAVE_SMS_INTEGRATIONS_SUCCESS: 'SAVE_SMS_INTEGRATIONS_SUCCESS',
  SAVE_SMS_INTEGRATIONS_FAILURE: 'SAVE_SMS_INTEGRATIONS_FAILURE',
  UPDATE_TOOL_DATA: 'UPDATE_TOOL_DATA',
  DISSOCIATE_TOOL_AND_PROJECT: 'DISSOCIATE_TOOL_AND_PROJECT',
  UPDATE_SMS_INTEGRATIONS_QUERIES: 'UPDATE_SMS_INTEGRATIONS_QUERIES',
  UPDATE_SMS_INTEGRATIONS_FILTERS: 'UPDATE_SMS_INTEGRATIONS_FILTERS'
}
