import produce from 'immer'
import constants from 'constants/newsletter'

const initialState = {
  newsletter: {},
  emailsString: '',
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  deleting: false,
  deleteError: '',
  sending: false,
  sendError: '',
  transitioning: false,
  transitionError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_NEWSLETTER_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_NEWSLETTER_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.newsletter = action.newsletter
      draft.subject = action.newsletter.subject
      draft.showLogo = action.newsletter.showLogo
      draft.showBanner = !!action.newsletter.bannerSourceUrl
      draft.bannerSourceUrl = action.newsletter.bannerSourceUrl
      break
    case constants.FETCH_NEWSLETTER_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_NEWSLETTER:
      draft.newsletter[action.key] = action.value
      draft[action.key] = action.value
      break
    case constants.UPDATE_EMAILS_STRING_NEWSLETTER:
      draft.emailsString = action.value
      break
    case constants.SPLIT_EMAILS_STRING_NEWSLETTER:
      draft.newsletter.emails = action.emails
      break
    case constants.SAVE_NEWSLETTER_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_NEWSLETTER_SUCCESS:
      draft.newsletter = action.newsletter
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_NEWSLETTER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.DELETE_NEWSLETTER_REQUEST:
      draft.deleting = true
      break
    case constants.DELETE_NEWSLETTER_SUCCESS:
      draft.deleting = false
      draft.deleteError = ''
      break
    case constants.DELETE_NEWSLETTER_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage
      break
    case constants.SEND_NEWSLETTER_REQUEST:
      draft.sending = true
      break
    case constants.SEND_NEWSLETTER_SUCCESS:
      draft.sending = false
      draft.sendError = ''
      draft.emailsString = ''
      draft.errors = {}
      break
    case constants.SEND_NEWSLETTER_FAILURE:
      draft.sending = false
      draft.sendError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.PUBLISH_NEWSLETTER_REQUEST:
      draft.transitioning = true
      break
    case constants.PUBLISH_NEWSLETTER_SUCCESS:
      draft.newsletter.state = 'sent'
      draft.transitioning = false
      draft.transitionError = ''
      break
    case constants.PUBLISH_NEWSLETTER_FAILURE:
      draft.transitioning = false
      draft.transitionError = action.errorMessage
      break
    case constants.RESET_NEWSLETTER:
      return initialState
  }
}, initialState)
