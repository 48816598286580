export default {
  FETCH_HUBS_REQUEST: 'FETCH_HUB_ADMIN_AUTHORIZED_HUBS_REQUEST',
  FETCH_HUBS_SUCCESS: 'FETCH_HUB_ADMIN_AUTHORIZED_HUBS_SUCCESS',
  FETCH_HUBS_FAILURE: 'FETCH_HUB_ADMIN_AUTHORIZED_HUBS_FAILURE',
  AUTHORIZE_HUB_REQUEST: 'AUTHORIZE_HUB_TO_HUB_ADMIN_REQUEST',
  AUTHORIZE_HUB_SUCCESS: 'AUTHORIZE_HUB_TO_HUB_ADMIN_SUCCESS',
  AUTHORIZE_HUB_FAILURE: 'AUTHORIZE_HUB_TO_HUB_ADMIN_FAILURE',
  UNAUTHORIZE_HUB_REQUEST: 'UNAUTHORIZE_HUB_TO_HUB_ADMIN_REQUEST',
  UNAUTHORIZE_HUB_SUCCESS: 'UNAUTHORIZE_HUB_TO_HUB_ADMIN_SUCCESS',
  UNAUTHORIZE_HUB_FAILURE: 'UNAUTHORIZE_HUB_TO_HUB_ADMIN_FAILURE',
  RESET_HUBS: 'RESET_HUB_ADMIN_AUTHORIZED_HUBS'
}
