export default {
  FETCH_DASHBOARD_REQUEST: 'FETCH_DASHBOARD_REQUEST',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  FETCH_DASHBOARD_FAILURE: 'FETCH_DASHBOARD_FAILURE',
  CREATE_DASHBOARD_REPORT_REQUEST: 'CREATE_DASHBOARD_REPORT_REQUEST',
  CREATE_DASHBOARD_REPORT_SUCCESS: 'CREATE_DASHBOARD_REPORT_SUCCESS',
  CREATE_DASHBOARD_REPORT_FAILURE: 'CREATE_DASHBOARD_REPORT_FAILURE',
  UPDATE_DASHBOARD_REPORT: 'UPDATE_DASHBOARD_REPORT',
  DELETE_DASHBOARD_REQUEST: 'DELETE_DASHBOARD_REQUEST',
  DELETE_DASHBOARD_SUCCESS: 'DELETE_DASHBOARD_SUCCESS',
  DELETE_DASHBOARD_FAILURE: 'DELETE_DASHBOARD_FAILURE'
}
