import produce from 'immer'
import constants from 'constants/folders'

const initialState = {
  active: '',
  folders: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FOLDERS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FOLDERS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.folders = action.folders
      break
    case constants.FETCH_FOLDERS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_FOLDER:
      draft.folders[action.id].sequence = action.sequence
      break
    case constants.CREATE_FOLDER_REQUEST:
      draft.saving = true
      break
    case constants.CREATE_FOLDER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.folders[action.folder.id] = action.folder
      break
    case constants.CREATE_FOLDER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_CREATE_ERRORS:
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.DELETE_FOLDER_REQUEST:
      draft.folders[action.id].deleting = true
      break
    case constants.DELETE_FOLDER_SUCCESS:
      delete draft.folders[action.id]
      break
    case constants.DELETE_FOLDER_FAILURE:
      draft.folders[action.id].deleting = false
      draft.folders[action.id].deleteError = action.errorMessage
      break
    case constants.SET_ACTIVE_FOLDER:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_FOLDER:
      draft.active = ''
      break
    case constants.SAVE_FOLDER_REQUEST:
      draft.folders[action.id].saving = true
      break
    case constants.SAVE_FOLDER_SUCCESS:
      draft.folders[action.id].saving = false
      draft.folders[action.id].saveError = ''
      draft.folders[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.folders[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_FOLDER_FAILURE:
      draft.folders[action.id].saving = false
      draft.folders[action.id].saveError = action.errorMessage
      draft.folders[action.id].errors = action.errors
      break
    case constants.RESET_FOLDER_ERRORS:
      draft.folders[action.id].saveError = ''
      draft.folders[action.id].errors = {}
      break
    case constants.SET_FOLDER:
      draft.folders[action.folder.id] = action.folder
      break
    case constants.RESET_FOLDERS :
      return initialState
  }
}, initialState)
