export default {
  FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST: 'FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST',
  FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS: 'FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS',
  FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE: 'FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE',
  UPDATE_PROJECT_GOV_DELIVERY_INTEGRATION: 'UPDATE_PROJECT_GOV_DELIVERY_INTEGRATION',
  SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST: 'SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST',
  SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS: 'SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS',
  SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE: 'SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE',
  RESET_PROJECT_GOV_DELIVERY_INTEGRATION: 'RESET_PROJECT_GOV_DELIVERY_INTEGRATION'
}
