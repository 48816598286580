import produce from 'immer'
import constants from 'constants/guest-book'

const initialState = {
  guestBook: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_GUEST_BOOK_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_GUEST_BOOK_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.guestBook = action.guestBook
      draft.sendAcknowledgement = action.guestBook.sendAcknowledgement
      draft.sendAcknowledgementToAdmins = action.guestBook.sendAcknowledgementToAdmins
      break
    case constants.FETCH_GUEST_BOOK_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_GUEST_BOOK:
      draft.guestBook[action.key] = action.value
      draft[action.key] = action.value
      break
    case constants.SAVE_GUEST_BOOK_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_GUEST_BOOK_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_GUEST_BOOK_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_GUEST_BOOK:
      return initialState
  }
}, initialState)
