import produce, { current } from 'immer'
import findIndex from 'lodash/findIndex'
import constants from 'constants/forum-email-templates'

const initialState = {
  forumEmailTemplates: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FORUM_EMAIL_TEMPLATES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FORUM_EMAIL_TEMPLATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.forumEmailTemplates = action.forumEmailTemplates
      break
    case constants.FETCH_FORUM_EMAIL_TEMPLATES_FAILURE:
      draft.fetching = false
      draft.errors = action.errors
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_FORUM_EMAIL_TEMPLATE:
      const index = findIndex(draft.forumEmailTemplates, s => s.id === action.id)

      draft.forumEmailTemplates[index] = {
        ...current(draft).forumEmailTemplates[index],
        ...action.attributes
      }

      break
  }
}, initialState)
