export default {
  FETCH_SURVEYS_REQUEST: 'FETCH_SURVEYS_REQUEST',
  FETCH_SURVEYS_SUCCESS: 'FETCH_SURVEYS_SUCCESS',
  FETCH_SURVEYS_FAILURE: 'FETCH_SURVEYS_FAILURE',
  UPDATE_SURVEY_FROM_LIST: 'UPDATE_SURVEY_FROM_LIST',
  RESET_SURVEYS: 'RESET_SURVEYS',
  REORDER_SURVEYS: 'REORDER_SURVEYS',
  PUBLISH_SURVEY_REQUEST: 'PUBLISH_SURVEY_REQUEST',
  PUBLISH_SURVEY_SUCCESS: 'PUBLISH_SURVEY_SUCCESS',
  PUBLISH_SURVEY_FAILURE: 'PUBLISH_SURVEY_FAILURE',
  UNPUBLISH_SURVEY_REQUEST: 'UNPUBLISH_SURVEY_REQUEST',
  UNPUBLISH_SURVEY_SUCCESS: 'UNPUBLISH_SURVEY_SUCCESS',
  UNPUBLISH_SURVEY_FAILURE: 'UNPUBLISH_SURVEY_FAILURE',
  ARCHIVE_SURVEY_REQUEST: 'ARCHIVE_SURVEY_REQUEST',
  ARCHIVE_SURVEY_SUCCESS: 'ARCHIVE_SURVEY_SUCCESS',
  ARCHIVE_SURVEY_FAILURE: 'ARCHIVE_SURVEY_FAILURE',
  UNARCHIVE_SURVEY_REQUEST: 'UNARCHIVE_SURVEY_REQUEST',
  UNARCHIVE_SURVEY_SUCCESS: 'UNARCHIVE_SURVEY_SUCCESS',
  UNARCHIVE_SURVEY_FAILURE: 'UNARCHIVE_SURVEY_FAILURE',
  DESTROY_SURVEY_REQUEST: 'DESTROY_SURVEY_REQUEST',
  DESTROY_SURVEY_SUCCESS: 'DESTROY_SURVEY_SUCCESS',
  DESTROY_SURVEY_FAILURE: 'DESTROY_SURVEY_FAILURE',
  SAVE_SCHEDULE_REQUEST: 'SAVE_SURVEY_SCHEDULE_REQUEST',
  SAVE_SCHEDULE_SUCCESS: 'SAVE_SURVEY_SCHEDULE_SUCCESS',
  SAVE_SCHEDULE_FAILURE: 'SAVE_SURVEY_SCHEDULE_FAILURE',
  DESTROY_SCHEDULE_REQUEST: 'DESTROY_SURVEY_SCHEDULE_REQUEST',
  DESTROY_SCHEDULE_SUCCESS: 'DESTROY_SURVEY_SCHEDULE_SUCCESS',
  DESTROY_SCHEDULE_FAILURE: 'DESTROY_SURVEY_SCHEDULE_FAILURE',
  RESET_SCHEDULE_ERRORS: 'RESET_SURVEY_SCHEDULE_ERRORS'
}
