import produce from 'immer'

import constants from '../constants/project'

const initialState = {
  project: {
    parentId: ''
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  uploadingBanner: false,
  bannerUploadError: '',
  uploadingImage: false,
  imageUploadError: '',
  transitioning: false,
  transitionError: '',
  isProjectSettingsVisible: false,
  settingsActiveTab: 'visibility',
  permalink: '',
  generatingPermalink: false,
  generatePermalinkError: '',
  hasUnsavedChanges: false
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_PROJECT_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.project = action.project
      draft.permalink = action.project.permalink

      break

    case constants.FETCH_PROJECT_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.UPDATE_PROJECT:
      draft.project[action.key] = action.value
      break

    case constants.TRACK_CHANGES:
      draft.hasUnsavedChanges = action.value
      break

    case constants.UPDATE_CROPPED_IMAGE:
      draft.project[action.key] = action.value

      break

    case constants.TOGGLE_IMAGE_SETTINGS:
      draft.activeImageTab = action.activeImageTab

      break

    case constants.SAVE_PROJECT_REQUEST:
    case constants.CLONE_PROJECT_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_PROJECT_SUCCESS:
    case constants.CLONE_PROJECT_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.project = action.project
      break

    case constants.CLONE_PROJECT_FAILURE:
    case constants.SAVE_PROJECT_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors

      break

    case constants.RESET_PROJECT:
      return initialState

    case constants.UPLOAD_BANNER_REQUEST:
      draft.uploadingBanner = true

      break

    case constants.UPLOAD_BANNER_SUCCESS:
      draft.uploadingBanner = false
      draft.bannerUploadError = ''
      draft.project.bannerUrl = action.imageUrl

      break

    case constants.UPLOAD_BANNER_FAILURE:
      draft.uploadingBanner = false
      draft.bannerUploadError = action.errorMessage

      break

    case constants.UPLOAD_IMAGE_REQUEST:
      draft.uploadingImage = true

      break

    case constants.UPLOAD_IMAGE_SUCCESS:
      draft.uploadingImage = false
      draft.imageUploadError = ''
      draft.project.imageUrl = action.imageUrl
      break

    case constants.UPLOAD_IMAGE_FAILURE:
      draft.uploadingImage = false
      draft.imageUploadError = action.errorMessage

      break

    case constants.PUBLISH_PROJECT_REQUEST:
    case constants.UNPUBLISH_PROJECT_REQUEST:
    case constants.ARCHIVE_PROJECT_REQUEST:
    case constants.UNARCHIVE_PROJECT_REQUEST:
    case constants.HIDE_PROJECT_REQUEST:
    case constants.UNHIDE_PROJECT_REQUEST:
    case constants.DESTROY_PROJECT_REQUEST:
      draft.transitioning = true

      break

    case constants.PUBLISH_PROJECT_SUCCESS:
      draft.project.state = 'published'
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.UNPUBLISH_PROJECT_SUCCESS:
      draft.project.state = 'draft'
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.ARCHIVE_PROJECT_SUCCESS:
      draft.project.state = !action.hide ? 'archived' : 'hidden'
      draft.project.archivalReasonMessage = action.archivalReasonMessage
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.UNARCHIVE_PROJECT_SUCCESS:
      draft.project.state = 'published'
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.HIDE_PROJECT_SUCCESS:
      draft.project.state = 'hidden'
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.UNHIDE_PROJECT_SUCCESS:
      draft.project.state = 'archived'
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.DESTROY_PROJECT_SUCCESS:
      draft.transitioning = false
      draft.transitionError = ''

      break

    case constants.PUBLISH_PROJECT_FAILURE:
      draft.transitioning = false
      draft.transitionError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.UNPUBLISH_PROJECT_FAILURE:
    case constants.ARCHIVE_PROJECT_FAILURE:
    case constants.UNARCHIVE_PROJECT_FAILURE:
    case constants.HIDE_PROJECT_FAILURE:
    case constants.UNHIDE_PROJECT_FAILURE:
    case constants.DESTROY_PROJECT_FAILURE:
      draft.transitioning = false
      draft.transitionError = action.errorMessage

      break

    case constants.RESET_ERRORS:
      draft.errors = {}

      break

    case constants.TOGGLE_PROJECT_SETTINGS:
      draft.isProjectSettingsVisible = action.toggle
      draft.settingsActiveTab = action.settingsActiveTab

      break

    case constants.FETCH_PROJECT_PERMALINK_REQUEST:
      draft.generatingPermalink = true
      draft.generatePermalinkError = ''

      break

    case constants.FETCH_PROJECT_PERMALINK_SUCCESS:
      draft.generatingPermalink = false
      draft.permalink = action.value
      draft.project.permalink = action.value
      draft.project.holdPostRequest = false

      break

    case constants.FETCH_PROJECT_PERMALINK_FAILURE:
      draft.generatingPermalink = false
      draft.generatePermalinkError = action.errorMessage

      break
  }
}, initialState)
