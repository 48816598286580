import produce from 'immer'
import constants from 'constants/widgets'

const initialState = {
  active: '',
  widgets: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  creating: false,
  createError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_WIDGETS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_WIDGETS_SUCCESS:
      draft.widgets = action.widgets
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_WIDGETS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.SET_ACTIVE_WIDGET:
      draft.active = action.id
      break
    case constants.UNSET_ACTIVE_WIDGET:
      draft.active = ''
      break
    case constants.SAVE_WIDGET_REQUEST:
      draft.widgets[action.id].saving = true
      break
    case constants.SAVE_WIDGET_SUCCESS:
      draft.widgets[action.id].saving = false
      draft.widgets[action.id].saveError = ''
      draft.widgets[action.id].errors = {};
      (() => {
        for (const key in action.updates) {
          draft.widgets[action.id][key] = action.updates[key]
        }
      })()
      break
    case constants.SAVE_WIDGET_FAILURE:
      draft.widgets[action.id].saving = false
      draft.widgets[action.id].saveError = action.errorMessage
      draft.widgets[action.id].errors = action.errors
      break
    case constants.DELETE_WIDGET_REQUEST:
      draft.widgets[action.id].deleting = true
      break
    case constants.DELETE_WIDGET_SUCCESS:
      delete draft.widgets[action.id]
      break
    case constants.DELETE_WIDGET_FAILURE:
      draft.widgets[action.id].deleting = false
      draft.widgets[action.id].deleteError = action.errorMessage
      break
    case constants.REORDER_WIDGETS:
      (() => {
        for (let key in action.updates) {
          draft.widgets[key].sequence = action.updates[key]
        }
      })()
      break
    case constants.CREATE_WIDGET_REQUEST:
      draft.creating = true
      break
    case constants.CREATE_WIDGET_SUCCESS:
      draft.creating = false
      draft.createError = ''
      draft.errors = {}
      draft.widgets[action.widget.id] = action.widget
      break
    case constants.CREATE_WIDGET_FAILURE:
      draft.creating = false
      draft.createError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SET_RESOURCE_COUNT:
      draft.widgets[draft.active].resourceCount = action.count
      break
    case constants.RESET_WIDGETS:
      return initialState
  }
}, initialState)
