import produce from 'immer'
import constants from 'constants/footer-links'

const initialState = {
  footerLinks: [],
  queries: {},
  filters: {
    search: 'footer',
    sort: { sequence: 'asc' }
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  footerLink: {
    id: '',
    title: '',
    linkableId: '',
    linkableTitle: '',
    linkableType: 'Project',
    linkableUrl: '',
    sequence: '',
    region: 'footer',
    createdAt: ''
  },
  saving: false,
  saveError: '',
  errors: {},
  deleting: false,
  deleteError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FOOTER_LINKS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FOOTER_LINKS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.footerLinks = action.footerLinks
      break
    case constants.FETCH_FOOTER_LINKS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.SORT_FOOTER_LINKS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      break
    case constants.SORT_FOOTER_LINKS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.FETCH_FOOTER_LINK_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FOOTER_LINK_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.footerLink = action.footerLink
      break
    case constants.FETCH_FOOTER_LINK_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_FOOTER_LINK:
      draft.footerLink[action.key] = action.value
      break
    case constants.SAVE_FOOTER_LINK_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_FOOTER_LINK_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_FOOTER_LINK_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_FOOTER_LINK:
      draft.footerLink = {
        id: '',
        title: '',
        linkableId: '',
        linkableTitle: '',
        linkableType: 'Project',
        linkableUrl: '',
        sequence: '',
        region: 'footer',
        createdAt: ''
      }
      draft.errors = {}
      draft.saveError = ''
      break
    case constants.UPDATE_FOOTER_LINK_ERROR:
      draft.errors[action.key] = action.value
      break
  }
}, initialState)
