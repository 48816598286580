import produce from 'immer'
import constants from 'constants/participant-segments'

const initialState = {
  segments: {},
  search: '',
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SEGMENTS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SEGMENTS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.segments = action.segments
      break
    case constants.FETCH_SEGMENTS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.TOGGLE_SEGMENT:
      draft.segments[action.id].selected = !draft.segments[action.id].selected
      break
    case constants.TOGGLE_ALL_SEGMENTS:
      for (let key in draft.segments) {
        if (!draft.segments[key].hidden) {
          // All the filtered out segments should not be changed, on Select All Checked
          draft.segments[key].selected = action.selected
        }
      }
      break
    case constants.FILTER_BY_NAME:
      draft.search = action.search
      for (let key in draft.segments) {
        let segment = draft.segments[key]
        segment['hidden'] = segment.name.toLowerCase().indexOf(action.search.toLowerCase()) < 0
      }
      break
    case constants.SELECT_SEGMENTS:
      action.segments.forEach((id) => {
        draft.segments[id].selected = true
      })
      break
    case constants.RESET_SEGMENTS:
      return initialState
  }
}, initialState)
