import produce from 'immer'
import constants from '../constants/hub-admin-authorized-hubs'

const initialState = {
  hubs: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_HUBS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_HUBS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.hubs = action.hubs
      break
    case constants.FETCH_HUBS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.AUTHORIZE_HUB_REQUEST:
    case constants.UNAUTHORIZE_HUB_REQUEST:
      draft.saving = true
      break
    case constants.AUTHORIZE_HUB_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.hubs[action.id] = { id: action.id }
      break
    case constants.UNAUTHORIZE_HUB_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      delete draft.hubs[action.id]
      break
    case constants.AUTHORIZE_HUB_FAILURE:
    case constants.UNAUTHORIZE_HUB_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.RESET_HUBS:
      return initialState
  }
}, initialState)
