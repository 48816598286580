import produce from 'immer'
import constants from '../constants/quick-polls'

const initialState = {
  quickPolls: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QUICK_POLLS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QUICK_POLLS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.quickPolls = action.quickPolls
      break
    case constants.FETCH_QUICK_POLLS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_QUICK_POLLS:
      return initialState
    case constants.REORDER_QUICK_POLLS:
      for (const key in action.updates) {
        draft.quickPolls[key].sequence = action.updates[key]
      }
      break
    case constants.PUBLISH_QUICK_POLL_REQUEST:
    case constants.UNPUBLISH_QUICK_POLL_REQUEST:
    case constants.ARCHIVE_QUICK_POLL_REQUEST:
    case constants.UNARCHIVE_QUICK_POLL_REQUEST:
    case constants.DESTROY_QUICK_POLL_REQUEST:
      draft.quickPolls[action.id].transitioning = true
      break
    case constants.PUBLISH_QUICK_POLL_SUCCESS:
    case constants.UNARCHIVE_QUICK_POLL_SUCCESS:
      draft.quickPolls[action.id].state = 'published'
      draft.quickPolls[action.id].transitioning = false
      draft.quickPolls[action.id].transitionError = ''
      break
    case constants.UNPUBLISH_QUICK_POLL_SUCCESS:
      draft.quickPolls[action.id].state = 'draft'
      draft.quickPolls[action.id].transitioning = false
      draft.quickPolls[action.id].transitionError = ''
      break
    case constants.ARCHIVE_QUICK_POLL_SUCCESS:
      draft.quickPolls[action.id].state = 'archived'
      draft.quickPolls[action.id].archivalReasonMessage = action.archivalReasonMessage
      draft.quickPolls[action.id].transitioning = false
      draft.quickPolls[action.id].transitionError = ''
      break
    case constants.DESTROY_QUICK_POLL_SUCCESS:
      delete draft.quickPolls[action.id]
      break
    case constants.PUBLISH_QUICK_POLL_FAILURE:
    case constants.UNPUBLISH_QUICK_POLL_FAILURE:
    case constants.ARCHIVE_QUICK_POLL_FAILURE:
    case constants.UNARCHIVE_QUICK_POLL_FAILURE:
    case constants.DESTROY_QUICK_POLL_FAILURE:
      draft.quickPolls[action.id].transitioning = false
      draft.quickPolls[action.id].transitionError = action.errorMessage
      break
  }
}, initialState)
