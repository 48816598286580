export default {
  FETCH_BANNER_REQUEST: 'FETCH_REGISTER_BANNER_REQUEST',
  FETCH_BANNER_SUCCESS: 'FETCH_REGISTER_BANNER_SUCCESS',
  FETCH_BANNER_FAILURE: 'FETCH_REGISTER_BANNER_FAILURE',
  SAVE_BANNER_REQUEST: 'SAVE_REGISTER_BANNER_REQUEST',
  SAVE_BANNER_SUCCESS: 'SAVE_REGISTER_BANNER_SUCCESS',
  SAVE_BANNER_FAILURE: 'SAVE_REGISTER_BANNER_FAILURE',
  RESET_BANNER: 'RESET_REGISTER_BANNER',
  CREATE_BANNER_REQUEST: 'CREATE_REGISTER_BANNER_REQUEST',
  CREATE_BANNER_SUCCESS: 'CREATE_REGISTER_BANNER_SUCCESS',
  CREATE_BANNER_FAILURE: 'CREATE_REGISTER_BANNER_FAILURE'
}
