export default {
  FETCH_SETTINGS_REQUEST: 'FETCH_SITE_SETTINGS_REQUEST',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SITE_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE: 'FETCH_SITE_SETTINGS_FAILURE',
  UPDATE_SETTINGS: 'UPDATE_SITE_SETTINGS',
  SAVE_SETTINGS_REQUEST: 'SAVE_SITE_SETTINGS_REQUEST',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SITE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILURE: 'SAVE_SITE_SETTINGS_FAILURE',
  UPDATE_SETTINGS_ERROR: 'UPDATE_SETTINGS_ERROR',
  RESET_SETTINGS: 'RESET_SETTINGS'
}
