import produce, { current } from 'immer'
import findIndex from 'lodash/findIndex'
import constants from 'constants/qanda-email-templates'

const initialState = {
  qandaEmailTemplates: [],
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QANDA_EMAIL_TEMPLATES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QANDA_EMAIL_TEMPLATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.qandaEmailTemplates = action.qandaEmailTemplates
      break
    case constants.FETCH_QANDA_EMAIL_TEMPLATES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_QANDA_EMAIL_TEMPLATE:
      const index = findIndex(draft.qandaEmailTemplates, s => s.id === action.id)

      draft.qandaEmailTemplates[index] = {
        ...current(draft).qandaEmailTemplates[index],
        ...action.attributes
      }
      break
  }
}, initialState)
