import produce from 'immer'
import constants from 'constants/layout'

const initialState = {
  layout: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_LAYOUT_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_LAYOUT_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.layout = action.layout

      break

    case constants.FETCH_LAYOUT_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.UPDATE_LAYOUT:
      draft.layout[action.key] = action.value

      break

    case constants.SAVE_LAYOUT_REQUEST:
    case constants.RESET_LAYOUT_REQUEST:
    case constants.RESET_LAYOUT_COLOR_REQUEST:
      draft.saving = true

      break

    case constants.SAVE_LAYOUT_SUCCESS:
    case constants.RESET_LAYOUT_SUCCESS:
    case constants.RESET_LAYOUT_COLOR_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}

      break

    case constants.SAVE_LAYOUT_FAILURE:
    case constants.RESET_LAYOUT_FAILURE:
    case constants.RESET_LAYOUT_COLOR_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors

      break
  }
}, initialState)
