import produce from 'immer'

import constants from 'constants/navigations'

const initialState = {
  filters: {
    state: 'header',
    formModalVisible: false
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.UPDATE_NAVIGATIONS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }

      break
  }
}, initialState)
