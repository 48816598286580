import produce from 'immer'
import constants from '../constants/project-tag'

const initialState = {
  tags: [],
  fetchError: '',
  fetching: false,
  doneFetching: false
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_TAGS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECT_TAGS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.tags = action.tags
      break
    case constants.FETCH_PROJECT_TAGS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
