import produce from 'immer'
import constants from 'constants/blog-post-emails'

const initialState = {
  blogPostEmails: [],
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BLOG_POST_EMAILS_REQUEST:
      draft.fetching = true

      break

    case constants.FETCH_BLOG_POST_EMAILS_SUCCESS:
      draft.blogPostEmails = action.blogPostEmails
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true

      break

    case constants.FETCH_BLOG_POST_EMAILS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage

      break

    case constants.RESET_BLOG_POST_EMAILS:
      return initialState
  }
}, initialState)
