import produce from 'immer'
import constants from 'constants/newsletter-stats'

const initialState = {
  stats: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_NEWSLETTER_STATS_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      break
    case constants.FETCH_NEWSLETTER_STATS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.stats = action.stats
      break
    case constants.FETCH_NEWSLETTER_STATS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
