import constants from 'constants/token'
import produce from 'immer'

const initialState = {
  token: '',
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_TOKEN_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_TOKEN_SUCCESS:
      draft.fetching = false
      draft.doneFetching = 'success'
      draft.fetchError = ''
      draft.token = action.token
      break
    case constants.FETCH_TOKEN_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_TOKEN:
      return initialState
  }
}, initialState)
