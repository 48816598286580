import produce from 'immer'
import constants from 'constants/google-translate-integration'

const initialState = {
  googleTranslateIntegration: {},
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.googleTranslateIntegration = action.googleTranslateIntegration
      break

    case constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_GOOGLE_TRANSLATE_INTEGRATION:
      draft.googleTranslateIntegration[action.key] = action.value
      break

    case constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break

    case constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.RESET_GOOGLE_TRANSLATE_INTEGRATION:
      return initialState
  }
}, initialState)
