import produce from 'immer'
import constants from '../constants/blog-post'

const initialState = {
  blogPost: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  uploadingImage: false,
  imageUploadError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_BLOG_POST_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_BLOG_POST_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.blogPost = action.blogPost
      break
    case constants.FETCH_BLOG_POST_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_BLOG_POST:
      draft.blogPost[action.key] = action.value
      break
    case constants.SAVE_BLOG_POST_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_BLOG_POST_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_BLOG_POST_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.UPLOAD_IMAGE_REQUEST:
      draft.uploadingImage = true
      break
    case constants.UPLOAD_IMAGE_SUCCESS:
      draft.uploadingImage = false
      draft.imageUploadError = ''
      draft.blogPost.imageUrl = action.imageUrl
      break
    case constants.UPLOAD_IMAGE_FAILURE:
      draft.uploadingImage = false
      draft.imageUploadError = action.errorMessage
      break
    case constants.FETCH_BLOG_POST_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break
    case constants.FETCH_BLOG_POST_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.blogPost.permalink = action.permalink
      draft.blogPost.holdPostRequest = false
      break
    case constants.FETCH_BLOG_POST_PERMALINK_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      draft.blogPost.permalink = ''
      break
    case constants.RESET_BLOG_POST:
      return initialState

    case constants.SET_BLOG_POST:
      draft.blogPost = action.blogPost

      break
  }
}, initialState)
