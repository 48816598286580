import produce from 'immer'
import constants from 'constants/qanda'

const initialState = {
  qanda: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  currentTab: 'details'
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_QANDA_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_QANDA_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.qanda = action.qanda
      break
    case constants.FETCH_QANDA_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_QANDA:
      draft.qanda[action.key] = action.value
      break
    case constants.SAVE_QANDA_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_QANDA_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_QANDA_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_QANDA:
      return initialState
    case constants.SET_CURRENT_TAB:
      draft.currentTab = action.currentTab
      break
  }
}, initialState)
