import produce from 'immer'
import constants from 'constants/responses'

const initialState = {
  signupResponses: {},
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SIGNUP_RESPONSE_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_SIGNUP_RESPONSE_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.signupResponses = action.signUpResponse
      break
    case constants.FETCH_SIGNUP_RESPONSE_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.RESET_SIGNUP_RESPONSE:
      return initialState
  }
}, initialState)
