import produce from 'immer'
import constants from 'constants/hub'

const initialState = {
  hub: {
    visibilityMode: 'Public'
  },
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_HUB_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_HUB_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.hub = action.hub
      break
    case constants.FETCH_HUB_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_HUB:
      draft.hub[action.key] = action.value
      break
    case constants.SAVE_HUB_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_HUB_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.hub = action.hub
      break
    case constants.SAVE_HUB_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_HUB:
      return initialState

    case constants.FETCH_HUB_PERMALINK_REQUEST:
      draft.fetching = true
      draft.fetchError = ''
      break

    case constants.FETCH_HUB_PERMALINK_SUCCESS:
      draft.fetching = false
      draft.hub.permalink = action.permalink
      draft.fetchError = ''
      break

    case constants.FETCH_HUB_PERMALINK_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.DELETE_HUB_REQUEST:
      draft.deleting = true
      break
    case constants.DELETE_HUB_SUCCESS:
      delete draft.hub[action.id]
      break
    case constants.DELETE_HUB_FAILURE:
      draft.deleting = false
      draft.deleteError = action.errorMessage
      break
    case constants.AUTHORIZE_HUB_ADMIN_REQUEST:
    case constants.UNAUTHORIZE_HUB_ADMIN_REQUEST:
      draft.saving = true
      break
    case constants.AUTHORIZE_HUB_ADMIN_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.hub.userIds = action.hub.userIds
      break
    case constants.UNAUTHORIZE_HUB_ADMIN_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      delete draft.hub.userIds[action.id]
      break
    case constants.AUTHORIZE_HUB_ADMIN_FAILURE:
    case constants.UNAUTHORIZE_HUB_ADMIN_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.FETCH_HUB_ADMIN_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_HUB_ADMIN_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.hub.userIds = action.hub
      break
    case constants.FETCH_HUB_ADMIN_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
