import produce from 'immer'
import constants from 'constants/opencities-integration'

const initialState = {
  opencitiesIntegration: {},
  opencitiesMeta: {},
  success: false,
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_OPENCITIES_INTEGRATION_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_OPENCITIES_INTEGRATION_SUCCESS:
      draft.fetchError = ''
      draft.fetching = false
      draft.opencitiesIntegration = { ...action.opencitiesIntegration[0] }
      draft.opencitiesMeta = { ...action.opencitiesIntegration.meta }
      break

    case constants.FETCH_OPENCITIES_INTEGRATION_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_OPENCITIES_INTEGRATION:
      draft.opencitiesIntegration[action.key] = action.value
      break

    case constants.SAVE_OPENCITIES_INTEGRATION_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_OPENCITIES_INTEGRATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break

    case constants.SAVE_OPENCITIES_INTEGRATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.VERIFY_OPENCITIES_INTEGRATION_REQUEST:
      draft.saving = true
      break

    case constants.VERIFY_OPENCITIES_INTEGRATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.success = action.integration.success
      break

    case constants.VERIFY_OPENCITIES_INTEGRATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.RESET_OPENCITIES_INTEGRATION:
      return initialState
  }
}, initialState)
