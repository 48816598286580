export default {
  FETCH_AUDIT_TRAILS_REQUEST: 'FETCH_AUDIT_TRAILS_REQUEST',
  FETCH_AUDIT_TRAILS_SUCCESS: 'FETCH_AUDIT_TRAILS_SUCCESS',
  FETCH_AUDIT_TRAILS_FAILURE: 'FETCH_AUDIT_TRAILS_FAILURE',
  RESET_AUDIT_TRAILS: 'RESET_AUDIT_TRAILS',
  UPDATE_AUDIT_TRAILS_QUERY: 'UPDATE_AUDIT_TRAILS_QUERY',
  UPDATE_AUDIT_TRAILS_FILTERS: 'UPDATE_AUDIT_TRAILS_FILTERS',
  UPDATE_ADVANCED_FILTER_VISIBILITY: 'UPDATE_ADVANCED_FILTER_VISIBILITY',
  UPDATE_DATE_RANGE_FILTER_VISIBILITY: 'UPDATE_DATE_RANGE_FILTER_VISIBILITY'
}
