import produce from 'immer'
import constants from 'constants/map'

const initialState = {
  view: 'default',
  map: { style: 'arcgis-topographic' } ,
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_MAP_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_MAP_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.saveError = ''
      draft.errors = {}
      draft.map = action.map
      draft.name = action.map.name
      draft.permalink = action.map.permalink
      draft.imageVisibility = action.map.imageVisibility
      draft.showAddress = action.map.showAddress
      draft.allowUnverifiedParticipation = action.map.allowUnverifiedParticipation
      draft.optionalComment = action.map.optionalComment
      draft.mapboxglEnabled = action.map.mapboxglEnabled
      draft.restrictMarkerCreation = action.map.restrictMarkerCreation
      draft.hideSurveyResponse = action.map.hideSurveyResponse
      draft.allowMarkerVoting = action.map.allowMarkerVoting
      break
    case constants.FETCH_MAP_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_MAP:
      draft[action.key] = action.value
      draft.map[action.key] = action.value
      break
    case constants.SAVE_MAP_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_MAP_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      for (const key in action.updates) {
        draft.map[key] = action.updates[key]
      }
      break
    case constants.SAVE_MAP_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.SET_VIEW:
      draft.view = action.view
      break
    case constants.RESET_MAP:
      return initialState
    case constants.FETCH_MAP_PERMALINK_REQUEST:
      draft.fetchError = ''
      break
    case constants.FETCH_MAP_PERMALINK_SUCCESS:
      draft.map.permalink = action.permalink
      draft.map.holdPostRequest = false
      draft.holdPostRequest = false
      break
    case constants.FETCH_MAP_PERMALINK_FAILURE:
      draft.fetchError = action.errorMessage
      draft.errors = action.errors
      break
  }
}, initialState)
