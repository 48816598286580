export default {
  FETCH_VIDEOS_REQUEST: 'FETCH_VIDEOS_REQUEST',
  FETCH_VIDEOS_SUCCESS: 'FETCH_VIDEOS_SUCCESS',
  FETCH_VIDEOS_FAILURE: 'FETCH_VIDEOS_FAILURE',
  CREATE_VIDEO_REQUEST: 'CREATE_VIDEO_REQUEST',
  CREATE_VIDEO_SUCCESS: 'CREATE_VIDEO_SUCCESS',
  CREATE_VIDEO_FAILURE: 'CREATE_VIDEO_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_VIDEOS_ERRORS',
  DELETE_VIDEO_REQUEST: 'DELETE_VIDEO_REQUEST',
  DELETE_VIDEO_SUCCESS: 'DELETE_VIDEO_SUCCESS',
  DELETE_VIDEO_FAILURE: 'DELETE_VIDEO_FAILURE',
  SAVE_VIDEO_REQUEST: 'SAVE_VIDEO_REQUEST',
  SAVE_VIDEO_SUCCESS: 'SAVE_VIDEO_SUCCESS',
  SAVE_VIDEO_FAILURE: 'SAVE_VIDEO_FAILURE',
  REORDER_VIDEOS: 'REORDER_VIDEOS',
  SET_ACTIVE_VIDEO: 'SET_ACTIVE_VIDEO',
  UNSET_ACTIVE_VIDEO: 'UNSET_ACTIVE_VIDEO',
  RESET_VIDEO_ERRORS: 'RESET_VIDEO_ERRORS',
  CLONE_VIDEOS_REQUEST: 'CLONE_VIDEOS_REQUEST',
  CLONE_VIDEOS_SUCCESS: 'CLONE_VIDEOS_SUCCESS',
  CLONE_VIDEOS_FAILURE: 'CLONE_VIDEOS_FAILURE',
  RESET_VIDEOS: 'RESET_VIDEOS'
}
