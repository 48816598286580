export default {
  FETCH_SITE_REQUEST: 'FETCH_SITE_REQUEST',
  FETCH_SITE_SUCCESS: 'FETCH_SITE_SUCCESS',
  FETCH_SITE_FAILURE: 'FETCH_SITE_FAILURE',
  SET_FILTER: 'SET_SITE_SETTINGS_FILTER',
  UPDATE_SITE: 'UPDATE_SITE',
  SAVE_SITE_REQUEST: 'SAVE_SITE_REQUEST',
  SAVE_SITE_SUCCESS: 'SAVE_SITE_SUCCESS',
  SAVE_SITE_FAILURE: 'SAVE_SITE_FAILURE',
  LAUNCH_SITE_REQUEST: 'LAUNCH_SITE_REQUEST',
  LAUNCH_SITE_SUCCESS: 'LAUNCH_SITE_SUCCESS',
  LAUNCH_SITE_FAILURE: 'LAUNCH_SITE_FAILURE',
  LOCK_SITE_REQUEST: 'LOCK_SITE_REQUEST',
  LOCK_SITE_SUCCESS: 'LOCK_SITE_SUCCESS',
  LOCK_SITE_FAILURE: 'LOCK_SITE_FAILURE',
  UNLOCK_SITE_REQUEST: 'UNLOCK_SITE_REQUEST',
  UNLOCK_SITE_SUCCESS: 'UNLOCK_SITE_SUCCESS',
  UNLOCK_SITE_FAILURE: 'UNLOCK_SITE_FAILURE',
  LOCK_STATUS_REQUEST: 'LOCK_STATUS_REQUEST',
  LOCK_STATUS_SUCCESS: 'LOCK_STATUS_SUCCESS',
  LOCK_STATUS_FAILURE: 'LOCK_STATUS_FAILURE'
}
