export default {
  FETCH_MAP_REQUEST: 'FETCH_MAP_REQUEST',
  FETCH_MAP_SUCCESS: 'FETCH_MAP_SUCCESS',
  FETCH_MAP_FAILURE: 'FETCH_MAP_FAILURE',
  UPDATE_MAP: 'UPDATE_MAP',
  SAVE_MAP_REQUEST: 'SAVE_MAP_REQUEST',
  SAVE_MAP_SUCCESS: 'SAVE_MAP_SUCCESS',
  SAVE_MAP_FAILURE: 'SAVE_MAP_FAILURE',
  RESET_MAP: 'RESET_MAP',
  SET_VIEW: 'SET_MAP_VIEW',
  FETCH_MAP_PERMALINK_REQUEST: 'FETCH_MAP_PERMALINK_REQUEST',
  FETCH_MAP_PERMALINK_SUCCESS: 'FETCH_MAP_PERMALINK_SUCCESS',
  FETCH_MAP_PERMALINK_FAILURE: 'FETCH_MAP_PERMALINK_FAILURE'
}
