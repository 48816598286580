export default {
  FETCH_FORUMS_REQUEST: 'FETCH_FORUMS_REQUEST',
  FETCH_FORUMS_SUCCESS: 'FETCH_FORUMS_SUCCESS',
  FETCH_FORUMS_FAILURE: 'FETCH_FORUMS_FAILURE',
  RESET_FORUMS: 'RESET_FORUMS',
  REORDER_FORUMS: 'REORDER_FORUMS',
  PUBLISH_FORUM_REQUEST: 'PUBLISH_FORUM_REQUEST',
  PUBLISH_FORUM_SUCCESS: 'PUBLISH_FORUM_SUCCESS',
  PUBLISH_FORUM_FAILURE: 'PUBLISH_FORUM_FAILURE',
  UNPUBLISH_FORUM_REQUEST: 'UNPUBLISH_FORUM_REQUEST',
  UNPUBLISH_FORUM_SUCCESS: 'UNPUBLISH_FORUM_SUCCESS',
  UNPUBLISH_FORUM_FAILURE: 'UNPUBLISH_FORUM_FAILURE',
  ARCHIVE_FORUM_REQUEST: 'ARCHIVE_FORUM_REQUEST',
  ARCHIVE_FORUM_SUCCESS: 'ARCHIVE_FORUM_SUCCESS',
  ARCHIVE_FORUM_FAILURE: 'ARCHIVE_FORUM_FAILURE',
  UNARCHIVE_FORUM_REQUEST: 'UNARCHIVE_FORUM_REQUEST',
  UNARCHIVE_FORUM_SUCCESS: 'UNARCHIVE_FORUM_SUCCESS',
  UNARCHIVE_FORUM_FAILURE: 'UNARCHIVE_FORUM_FAILURE',
  DESTROY_FORUM_REQUEST: 'DESTROY_FORUM_REQUEST',
  DESTROY_FORUM_SUCCESS: 'DESTROY_FORUM_SUCCESS',
  DESTROY_FORUM_FAILURE: 'DESTROY_FORUM_FAILURE'
}
