import produce from 'immer'
import constants from '../constants/forums'

const initialState = {
  forums: {},
  advancedDrawerVisible: false,
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_FORUMS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_FORUMS_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.forums = action.forums
      draft.meta = action.meta
      break
    case constants.FETCH_FORUMS_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.REORDER_FORUMS:
      for (const key in action.updates) {
        draft.forums[key].sequence = action.updates[key]
      }
      break
    case constants.RESET_FORUMS:
      return initialState
    case constants.PUBLISH_FORUM_REQUEST:
    case constants.UNPUBLISH_FORUM_REQUEST:
    case constants.ARCHIVE_FORUM_REQUEST:
    case constants.UNARCHIVE_FORUM_REQUEST:
    case constants.DESTROY_FORUM_REQUEST:
      draft.forums[action.id].transitioning = true
      break
    case constants.PUBLISH_FORUM_SUCCESS:
    case constants.UNARCHIVE_FORUM_SUCCESS:
      draft.forums[action.id].state = 'published'
      draft.forums[action.id].transitioning = false
      draft.forums[action.id].transitionError = ''
      break
    case constants.UNPUBLISH_FORUM_SUCCESS:
      draft.forums[action.id].state = 'draft'
      draft.forums[action.id].transitioning = false
      draft.forums[action.id].transitionError = ''
      break
    case constants.ARCHIVE_FORUM_SUCCESS:
      draft.forums[action.id].state = 'archived'
      draft.forums[action.id].archivalReasonMessage = action.archivalReasonMessage
      draft.forums[action.id].transitioning = false
      draft.forums[action.id].transitionError = ''
      break
    case constants.DESTROY_FORUM_SUCCESS:
      delete draft.forums[action.id]
      break
    case constants.PUBLISH_FORUM_FAILURE:
    case constants.UNPUBLISH_FORUM_FAILURE:
    case constants.ARCHIVE_FORUM_FAILURE:
    case constants.UNARCHIVE_FORUM_FAILURE:
    case constants.DESTROY_FORUM_FAILURE:
      draft.forums[action.id].transitioning = false
      draft.forums[action.id].transitionError = action.errorMessage
      break
  }
}, initialState)
