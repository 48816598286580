export default {
  FETCH_DEDICATED_NUMBER_REQUEST: 'FETCH_DEDICATED_NUMBER_REQUEST',
  FETCH_DEDICATED_NUMBER_SUCCESS: 'FETCH_DEDICATED_NUMBER_SUCCESS',
  FETCH_DEDICATED_NUMBER_FAILURE: 'FETCH_DEDICATED_NUMBER_FAILURE',
  SAVE_DEDICATED_NUMBER_REQUEST: 'SAVE_DEDICATED_NUMBER_REQUEST',
  SAVE_DEDICATED_NUMBER_SUCCESS: 'SAVE_DEDICATED_NUMBER_SUCCESS',
  SAVE_DEDICATED_NUMBER_FAILURE: 'SAVE_DEDICATED_NUMBER_FAILURE',
  UPDATE_DEDICATED_NUMBER: 'UPDATE_DEDICATED_NUMBER',
  DEDICATED_NUMBER_ACTIVE_TOGGLE_CHANGE: 'DEDICATED_NUMBER_ACTIVE_TOGGLE_CHANGE',
  FETCH_ASSIGNED_NUMBER_REQUEST: 'FETCH_ASSIGNED_NUMBER_REQUEST',
  FETCH_ASSIGNED_NUMBER_SUCCESS: 'FETCH_ASSIGNED_NUMBER_SUCCESS',
  FETCH_ASSIGNED_NUMBER_FAILURE: 'FETCH_ASSIGNED_NUMBER_FAILURE',
  UPDATE_ASSIGNED_NUMBER: 'UPDATE_ASSIGNED_NUMBER',
  UPDATE_ASSIGNED_NUMBER_MESSAGE: 'UPDATE_ASSIGNED_NUMBER_MESSAGE',
  DISSOCIATE_ASSIGNED_TOOL: 'DISSOCIATE_ASSIGNED_TOOL'
}
