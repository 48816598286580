import produce from 'immer'
import constants from '../constants/text-analysis'

const initialState = {
  responses: {},
  fetching: false,
  doneFetching: false,
  showTable: false,
  fetchError: {},
  showSentiment: true,
  query: {
    page: 1,
    page_size: 25
  },
  meta: {
    count: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_DEMOGRAPHIC_FILTER_RESPONSE_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      draft.showTable = true
      break
    case constants.FETCH_DEMOGRAPHIC_FILTER_RESPONSE_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.responses.demographicFilters = action.responses
      break
    case constants.FETCH_DEMOGRAPHIC_FILTER_RESPONSE_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.FETCH_SENTIMENT_FILTER_RESPONSE_REQUEST:
      draft.fetching = true
      draft.doneFetching = false
      draft.showTable = true
      break
    case constants.FETCH_SENTIMENT_FILTER_RESPONSE_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.doneFetching = true
      draft.responses.sentimentFilters = action.responses
      break
    case constants.FETCH_SENTIMENT_FILTER_RESPONSE_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
  }
}, initialState)
