export default {
  FETCH_CATEGORIES_REQUEST: 'FETCH_MARKER_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_MARKER_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_MARKER_CATEGORIES_FAILURE',
  UPDATE_CATEGORY: 'UPDATE_MARKER_CATEGORY',
  HIDE_CATEGORY_REQUEST: 'HIDE_MARKER_CATEGORY_REQUEST',
  HIDE_CATEGORY_SUCCESS: 'HIDE_MARKER_CATEGORY_SUCCESS',
  HIDE_CATEGORY_FAILURE: 'HIDE_MARKER_CATEGORY_FAILURE',
  UNHIDE_CATEGORY_REQUEST: 'UNHIDE_MARKER_CATEGORY_REQUEST',
  UNHIDE_CATEGORY_SUCCESS: 'UNHIDE_MARKER_CATEGORY_SUCCESS',
  UNHIDE_CATEGORY_FAILURE: 'UNHIDE_MARKER_CATEGORY_FAILURE',
  DESTROY_CATEGORY_REQUEST: 'DESTROY_MARKER_CATEGORY_REQUEST',
  DESTROY_CATEGORY_SUCCESS: 'DESTROY_MARKER_CATEGORY_SUCCESS',
  DESTROY_CATEGORY_FAILURE: 'DESTROY_MARKER_CATEGORY_FAILURE',
  SAVE_CATEGORY_REQUEST: 'SAVE_MARKER_CATEGORY_REQUEST',
  SAVE_CATEGORY_SUCCESS: 'SAVE_MARKER_CATEGORY_SUCCESS',
  SAVE_CATEGORY_FAILURE: 'SAVE_MARKER_CATEGORY_FAILURE',
  CREATE_CATEGORY_REQUEST: 'CREATE_MARKER_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_MARKER_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_MARKER_CATEGORY_FAILURE',
  SAVE_CATEGORIES_REQUEST: 'SAVE_MARKER_CATEGORIES_REQUEST',
  SAVE_CATEGORIES_SUCCESS: 'SAVE_MARKER_CATEGORIES_SUCCESS',
  SAVE_CATEGORIES_FAILURE: 'SAVE_MARKER_CATEGORIES_FAILURE',
  TOGGLE_PIN_VISIBILITY_REQUEST: 'TOGGLE_PIN_VISIBILITY_REQUEST',
  TOGGLE_PIN_VISIBILITY_SUCCESS: 'TOGGLE_PIN_VISIBILITY_SUCCESS',
  TOGGLE_PIN_VISIBILITY_FAILURE: 'TOGGLE_PIN_VISIBILITY_FAILURE',
  RESET_CATEGORIES: 'RESET_MARKER_CATEGORIES'
}
