import produce from 'immer'
import constants from 'constants/marker-categories'

const initialState = {
  categories: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  creating: false,
  createError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_CATEGORIES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_CATEGORIES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.categories = action.categories
      break
    case constants.FETCH_CATEGORIES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_CATEGORY:
      draft.categories[action.id][action.key] = action.value
      break
    case constants.RESET_CATEGORIES:
      return initialState
    case constants.HIDE_CATEGORY_REQUEST:
    case constants.TOGGLE_PIN_VISIBILITY_REQUEST:
    case constants.UNHIDE_CATEGORY_REQUEST:
    case constants.DESTROY_CATEGORY_REQUEST:
      draft.categories[action.id].transitioning = true
      break
    case constants.HIDE_CATEGORY_SUCCESS:
      draft.categories[action.id].transitioning = false
      draft.categories[action.id].transitionError = ''
      draft.categories[action.id].visible = true
      break
    case constants.TOGGLE_PIN_VISIBILITY_SUCCESS:
      draft.categories[action.id].transitioning = false
      draft.categories[action.id].transitionError = ''
      draft.categories[action.id].visible = true
      break
    case constants.UNHIDE_CATEGORY_SUCCESS:
      draft.categories[action.id].transitioning = false
      draft.categories[action.id].transitionError = ''
      draft.categories[action.id].visible = false
      break
    case constants.DESTROY_CATEGORY_SUCCESS:
      delete draft.categories[action.id]
      break
    case constants.HIDE_CATEGORY_FAILURE:
    case constants.TOGGLE_PIN_VISIBILITY_FAILURE:
    case constants.UNHIDE_CATEGORY_FAILURE:
    case constants.DESTROY_CATEGORY_FAILURE:
      draft.categories[action.id].transitioning = false
      draft.categories[action.id].transitionError = action.errorMessage
      break
    case constants.SAVE_CATEGORY_REQUEST:
      draft.categories[action.id].saving = true
      break
    case constants.SAVE_CATEGORY_SUCCESS:
      draft.categories[action.id].saving = false
      draft.categories[action.id].saveError = ''
      draft.categories[action.id].errors = {}
      break
    case constants.SAVE_CATEGORY_FAILURE:
      draft.categories[action.id].saving = false
      draft.categories[action.id].saveError = action.errorMessage
      draft.categories[action.id].errors = action.errors
      break
    case constants.SAVE_CATEGORIES_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_CATEGORIES_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      break
    case constants.SAVE_CATEGORIES_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
    case constants.CREATE_CATEGORY_REQUEST:
      draft.creating = true
      break
    case constants.CREATE_CATEGORY_SUCCESS:
      draft.categories[action.category.id] = action.category
      draft.creating = false
      draft.createError = ''
      break
    case constants.CREATE_CATEGORY_FAILURE:
      draft.creating = false
      draft.createError = action.errorMessage
      break
  }
}, initialState)
