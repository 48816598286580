import produce, { current } from 'immer'
import findIndex from 'lodash/findIndex'
import constants from 'constants/survey-email-templates'

const initialState = {
  surveyEmailTemplates: [],
  fetching: false,
  doneFetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_SURVEY_EMAIL_TEMPLATES_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_SURVEY_EMAIL_TEMPLATES_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.surveyEmailTemplates = action.surveyEmailTemplates
      break
    case constants.FETCH_SURVEY_EMAIL_TEMPLATES_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_SURVEY_EMAIL_TEMPLATE:
      const index = findIndex(draft.surveyEmailTemplates, s => s.id === action.id)

      draft.surveyEmailTemplates[index] = {
        ...current(draft).surveyEmailTemplates[index],
        ...action.attributes
      }
      break
  }
}, initialState)
