export default {
  FETCH_STORY_COLLECTION_REQUEST: 'FETCH_PINNED_STORY_COLLECTION_REQUEST',
  FETCH_STORY_COLLECTION_SUCCESS: 'FETCH_PINNED_STORY_COLLECTION_SUCCESS',
  FETCH_STORY_COLLECTION_FAILURE: 'FETCH_PINNED_STORY_COLLECTION_FAILURE',
  RESET_STORY_COLLECTION: 'RESET_PINNED_STORY_COLLECTION',
  PUBLISH_STORY_COLLECTION_REQUEST: 'PUBLISH_STORY_COLLECTION_REQUEST',
  PUBLISH_STORY_COLLECTION_SUCCESS: 'PUBLISH_STORY_COLLECTION_SUCCESS',
  PUBLISH_STORY_COLLECTION_FAILURE: 'PUBLISH_STORY_COLLECTION_FAILURE',
  UNPUBLISH_STORY_COLLECTION_REQUEST: 'UNPUBLISH_STORY_COLLECTION_REQUEST',
  UNPUBLISH_STORY_COLLECTION_SUCCESS: 'UNPUBLISH_STORY_COLLECTION_SUCCESS',
  UNPUBLISH_STORY_COLLECTION_FAILURE: 'UNPUBLISH_STORY_COLLECTION_FAILURE',
  ARCHIVE_STORY_COLLECTION_REQUEST: 'ARCHIVE_STORY_COLLECTION_REQUEST',
  ARCHIVE_STORY_COLLECTION_SUCCESS: 'ARCHIVE_STORY_COLLECTION_SUCCESS',
  ARCHIVE_STORY_COLLECTION_FAILURE: 'ARCHIVE_STORY_COLLECTION_FAILURE',
  UNARCHIVE_STORY_COLLECTION_REQUEST: 'UNARCHIVE_STORY_COLLECTION_REQUEST',
  UNARCHIVE_STORY_COLLECTION_SUCCESS: 'UNARCHIVE_STORY_COLLECTION_SUCCESS',
  UNARCHIVE_STORY_COLLECTION_FAILURE: 'UNARCHIVE_STORY_COLLECTION_FAILURE',
  DESTROY_STORY_COLLECTION_REQUEST: 'DESTROY_STORY_COLLECTION_REQUEST',
  DESTROY_STORY_COLLECTION_SUCCESS: 'DESTROY_STORY_COLLECTION_SUCCESS',
  DESTROY_STORY_COLLECTION_FAILURE: 'DESTROY_STORY_COLLECTION_FAILURE'
}
