export default {
  FETCH_HUB_REQUEST: 'FETCH_HUB_REQUEST',
  FETCH_HUB_SUCCESS: 'FETCH_HUB_SUCCESS',
  FETCH_HUB_FAILURE: 'FETCH_HUB_FAILURE',
  UPDATE_HUB: 'UPDATE_HUB',
  SAVE_HUB_REQUEST: 'SAVE_HUB_REQUEST',
  SAVE_HUB_SUCCESS: 'SAVE_HUB_SUCCESS',
  SAVE_HUB_FAILURE: 'SAVE_HUB_FAILURE',
  RESET_HUB: 'RESET_HUB',
  DELETE_HUB_REQUEST: 'DELETE_HUB_REQUEST',
  DELETE_HUB_SUCCESS: 'DELETE_HUB_SUCCESS',
  DELETE_HUB_FAILURE: 'DELETE_HUB_FAILURE',
  FETCH_HUB_PERMALINK_REQUEST: 'FETCH_HUB_PERMALINK_REQUEST',
  FETCH_HUB_PERMALINK_SUCCESS: 'FETCH_HUB_PERMALINK_SUCCESS',
  FETCH_HUB_PERMALINK_FAILURE: 'FETCH_HUB_PERMALINK_FAILURE',
  AUTHORIZE_HUB_ADMIN_REQUEST: 'AUTHORIZE_HUB_ADMIN_REQUEST',
  AUTHORIZE_HUB_ADMIN_SUCCESS: 'AUTHORIZE_HUB_ADMIN_SUCCESS',
  AUTHORIZE_HUB_ADMIN_FAILURE: 'AUTHORIZE_HUB_ADMIN_FAILURE',
  UNAUTHORIZE_HUB_ADMIN_REQUEST: 'UNAUTHORIZE_HUB_ADMIN_REQUEST',
  UNAUTHORIZE_HUB_ADMIN_SUCCESS: 'UNAUTHORIZE_HUB_ADMIN_SUCCESS',
  UNAUTHORIZE_HUB_ADMIN_FAILURE: 'UNAUTHORIZE_HUB_ADMIN_FAILURE',
  FETCH_HUB_ADMIN_REQUEST: 'FETCH_HUB_ADMIN_REQUEST',
  FETCH_HUB_ADMIN_SUCCESS: 'FETCH_HUB_ADMIN_SUCCESS',
  FETCH_HUB_ADMIN_FAILURE: 'FETCH_HUB_ADMIN_FAILURE'
}
