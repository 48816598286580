export default {
  FETCH_PHOTOS_REQUEST: 'FETCH_PHOTOS_REQUEST',
  FETCH_PHOTOS_SUCCESS: 'FETCH_PHOTOS_SUCCESS',
  FETCH_PHOTOS_FAILURE: 'FETCH_PHOTOS_FAILURE',
  RESET_PHOTOS: 'RESET_PHOTOS',
  REORDER_PHOTOS: 'REORDER_PHOTOS',
  CREATE_PHOTO_REQUEST: 'CREATE_PHOTO_REQUEST',
  CREATE_PHOTO_SUCCESS: 'CREATE_PHOTO_SUCCESS',
  CREATE_PHOTO_FAILURE: 'CREATE_PHOTO_FAILURE',
  RESET_CREATE_ERRORS: 'RESET_CREATE_PHOTO_ERRORS',
  DELETE_PHOTO_REQUEST: 'DELETE_PHOTO_REQUEST',
  DELETE_PHOTO_SUCCESS: 'DELETE_PHOTO_SUCCESS',
  DELETE_PHOTO_FAILURE: 'DELETE_PHOTO_FAILURE',
  SET_ACTIVE_PHOTO: 'SET_ACTIVE_PHOTO',
  UNSET_ACTIVE_PHOTO: 'UNSET_ACTIVE_PHOTO',
  CLONE_PHOTOS_REQUEST: 'CLONE_PHOTOS_REQUEST',
  CLONE_PHOTOS_SUCCESS: 'CLONE_PHOTOS_SUCCESS',
  CLONE_PHOTOS_FAILURE: 'CLONE_PHOTOS_FAILURE',
  SAVE_PHOTO_REQUEST: 'SAVE_PHOTO_REQUEST',
  SAVE_PHOTO_SUCCESS: 'SAVE_PHOTO_SUCCESS',
  SAVE_PHOTO_FAILURE: 'SAVE_PHOTO_FAILURE',
  RESET_PHOTO_ERRORS: 'RESET_PHOTO_ERRORS'
}
