export default {
  FETCH_ADMINISTRATOR_REQUEST: 'FETCH_ADMINISTRATOR_REQUEST',
  FETCH_ADMINISTRATOR_SUCCESS: 'FETCH_ADMINISTRATOR_SUCCESS',
  FETCH_ADMINISTRATOR_FAILURE: 'FETCH_ADMINISTRATOR_FAILURE',
  UPDATE_RESPONSES: 'UPDATE_ADMINISTRATOR_RESPONSES',
  UPDATE_ADMINISTRATOR: 'UPDATE_ADMINISTRATOR',
  RESET_ADMINISTRATOR: 'RESET_ADMINISTRATOR',
  SAVE_ADMINISTRATOR_REQUEST: 'SAVE_ADMINISTRATOR_REQUEST',
  SAVE_ADMINISTRATOR_SUCCESS: 'SAVE_ADMINISTRATOR_SUCCESS',
  SAVE_ADMINISTRATOR_FAILURE: 'SAVE_ADMINISTRATOR_FAILURE',
  SAVE_ADMIN_INVITATION_REQUEST: 'SAVE_ADMIN_INVITATION_REQUEST',
  SAVE_ADMIN_INVITATION_SUCCESS: 'SAVE_ADMIN_INVITATION_SUCCESS',
  SAVE_ADMIN_INVITATION_FAILURE: 'SAVE_ADMIN_INVITATION_FAILURE',
  DELETE_ADMIN_INVITATION_REQUEST: 'DELETE_ADMIN_INVITATION_REQUEST',
  DELETE_ADMIN_INVITATION_SUCCESS: 'DELETE_ADMIN_INVITATION_SUCCESS',
  DELETE_ADMIN_INVITATION_FAILURE: 'DELETE_ADMIN_INVITATION_FAILURE',
  UPDATE_INVITATION_TOKEN: 'UPDATE_INVITATION_TOKEN'
}
