export default {
  FETCH_FOOTER_LINKS_REQUEST: 'FETCH_FOOTER_LINKS_REQUEST',
  FETCH_FOOTER_LINKS_SUCCESS: 'FETCH_FOOTER_LINKS_SUCCESS',
  FETCH_FOOTER_LINKS_FAILURE: 'FETCH_FOOTER_LINKS_FAILURE',
  UPDATE_FOOTER_LINKS: 'UPDATE_FOOTER_LINKS',
  FETCH_FOOTER_LINK_REQUEST: 'FETCH_FOOTER_LINK_REQUEST',
  FETCH_FOOTER_LINK_SUCCESS: 'FETCH_FOOTER_LINK_SUCCESS',
  FETCH_FOOTER_LINK_FAILURE: 'FETCH_FOOTER_LINK_FAILURE',
  UPDATE_FOOTER_LINK: 'UPDATE_FOOTER_LINK',
  SAVE_FOOTER_LINK_REQUEST: 'SAVE_FOOTER_LINK_REQUEST',
  SAVE_FOOTER_LINK_SUCCESS: 'SAVE_FOOTER_LINK_SUCCESS',
  SAVE_FOOTER_LINK_FAILURE: 'SAVE_FOOTER_LINK_FAILURE',
  RESET_FOOTER_LINK: 'RESET_FOOTER_LINK',
  DELETE_FOOTER_LINK_REQUEST: 'DELETE_FOOTER_LINK_REQUEST',
  DELETE_FOOTER_LINK_SUCCESS: 'DELETE_FOOTER_LINK_SUCCESS',
  DELETE_FOOTER_LINK_FAILURE: 'DELETE_FOOTER_LINK_FAILURE',
  SORT_FOOTER_LINKS_SUCCESS: 'SORT_FOOTER_LINKS_SUCCESS',
  SORT_FOOTER_LINKS_FAILURE: 'SORT_FOOTER_LINKS_FAILURE',
  UPDATE_FOOTER_LINK_ERROR: 'UPDATE_FOOTER_LINK_ERROR'
}
