import produce from 'immer'
import constants from 'constants/story-collection'

const initialState = {
  storyCollection: {},
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_STORY_COLLECTION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_STORY_COLLECTION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.storyCollection = action.storyCollection
      break
    case constants.FETCH_STORY_COLLECTION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_STORY_COLLECTION:
      draft.storyCollection[action.key] = action.value
      break
    case constants.SAVE_STORY_COLLECTION_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_STORY_COLLECTION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_STORY_COLLECTION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_STORY_COLLECTION:
      return initialState
  }
}, initialState)
