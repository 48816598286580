import produce from 'immer'
import constants from '../constants/users-by-segments'

const initialState = {
  users: {},
  fetching: false,
  fetchError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_USERS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_USERS_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.users = action.users
      break
    case constants.FETCH_USERS_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break
  }
}, initialState)
