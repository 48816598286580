import produce from 'immer'
import constants from 'constants/project-gov-delivery-integration'

const initialState = {
  projectGovDeliveryIntegration: {},
  gdTopicCode: '',
  fetching: false,
  doneFetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {}
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = ''
      draft.projectGovDeliveryIntegration = action.projectGovDeliveryIntegration
      draft.gdTopicCode = action.projectGovDeliveryIntegration.gdTopicCode
      break
    case constants.FETCH_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE:
      draft.fetching = false
      draft.doneFetching = true
      draft.fetchError = action.errorMessage
      break
    case constants.UPDATE_PROJECT_GOV_DELIVERY_INTEGRATION:
      draft.projectGovDeliveryIntegration[action.key] = action.value
      draft[action.key] = action.value
      break
    case constants.SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_REQUEST:
      draft.saving = true
      break
    case constants.SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      break
    case constants.SAVE_PROJECT_GOV_DELIVERY_INTEGRATION_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break
    case constants.RESET_PROJECT_GOV_DELIVERY_INTEGRATION:
      return initialState
  }
}, initialState)
