export default {
  FETCH_EMAIL_TEMPLATES_REQUEST: 'FETCH_EMAIL_TEMPLATES_REQUEST',
  FETCH_EMAIL_TEMPLATES_SUCCESS: 'FETCH_EMAIL_TEMPLATES_SUCCESS',
  FETCH_EMAIL_TEMPLATES_FAILURE: 'FETCH_EMAIL_TEMPLATES_FAILURE',
  UPDATE_EMAIL_TEMPLATE: 'UPDATE_EMAIL_TEMPLATE',
  SAVE_EMAIL_TEMPLATE_REQUEST: 'SAVE_EMAIL_TEMPLATE_REQUEST',
  SAVE_EMAIL_TEMPLATE_SUCCESS: 'SAVE_EMAIL_TEMPLATE_SUCCESS',
  SAVE_EMAIL_TEMPLATE_FAILURE: 'SAVE_EMAIL_TEMPLATE_FAILURE',
  SAVE_EMAIL_TEMPLATES_REQUEST: 'SAVE_EMAIL_TEMPLATES_REQUEST',
  SAVE_EMAIL_TEMPLATES_SUCCESS: 'SAVE_EMAIL_TEMPLATES_SUCCESS',
  SAVE_EMAIL_TEMPLATES_FAILURE: 'SAVE_EMAIL_TEMPLATES_FAILURE'
}
