export default {
  FETCH_DEDICATED_NUMBERS_REQUEST: 'FETCH_DEDICATED_NUMBERS_REQUEST',
  FETCH_DEDICATED_NUMBERS_SUCCESS: 'FETCH_DEDICATED_NUMBERS_SUCCESS',
  FETCH_DEDICATED_NUMBERS_FAILURE: 'FETCH_DEDICATED_NUMBERS_FAILURE',
  UPDATE_DEDICATED_NUMBERS_FILTERS: 'UPDATE_DEDICATED_NUMBERS_FILTERS',
  UPDATE_DEDICATED_NUMBERS_QUERIES: 'UPDATE_DEDICATED_NUMBERS_QUERIES',
  UPDATE_TOOL_DATA: 'UPDATE_TOOL_DATA',
  TOOL_DATA_ACTIVE_TOGGLE_CHANGE: 'TOOL_DATA_ACTIVE_TOGGLE_CHANGE',
  REMOVE_TOOL_ID: 'REMOVE_TOOL_ID'
}
