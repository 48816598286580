import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function initializeStore (reducer, initialState) {
  if (process.env.NODE_ENV === 'production') {
    return createStore(
      reducer,
      initialState,
      compose(applyMiddleware(thunkMiddleware))
    )
  } else {
    const composeWithDevTools = require('redux-devtools-extension').composeWithDevTools

    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
    return createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(thunkMiddleware))
    )
  }
}

export function getOrCreateStore (reducer, initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(reducer, initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(reducer, initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}
