import produce from 'immer'
import constants from 'constants/project-finder'

const initialState = {
  projectFinder: {},
  fetching: false,
  fetchError: '',
  saving: false,
  saveError: '',
  errors: {},
  rearrangeOrder: false,
  hasUnsavedChanges: false
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_FINDER_REQUEST:
      draft.fetching = true
      break

    case constants.FETCH_PROJECT_FINDER_SUCCESS:
      draft.fetching = false
      draft.fetchError = ''
      draft.projectFinder = action.projectFinder
      break

    case constants.FETCH_PROJECT_FINDER_FAILURE:
      draft.fetching = false
      draft.fetchError = action.errorMessage
      break

    case constants.UPDATE_PROJECT_FINDER:
      draft.hasUnsavedChanges = true
      draft.projectFinder[action.key] = action.value
      break

    case constants.UPDATE_PROJECT_FINDER_LOCALES:
      draft.hasUnsavedChanges = true
      draft.projectFinder['locales'] = action.locales
      break

    case constants.SAVE_PROJECT_FINDER_REQUEST:
      draft.saving = true
      break

    case constants.SAVE_PROJECT_FINDER_SUCCESS:
      draft.saving = false
      draft.saveError = ''
      draft.errors = {}
      draft.hasUnsavedChanges = false
      draft.projectFinder = action.projectFinder
      break

    case constants.SAVE_PROJECT_FINDER_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      draft.errors = action.errors
      break

    case constants.SET_PROJECT_FINDER:
      draft.hasUnsavedChanges = false
      draft.projectFinder = action.projectFinder
      break

    case constants.TOGGLE_REARRANGE_ORDER:
      draft.rearrangeOrder = !draft.rearrangeOrder
      break

    case constants.RESET_PROJECT_FINDER:
      return initialState
  }
}, initialState)
