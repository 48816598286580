import produce from 'immer'
import constants from '../constants/clone-project'

const initialState = {
  clonedProject: {
    activeStep: 0,
    fetchingPermalink: false
  },
  saved: false,
  saving: false,
  saveError: ''
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.UPDATE_PROJECT_CLONED:
      draft.clonedProject = { ...draft.clonedProject, ...action.clonedProject }
      break
    case constants.RESET_PROJECT_CLONED:
      return initialState
    case constants.CLONE_PROJECT_REQUEST:
      draft.saving = true
      break
    case constants.CLONE_PROJECT_SUCCESS:
      draft.saving = false
      draft.saved = true
      draft.saveError = ''
      break
    case constants.CLONE_PROJECT_FAILURE:
      draft.saving = false
      draft.saveError = action.errorMessage
      break
  }
}, initialState)
