import constants from 'constants/places';
import produce from 'immer';
import type { TabType } from 'types/places';

interface IState {
  esri: {
    currentTab: TabType;
  };
}

const initialState: IState = {
  esri: {
    currentTab: 'details',
  },
};

export default produce((draft, action) => {
  switch (action.type) {
    case constants.SET_CURRENT_TAB:
      draft.esri.currentTab = action.value;
      break;
  }
}, initialState);
