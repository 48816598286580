import produce from 'immer'
import constants from '../constants/hubs'

const initialState = {
  hubs: [],
  fetchError: '',
  fetching: false,
  doneFetching: false,
  query: {
    page: 1,
    per_page: 25
  },
  filters: {
    state: '',
    search: '',
    sort: {}
  },
  meta: {
    all: 0
  }
}

export default produce((draft, action) => {
  switch (action.type) {
    case constants.FETCH_HUBS_REQUEST:
      draft.fetching = true
      break
    case constants.FETCH_HUBS_SUCCESS:
      draft.hubs = action.hubs
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.FETCH_HUBS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.QUERY_HUBS_REQUEST:
      draft.fetching = true
      break
    case constants.QUERY_HUBS_SUCCESS:
      draft.hubs = action.hubs
      draft.meta = action.hubs.meta
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.QUERY_HUBS_FAILURE:
      draft.fetchError = action.errorMessage
      draft.fetching = false
      draft.doneFetching = true
      break
    case constants.UPDATE_HUBS_QUERY:
      draft.query = { ...draft.query, ...action.query }
      break
    case constants.UPDATE_HUBS_FILTERS:
      draft.filters = { ...draft.filters, ...action.filters }
      break
    case constants.RESET_HUBS:
      return initialState
  }
}, initialState)
