export default {
  FETCH_BLOG_POSTS_REQUEST: 'FETCH_BLOG_POSTS_REQUEST',
  FETCH_BLOG_POSTS_SUCCESS: 'FETCH_BLOG_POSTS_SUCCESS',
  FETCH_BLOG_POSTS_FAILURE: 'FETCH_BLOG_POSTS_FAILURE',
  RESET_BLOG_POSTS: 'RESET_BLOG_POSTS',
  PUBLISH_BLOG_POST_REQUEST: 'PUBLISH_BLOG_POST_REQUEST',
  PUBLISH_BLOG_POST_SUCCESS: 'PUBLISH_BLOG_POST_SUCCESS',
  PUBLISH_BLOG_POST_FAILURE: 'PUBLISH_BLOG_POST_FAILURE',
  UNPUBLISH_BLOG_POST_REQUEST: 'UNPUBLISH_BLOG_POST_REQUEST',
  UNPUBLISH_BLOG_POST_SUCCESS: 'UNPUBLISH_BLOG_POST_SUCCESS',
  UNPUBLISH_BLOG_POST_FAILURE: 'UNPUBLISH_BLOG_POST_FAILURE',
  ARCHIVE_BLOG_POST_REQUEST: 'ARCHIVE_BLOG_POST_REQUEST',
  ARCHIVE_BLOG_POST_SUCCESS: 'ARCHIVE_BLOG_POST_SUCCESS',
  ARCHIVE_BLOG_POST_FAILURE: 'ARCHIVE_BLOG_POST_FAILURE',
  UNARCHIVE_BLOG_POST_REQUEST: 'UNARCHIVE_BLOG_POST_REQUEST',
  UNARCHIVE_BLOG_POST_SUCCESS: 'UNARCHIVE_BLOG_POST_SUCCESS',
  UNARCHIVE_BLOG_POST_FAILURE: 'UNARCHIVE_BLOG_POST_FAILURE',
  DESTROY_BLOG_POST_REQUEST: 'DESTROY_BLOG_POST_REQUEST',
  DESTROY_BLOG_POST_SUCCESS: 'DESTROY_BLOG_POST_SUCCESS',
  DESTROY_BLOG_POST_FAILURE: 'DESTROY_BLOG_POST_FAILURE',
  REORDER_BLOG_POSTS: 'REORDER_BLOG_POSTS',
  FETCH_SCHEDULE_REQUEST: 'FETCH_BLOG_POST_SCHEDULE_REQUEST',
  FETCH_SCHEDULE_SUCCESS: 'FETCH_BLOG_POST_SCHEDULE_SUCCESS',
  FETCH_SCHEDULE_FAILURE: 'FETCH_BLOG_POST_SCHEDULE_FAILURE',
  SAVE_SCHEDULE_REQUEST: 'SAVE_BLOG_POST_SCHEDULE_REQUEST',
  SAVE_SCHEDULE_SUCCESS: 'SAVE_BLOG_POST_SCHEDULE_SUCCESS',
  SAVE_SCHEDULE_FAILURE: 'SAVE_BLOG_POST_SCHEDULE_FAILURE',
  DESTROY_SCHEDULE_REQUEST: 'DESTROY_BLOG_POST_SCHEDULE_REQUEST',
  DESTROY_SCHEDULE_SUCCESS: 'DESTROY_BLOG_POST_SCHEDULE_SUCCESS',
  DESTROY_SCHEDULE_FAILURE: 'DESTROY_BLOG_POST_SCHEDULE_FAILURE',
  RESET_SCHEDULE_ERRORS: 'RESET_BLOG_POST_SCHEDULE_ERRORS'
}
